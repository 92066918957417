import { memo } from 'react'

const VerificationStatusError = () => (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={20} cy={20} r={20} fill="#F6A623" fillOpacity="0.2" />
        <path
            d="M19.9999 23.0058C20.8994 23.0058 21.6233 23.7297 21.6233 24.6291C21.6233 25.5286 20.8994 26.2745 19.9999 26.2745C19.1004 26.2745 18.3546 25.5287 18.3546 24.6291C18.3546 23.7297 19.1004 23.0058 19.9999 23.0058ZM19.9999 21.3604C19.3857 21.3604 18.9688 20.8559 18.903 20.2635L18.3546 15.3714C18.2669 14.5158 19.1663 13.7261 19.9999 13.7261C20.8336 13.7261 21.733 14.5159 21.6233 15.3714L21.0968 20.2635C21.031 20.8558 20.6141 21.3604 19.9999 21.3604Z"
            fill="#F6A623"
        />
        <path
            d="M20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8C26.6274 8 32 13.3726 32 20C32 26.6274 26.6274 32 20 32ZM20 29.3333C25.1544 29.3333 29.3334 25.1546 29.3334 19.9999C29.3334 14.8452 25.1547 10.6664 20 10.6664C14.8453 10.6664 10.6666 14.8452 10.6666 19.9999C10.6666 25.1546 14.8453 29.3333 20 29.3333Z"
            fill="#F6A623"
        />
    </svg>
)

export default memo(VerificationStatusError)
