import { memo } from 'react'

const DashboardUserIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
        <circle cx={12} cy={12} r={12} fill="#FF6A71" opacity={0.12} />
        <path
            stroke="#FF6A71"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M16 16.5v-1a2 2 0 0 0-2-2h-3.5a2 2 0 0 0-2 2v1"
        />
        <circle
            cx={12.25}
            cy={9.5}
            r={2}
            stroke="#FF6A71"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        />
    </svg>
)
export default memo(DashboardUserIcon)
