import { memo } from 'react'

import { Box } from '../../components/common'

const CheckboxCheckedIcon = () => (
    <Box
        css={{
            width: 24,
            height: 24,
            flexDirection: 'column',
            borderRadius: '3px',
            borderBottom: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: '#2EA2F8',
        }}
    >
        <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.93 7.36a1.714 1.714 0 0 1 2.425 2.424l-6.857 6.857c-.67.67-1.755.67-2.425 0l-3.428-3.429a1.714 1.714 0 1 1 2.424-2.424l2.216 2.216L15.93 7.36Z"
                fill="#fff"
            />
        </svg>
    </Box>
)

export default memo(CheckboxCheckedIcon)
