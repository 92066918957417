import { styled } from '@leanpay/stitches'

import { P } from '../common'

export const Subtitle = styled(P, {
    'color': '$dark1',
    'fontSize': '$3',
    'lineHeight': '$2',
    'textAlign': 'center',
    'fontWeight': 400,
    '@sm': {
        fontSize: '$2',
    },
    'a': {
        color: '$peach2',
    },
})

export const BodyText = styled(P, {
    fontSize: '$3',
    color: '$dark1',
    lineHeight: '$2',
    a: {
        color: '$peach2',
        textDecoration: 'underline',
    },
})

export const ActionText = styled(P, {
    fontSize: '$2',
    color: '$peach2',
    lineHeight: '$3',
    cursor: 'pointer',
    a: {
        color: '$peach2',
    },
})
