/**
 * Launches a download in the browser
 * @param blob
 * @param fileName
 */
export const saveBlobFile = (blob: Blob, fileName: string) => {
    const a: HTMLAnchorElement | any = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'

    a.href = window.URL.createObjectURL(blob)
    a.download = fileName
    a.click()
}
