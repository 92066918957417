import { memo } from 'react'

const CalculatorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={13} height={16} fill="none">
            <path
                fill="#fff"
                d="M8.55 5.9h-4.1c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3h4.1c.7.1 1.3.6 1.3 1.4 0 .8-.6 1.2-1.3 1.2Z"
            />
            <circle cx={4.004} cy={8.4} r={0.8} fill="#fff" />
            <circle cx={4.004} cy={10.9} r={0.8} fill="#fff" />
            <circle cx={6.504} cy={8.4} r={0.8} fill="#fff" />
            <circle cx={6.504} cy={10.9} r={0.8} fill="#fff" />
            <circle cx={8.996} cy={8.4} r={0.8} fill="#fff" />
            <circle cx={8.996} cy={10.9} r={0.8} fill="#fff" />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M2.444 16h8.112C11.925 16 13 14.9 13 13.4V2.5C13 1.1 11.925 0 10.556 0H2.444C1.075 0 0 1.1 0 2.5v11C0 14.9 1.075 16 2.444 16ZM1.662 2.5c0-.4.293-.8.782-.8h8.112c.391 0 .782.3.782.8v10.9c0 .4-.293.8-.782.8H2.444a.78.78 0 0 1-.782-.8V2.5Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(CalculatorIcon)
