import { CountryCodebookDTO } from './dto-client'

export class Country {
    readonly name: string
    readonly id: number
    readonly isoAlpha2Code: string
    readonly isoAlpha3Code: string
    readonly featured: boolean

    constructor(data: CountryCodebookDTO) {
        this.name = data?.name
        this.id = data?.id
        this.isoAlpha2Code = data?.isoAlpha2Code
        this.isoAlpha3Code = data?.isoAlpha3Code
        this.featured = data?.featured
    }
}
