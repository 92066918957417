import { globalStyles } from '@leanpay/stitches'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import AppStore from './app-store'
import MainLayout from './main-layout'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: true,
        },
    },
})

function App() {
    useEffect(() => {
        globalStyles()
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AppStore>
                    <MainLayout />
                </AppStore>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App
