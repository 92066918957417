import { memo } from 'react'

const MasterCardIcon = () => {
    return (
        <svg
            width={25}
            height={16}
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.89062 13.8964H15.4819V2.10352H8.89062V13.8964Z"
                fill="#FF5F00"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.31038 8C9.31038 5.6075 10.4355 3.47667 12.1875 2.10354C10.9063 1.09917 9.28945 0.5 7.53221 0.5C3.3722 0.5 0 3.85771 0 8C0 12.1421 3.3722 15.5 7.53221 15.5C9.28945 15.5 10.9063 14.9008 12.1875 13.8965C10.4355 12.5231 9.31038 10.3925 9.31038 8Z"
                fill="#EB001B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1403 12.6473V12.3561H24.0639L23.9762 12.5563L23.8883 12.3561H23.812V12.6473H23.8659V12.4277L23.9482 12.6169H24.004L24.0865 12.4271V12.6473H24.1403ZM23.6565 12.6478V12.4064H23.7543V12.357H23.5052V12.4064H23.603V12.6478H23.6565ZM24.375 8C24.375 12.1421 21.0028 15.5 16.8428 15.5C15.0855 15.5 13.4689 14.9008 12.1875 13.8965C13.9395 12.5231 15.0646 10.3925 15.0646 8C15.0646 5.6075 13.9395 3.47667 12.1875 2.10354C13.4689 1.09917 15.0855 0.5 16.8428 0.5C21.0028 0.5 24.375 3.85771 24.375 8Z"
                fill="#F79E1B"
            />
        </svg>
    )
}

export default memo(MasterCardIcon)
