import { PreQualifiedInfoDTO } from './dto-client'

export class Vendor {
    readonly vendorKnown: boolean
    readonly vendorName: string
    readonly returnUrl: string
    readonly incomeLimit: number

    constructor(data: PreQualifiedInfoDTO) {
        this.vendorKnown = data?.vendorKnown
        this.vendorName = data?.vendorName
        this.returnUrl = data?.returnUrl
        this.incomeLimit = data?.incomeLimit
    }
}
