import { IndustryOfEmploymentDTO } from './dto-client'

export class IndustryOfEmployment {
    readonly name: string
    readonly id: number
    readonly nameEn: string

    constructor(data: IndustryOfEmploymentDTO) {
        this.name = data?.name
        this.id = data?.id
        this.nameEn = data?.nameEn
    }
}
