import { CostsCalculationMethod, MaturityDTO, PaymentPlanDTO } from './dto-client'

export class PaymentPlan {
    readonly possible: boolean
    readonly termMaturity: MaturityDTO
    readonly graceMaturity: MaturityDTO
    readonly loanAmount: number
    readonly totalAmount: number
    readonly graceAmount: number
    readonly installmentAmount: number
    readonly firstInstallmentAmount: number
    readonly firstInstallmentDate: string
    readonly numberOfInstallments: number
    readonly nominalInterestRate: number
    readonly costOfReview: number
    readonly costOfRisk: number
    readonly clientInterest: number
    readonly interestAndFees: number
    readonly eom: number
    readonly downPaymentTotal: number
    readonly downPaymentPrincipal: number
    readonly calculationMethod: CostsCalculationMethod

    constructor(data: PaymentPlanDTO) {
        this.possible = data.possible
        this.termMaturity = data.termMaturity
        this.graceMaturity = data.graceMaturity
        this.loanAmount = data.loanAmount
        this.totalAmount = data.totalAmount
        this.graceAmount = data.graceAmount
        this.installmentAmount = data.installmentAmount
        this.firstInstallmentAmount = data.firstInstallmentAmount
        this.firstInstallmentDate = data.firstInstallmentDate
        this.numberOfInstallments = data.numberOfInstallments
        this.nominalInterestRate = data.nominalInterestRate
        this.costOfReview = data.costOfReview
        this.costOfRisk = data.costOfRisk
        this.clientInterest = data.clientInterest
        this.interestAndFees = data.interestAndFees
        this.eom = data.eom
        this.downPaymentTotal = data.downPaymentTotal
        this.downPaymentPrincipal = data.downPaymentPrincipal
        this.calculationMethod = data.calculationMethod
    }
}
