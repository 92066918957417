const TriglavLogoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={140} height={45}>
        <g fill="none" fillRule="nonzero">
            <path
                fill="#91A3AE"
                d="M139.843 16.18h-5.369l-1.895 5.752c-.414 1.276-.682 2.357-.98 4.02h-.057c-.401-1.768-.69-2.975-1.088-4.183l-1.888-5.59h-5.49l5.713 16.257h5.272l5.782-16.257m-38.948 16.257h4.975V8.562h-4.975zm21.435 0c-.099-1.479-.099-3.007-.099-3.86v-6.969c0-3.915-1.6-5.784-7.254-5.784-1.94 0-3.903.326-5.633.878v4.45c1.473-.781 3.535-1.342 4.975-1.342 2.353 0 2.905.683 2.905 2.027v.552c-4.97 0-9.158 1.7-9.158 5.955 0 2.586 1.57 4.451 4.746 4.451 2.25 0 4.185-1.115 4.872-2.71l.032.03c-.132.685-.132 1.535-.132 2.322h4.746zm-5.106-6.484c0 1.574-1.136 3.083-2.84 3.083-.988 0-1.572-.59-1.572-1.44 0-1.341 1.34-2.16 4.412-2.16v.517m-55.321 6.287v-4.092c-.522.26-1.239.394-1.833.394-1.246 0-1.66-.527-1.66-2.227V20.01h3.493v-3.83h-3.528v-6.156l-4.977 1.347v4.808h-2.446v3.83h2.446v7.551c0 3.992 1.21 5.234 4.977 5.234 1.178 0 2.447-.204 3.528-.555m11.691-11.613v-4.839c-2.45-.094-3.92 1.342-4.673 3.012h-.034c.135-.882.16-2.036.16-2.62h-4.67v16.257h4.97v-6.87c0-4.158 1.599-5.526 4.247-4.94m1.807 11.81h4.968V16.179H75.4v16.257zm2.492-23.851c-1.68 0-3.05 1.313-3.05 2.948 0 1.638 1.37 2.948 3.05 2.948 1.693 0 3.033-1.34 3.033-2.948 0-1.635-1.34-2.948-3.033-2.948m20.182 24.181V16.339a37.747 37.747 0 0 0-6.281-.516c-5.953 0-9.063 4.046-9.063 9.383 0 4.878 2.123 7.62 6.056 7.62 1.997 0 3.66-.983 4.444-2.586h.06c-.132.788-.155 1.542-.155 2.35v.529c0 2.394-1.342 3.436-4.191 3.436-1.468 0-3.498-.492-5.1-1.239l-.194 4.643c1.435.462 3.27.81 5.099.81 7.364 0 9.325-3.756 9.325-8.003zM93.102 23.9c0 2.808-1.18 4.775-2.875 4.775-1.513 0-2.224-1.28-2.224-3.992 0-3.632 1.47-4.874 3.397-4.874.683 0 1.21.103 1.702.2V23.9M.107 44.113h44v-44h-44z"
            />
            <g fill="#FFF">
                <path d="M29.797 21.965a7.871 7.871 0 0 1-7.882 7.834c-4.336 0-7.85-3.495-7.925-7.834v-7.932h19.698v-3.91l-23.581-.01v11.85c.023 6.51 5.303 11.77 11.808 11.77 6.516 0 11.79-5.26 11.81-11.77h-3.928" />
                <path d="M33.688 19.978v-3.957l-17.683.005.007 5.939a5.908 5.908 0 0 0 5.903 5.866 5.898 5.898 0 0 0 5.905-5.866h-3.945a1.973 1.973 0 0 1-3.947 0v-1.986h13.76" />
            </g>
        </g>
    </svg>
)
export default TriglavLogoIcon
