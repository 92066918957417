import { memo } from 'react'

const ShopBagIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M.65 2.757a1 1 0 0 0-.65.936v8.614a1 1 0 0 0 .65.936l7 2.625a1 1 0 0 0 .701 0l7-2.625a1 1 0 0 0 .65-.936V3.693a1 1 0 0 0-.65-.936l-7-2.625a1 1 0 0 0-.702 0l-7 2.625ZM2 11.614V5.517l1.5.642V9a1 1 0 1 0 2 0V7.017l1.5.642v5.83l-5-1.875Zm7 1.875 5-1.875V5.517L9 7.659v5.83ZM8 5.912l4.7-2.014-1.026-.384L7.039 5.5 8 5.912Zm-3.5-1.5 4.466-1.914L8 2.136 3.301 3.898l1.2.514Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(ShopBagIcon)
