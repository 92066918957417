import { ExecutionAmountMetaDTO } from './dto-client'

export class ExecutionAmount {
    readonly id: number
    readonly orderNumber: number
    readonly fromAmount: number
    readonly toAmount: number
    readonly name: string

    constructor(data: ExecutionAmountMetaDTO) {
        this.id = data?.id
        this.orderNumber = data?.orderNumber
        this.fromAmount = data?.fromAmount
        this.toAmount = data?.toAmount
        this.name = data?.name
    }
}
