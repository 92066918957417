import { EmploymentContractType, EmploymentMetaTypeDTO } from './dto-client'

export class UserEmploymentType {
    readonly name: string
    readonly contractTypes: EmploymentContractType[]

    constructor(data: EmploymentMetaTypeDTO) {
        this.name = data?.name
        this.contractTypes = data?.contractTypes
    }
}
