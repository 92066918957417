import { NationalityCodebookDTO } from './dto-client'

export class Nationality {
    readonly name: string
    readonly id: number
    readonly country: string
    readonly nameEn: string

    constructor(data: NationalityCodebookDTO) {
        this.name = data?.name
        this.id = data?.id
        this.country = data?.country
        this.nameEn = data?.nameEn
    }
}
