import { CheckoutDataDTO } from './dto-client'
import { PaymentData } from './PaymentData'
import { PaymentPlan } from './PaymentPlan'
import { PersonalDetails } from './PersonalDetails'
import { SessionInfo } from './SessionInfo'

export class CheckoutData {
    readonly token: string
    readonly sessionInfo?: SessionInfo | null
    readonly maturityPriceId?: number
    readonly maturityGraceId?: number
    readonly firstPaymentDate?: string
    readonly authToken?: string
    readonly personalDetails?: PersonalDetails | null
    readonly paymentData?: PaymentData | null
    readonly isVerified?: boolean
    readonly isInStore?: boolean
    readonly paymentPlan?: PaymentPlan | null
    readonly incomeLimit?: number
    readonly vendorKnown?: boolean
    readonly vendorName?: string
    readonly returnUrl?: string
    readonly pendingApproval?: boolean
    readonly applicationApprovalType?: string
    readonly vendorRequestType?: string
    readonly vendorPackageDescription?: string
    readonly customerLanguage?: string

    constructor(data: CheckoutDataDTO) {
        this.token = data.token
        this.sessionInfo = data.sessionInfo ? new SessionInfo(data.sessionInfo) : null
        this.maturityPriceId = data.maturityPriceId
        this.maturityGraceId = data.maturityGraceId
        this.firstPaymentDate = data.firstPaymentDate
        this.authToken = data.authToken
        this.personalDetails = data.personalDetails
            ? new PersonalDetails(data.personalDetails)
            : null
        this.paymentData = data.paymentData ? new PaymentData(data.paymentData) : null
        this.isVerified = data.isVerified
        this.isInStore = data.isInStore
        this.paymentPlan = data.paymentPlan ? new PaymentPlan(data.paymentPlan) : null
        this.incomeLimit = data.incomeLimit
        this.vendorKnown = data.vendorKnown
        this.vendorName = data.vendorName
        this.returnUrl = data.returnUrl
        this.pendingApproval = data.pendingApproval
        this.applicationApprovalType = data?.applicationApprovalType
        this.vendorRequestType = data.vendorRequestType
        this.vendorPackageDescription = data.vendorPackageDescription
        this.customerLanguage = data.customerLanguage
    }
}
