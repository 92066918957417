import { memo } from 'react'

const FilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
            <path
                fill="#000"
                fillRule="evenodd"
                d="M3.963 3a1 1 0 1 0 0 2h10.032a1 1 0 1 0 0-2H3.963Zm1.005 5a1 1 0 0 1 1-1h6.021a1 1 0 1 1 0 2H5.968a1 1 0 0 1-1-1Zm2.005 4a1 1 0 0 1 1-1h2.011a1 1 0 0 1 0 2h-2.01a1 1 0 0 1-1-1Z"
                clipRule="evenodd"
            />
            <mask
                id="a"
                width={13}
                height={10}
                x={2}
                y={3}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M3.963 3a1 1 0 1 0 0 2h10.032a1 1 0 1 0 0-2H3.963Zm1.005 5a1 1 0 0 1 1-1h6.021a1 1 0 1 1 0 2H5.968a1 1 0 0 1-1-1Zm2.005 4a1 1 0 0 1 1-1h2.011a1 1 0 0 1 0 2h-2.01a1 1 0 0 1-1-1Z"
                    clipRule="evenodd"
                />
            </mask>
            <g mask="url(#a)">
                <path fill="#8190A6" d="M.957 0H17v16H.957z" />
            </g>
        </svg>
    )
}

export default memo(FilterIcon)
