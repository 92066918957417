import { HttpClient } from '@leanpay/http-client'
import { ContractPreliminaryRequest, DocumentType, TransactionRequest } from '@leanpay/models'

import { preQualifiedErrorHandler } from '../errorHandler'
import { clientInterceptor } from '../interceptors'

class DownloadService {
    _httpClient = new HttpClient({
        config: { baseURL: window.location.origin, responseType: 'json' },
        interceptorConfig: {
            onRequestFulfilled: clientInterceptor,
            onRejected: preQualifiedErrorHandler,
        },
    })

    private _downloadInitialOfferUrl = 'api/checkout/initial-offer/download'
    private _contractPreliminaryDownloadUrl = 'api/checkout/contract-preliminary/download'
    private _globalDocumentUrl = 'api/documents/global'
    private _personalDocumentUrl = 'api/documents/empty-personal'
    private _invoicesDownloadUrl = 'api/vendor/payment-invoice/document'

    async downloadVendorInvoice(correlationId: string): Promise<{ blob: Blob; fileName: string }> {
        const url = `${this._invoicesDownloadUrl}/${correlationId}`
        const { data, headers } = await this._httpClient.get(url, { responseType: 'blob' })

        const blob = data as Blob

        const fileName = headers['content-disposition']?.split('filename=')[1] ?? ''
        return { blob, fileName }
    }

    async downloadInitialOffer(
        payload: TransactionRequest,
    ): Promise<{ blob: Blob; fileName: string }> {
        const { data, headers } = await this._httpClient.post(
            this._downloadInitialOfferUrl,
            payload,
            {
                responseType: 'blob',
            },
        )

        const blob = data as Blob

        const fileName = headers['content-disposition']?.split('filename=')[1] ?? ''
        return { blob, fileName }
    }

    async downloadPreliminaryContract(
        payload: ContractPreliminaryRequest,
    ): Promise<{ blob: Blob; fileName: string }> {
        const { data, headers } = await this._httpClient.post(
            this._contractPreliminaryDownloadUrl,
            payload,
            {
                responseType: 'blob',
            },
        )

        const blob = data as Blob

        const fileName = headers['content-disposition']?.split('filename=')[1] ?? ''
        return { blob, fileName }
    }

    async downloadGlobalDocument(
        documentType: DocumentType,
    ): Promise<{ blob: Blob; fileName: string }> {
        const documentUrl = `${this._globalDocumentUrl}/${documentType}`

        const { data, headers } = await this._httpClient.get(documentUrl, {
            responseType: 'blob',
        })
        const blob = data as Blob

        const fileName = headers['content-disposition']?.split('filename=')[1] ?? ''
        return { blob, fileName }
    }

    async downloadPersonalDocument(
        documentType: DocumentType,
    ): Promise<{ blob: Blob; fileName: string }> {
        const documentUrl = `${this._personalDocumentUrl}/${documentType}`

        const { data, headers } = await this._httpClient.get(documentUrl, {
            responseType: 'blob',
        })
        const blob = data as Blob

        const fileName = headers['content-disposition']?.split('filename=')[1] ?? ''
        return { blob, fileName }
    }
}

export const downloadService = new DownloadService()
