import { memo } from 'react'

const EltusLogoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="114" height="26" viewBox="0 0 114 26">
            <g fill="#91A3AE" fillRule="evenodd">
                <path d="M113.17 8.604c-.046-.048-.094-.048-.14-.095-.095-.047-.143-.142-.048-.236.047-.048.52-.568.52-1.182 0-.567-.378-.946-.757-.946-.472 0-.756.426-1.087 1.23-.33.756-.662 1.229-1.465 1.229-.473 0-1.23-.379-1.23-1.466 0-.662.379-1.182.379-1.182 0-.047.094-.141.236-.047.047.047.095.047.142.095.095.047.142.141.047.236-.047.047-.33.473-.33.898 0 .757.472.946.756.946.472 0 .756-.379.992-.946.379-.945.757-1.56 1.56-1.56.71 0 1.277.71 1.277 1.513 0 .804-.473 1.37-.567 1.465-.095.048-.142.095-.284.048zm-4.017 4.726c-.048 0-.142-.046-.142-.14v-.285c0-.094.047-.141.142-.141h2.93c.804 0 1.419-.473 1.419-1.324 0-.804-.615-1.324-1.418-1.324h-2.931c-.048 0-.142-.047-.142-.141V9.69c0-.047.047-.142.142-.142h2.978c1.04 0 1.89.756 1.89 1.844 0 1.087-.803 1.843-1.89 1.843h-2.978v.095zm0 3.263c-.048 0-.142-.048-.142-.142v-.284c0-.047.047-.142.142-.142h4.302v-2.032c0-.095.047-.142.141-.142h.19c.047 0 .141.047.141.142v2.41c0 .095-.047.142-.142.142h-4.632v.048z"></path>
                <path
                    fillRule="nonzero"
                    d="M109.153 20.753c-.048 0-.142-.048-.142-.142v-1.56c0-.851.709-1.56 1.56-1.56.85 0 1.56.709 1.56 1.56v1.134h1.654c.048 0 .142.048.142.142v.284c0 .094-.047.142-.142.142h-4.632zm2.458-1.655c0-.567-.473-1.04-1.087-1.04-.568 0-.993.473-.993 1.04v1.087h2.08v-1.087z"
                ></path>
                <path d="M93.978 18.956l1.182-1.89c.19-.284.662-.237.85-.142.095.047 1.94 1.276 3.594 1.276.992 0 1.749-.567 1.749-1.37 0-.946-.898-1.703-2.6-2.317-2.175-.757-4.87-2.317-4.87-5.058 0-2.27 1.939-4.539 5.815-4.539 2.6 0 4.633 1.23 5.39 1.702.283.142.236.567.141.71l-1.276 1.748c-.19.237-.615.473-.851.284-.237-.095-2.08-1.37-3.593-1.37-.898 0-1.607.566-1.607 1.18 0 .852.756 1.466 2.742 2.223 1.985.709 5.105 2.08 5.105 5.2 0 2.363-2.222 4.727-5.956 4.727-3.262 0-5.106-1.23-5.673-1.75-.284-.188-.33-.283-.142-.614zM76.582 5.625c0-.236.236-.425.473-.425h3.12c.283 0 .472.19.472.425v9.219c0 1.607 1.324 2.883 3.12 2.883 1.797 0 3.168-1.276 3.168-2.883V5.625c0-.236.189-.425.472-.425h3.12c.237 0 .473.19.473.425v9.408c0 3.45-3.215 6.334-7.185 6.334-3.971 0-7.139-2.883-7.139-6.334V5.625h-.094zM66.23 8.51h-3.594c-.283 0-.472-.19-.472-.426V5.578c0-.236.189-.425.472-.425H73.84c.284 0 .473.189.473.425v2.506c0 .236-.19.425-.473.425h-3.593v12.196c0 .237-.236.426-.472.426h-3.026c-.236 0-.473-.19-.473-.426V8.51h-.047zM52.047 5.625c0-.236.19-.425.473-.425h2.978c.237 0 .473.19.473.425v12.197h6.004c.283 0 .472.189.472.425v2.506c0 .236-.189.425-.472.425H52.52c-.284 0-.473-.189-.473-.425V5.625zm-14.749 0c0-.236.19-.425.473-.425h10.447c.284 0 .473.19.473.425v2.506c0 .236-.19.425-.473.425h-6.996v2.837h5.767c.236 0 .473.189.473.425v2.506c0 .236-.237.425-.473.425h-5.767v3.073h6.996c.284 0 .473.189.473.425v2.506c0 .236-.19.425-.473.425H37.771c-.284 0-.473-.189-.473-.425V5.625z"></path>
                <path
                    fillRule="nonzero"
                    d="M26.615.378H1.56C.756.378.095 1.04.095 1.844V24.44c0 .804.661 1.465 1.465 1.465h25.055c.803 0 1.465-.661 1.465-1.465V1.844c0-.804-.662-1.466-1.465-1.466zm-12.528 23.59a2.744 2.744 0 01-2.742-2.743 2.744 2.744 0 012.742-2.741 2.744 2.744 0 012.742 2.741 2.744 2.744 0 01-2.742 2.742zm0-8.084a2.744 2.744 0 01-2.742-2.742 2.744 2.744 0 012.742-2.742 2.744 2.744 0 012.742 2.742 2.744 2.744 0 01-2.742 2.742zm0-8.084a2.744 2.744 0 01-2.742-2.742 2.744 2.744 0 012.742-2.742 2.744 2.744 0 012.742 2.742A2.744 2.744 0 0114.087 7.8z"
                ></path>
            </g>
        </svg>
    )
}

export default memo(EltusLogoIcon)
