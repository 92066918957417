import { globalCss } from '@stitches/react'

export const globalStyles = globalCss({
    '*': {
        '-webkit-tap-highlight-color': 'transparent',
        'box-sizing': 'border-box',
        /* Chrome, Safari, Edge, Opera */
        'input::-webkit-inner-spin-button': {
            '  -webkit-appearance': 'none',
            'margin': 0,
        },
        'input::-webkit-outer-spin-button': {
            '  -webkit-appearance': 'none',
            'margin': 0,
        },
        /* Firefox */
        ' input[type=number]': {
            ' -moz-appearance': 'textfield',
        },
    },
    '*:before': {
        'box-sizing': 'border-box',
    },
    '*:after': {
        'box-sizing': 'border-box',
    },
    'body, html': {
        'fontFamily': '$sourceSansPro',
        'backgroundColor': '$grey1',
        '@media (min-width: 900px)': {
            overflowY: 'hidden',
        },
    },

    'body': {
        fontSize: '16px',
        padding:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        margin: 0,
    },
    'html': {
        'margin': 0,
        'padding': 0,
        '@media (max-width: 900px)': {
            backgroundColor: '$grey2',
        },
    },
    '#root': {
        'display': 'flex',
        'flexDirection': 'column',
        'minHeight': '100vh',
        'justifyContent': 'center',
        '@minLg': {
            height: '100%',
        },
    },
    'p': {
        padding: 0,
        margin: 0,
    },
    'h1, h2, h3, h4, h5, h6': {
        padding: 0,
        margin: 0,
        fontWeight: '$light',
    },

    'button': {
        'cursor': 'pointer',
        'border': 'none',
        'padding': 0,
        'margin': 0,
        '&:focus': {
            outline: 'none',
        },
    },
    'ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
    },

    'a': {
        textDecoction: 'none',
    },

    'a:-webkit-any-link': {
        textDecoration: 'none',
    },
})
