import { Base64 } from 'js-base64'

class LocalStorage {
    save(key: string, value: any) {
        if (typeof window === 'undefined') return

        try {
            const encodedData = Base64.encode(JSON.stringify(value))
            localStorage.setItem(key, encodedData)
        } catch (error) {
            // Error saving data
            console.error('storage', error)
            localStorage.removeItem(key)
            window.dispatchEvent(new Event('localStorage'))
        }
    }

    load(key: string) {
        if (typeof window === 'undefined') return

        try {
            const data = localStorage.getItem(key)
            if (!data) {
                return null
            }

            // We have data!!
            const baseData = Base64.decode(data)
            return JSON.parse(baseData)
        } catch (error) {
            console.error('storage', error)
            localStorage.removeItem(key)
            window.dispatchEvent(new Event('localStorage'))
        }
    }

    remove(key: string) {
        if (typeof window === 'undefined') return

        try {
            localStorage.removeItem(key)
            window.dispatchEvent(new Event('localStorage'))
        } catch (error) {
            console.error('storage', error)
        }
    }

    clear() {
        if (typeof window === 'undefined') return

        localStorage.clear()
        window.dispatchEvent(new Event('localStorage'))
    }
}

export const storage = new LocalStorage()
