import { memo } from 'react'

const RingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
        <path
            fill="#000"
            fillRule="evenodd"
            d="M11.736.482a1.5 1.5 0 0 0-1.791.624 5.502 5.502 0 0 0-5.6 3.603l-1.54 4.229-1.953.66a.4.4 0 0 0-.009.754l5.248 1.91a3 3 0 0 0 5.61 2.042l2.034.74a.4.4 0 0 0 .479-.583l-1.072-1.76 1.539-4.23a5.502 5.502 0 0 0-1.974-6.36 1.5 1.5 0 0 0-.971-1.629ZM9.793 13.61 8 12.958V13a1 1 0 0 0 1.792.61Zm-3.57-8.217a3.5 3.5 0 0 1 6.578 2.395l-1.539 4.228-6.578-2.394 1.54-4.229Z"
            clipRule="evenodd"
        />
        <mask
            id="a"
            width={16}
            height={16}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'luminance',
            }}
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M11.736.482a1.5 1.5 0 0 0-1.791.624 5.502 5.502 0 0 0-5.6 3.603l-1.54 4.229-1.953.66a.4.4 0 0 0-.009.754l5.248 1.91a3 3 0 0 0 5.61 2.042l2.034.74a.4.4 0 0 0 .479-.583l-1.072-1.76 1.539-4.23a5.502 5.502 0 0 0-1.974-6.36 1.5 1.5 0 0 0-.971-1.629ZM9.793 13.61 8 12.958V13a1 1 0 0 0 1.792.61Zm-3.57-8.217a3.5 3.5 0 0 1 6.578 2.395l-1.539 4.228-6.578-2.394 1.54-4.229Z"
                clipRule="evenodd"
            />
        </mask>
        <g mask="url(#a)">
            <path fill="#8190A6" d="M0 0h16v16H0z" />
        </g>
    </svg>
)
export default memo(RingIcon)
