import { memo } from 'react'

const CheckIcon = () => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity={0.12} cx={20} cy={20} r={20} fill="#FF786E" />
        <path
            d="M28.333 19.239v.766a8.333 8.333 0 1 1-4.941-7.616"
            stroke="#FF786E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.333 13.334 20 21.675l-2.5-2.5"
            stroke="#FF786E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default memo(CheckIcon)
