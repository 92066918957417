export class PersonalDetails {
    readonly firstName: string
    readonly lastName: string
    readonly birthFirstName?: string
    readonly birthLastName?: string
    readonly mothersMaidenName?: string
    readonly identityCardType?: string
    readonly identityCardNumber?: string
    readonly email: string
    readonly postZipCodebookId: number
    readonly settlementCodebookId: number
    readonly streetCodebookId?: number
    readonly street?: string
    readonly houseNumber: string | number
    readonly bankAccountNumber?: string
    readonly countryOfBirthCodebookId: number
    readonly placeOfBirth: string
    readonly dateOfBirth: string
    readonly gender: string
    readonly taxNumber: string
    readonly terms: boolean
    readonly citizenship?: boolean
    readonly marketingConsent: boolean
    readonly address?: string
    readonly additionalAddress?: string
    readonly creditBureauConsent?: boolean

    constructor(data: any) {
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.birthFirstName = data.firstName
        this.birthLastName = data.birthLastName
        this.email = data.email
        this.postZipCodebookId = data.postZipCodebookId
        this.settlementCodebookId = data.settlementCodebookId
        this.streetCodebookId = data.streetCodebookId
        this.street = data.street
        this.houseNumber = data.houseNumber
        this.marketingConsent = data.marketingConsent
        this.bankAccountNumber = data.bankAccountNumber
        this.countryOfBirthCodebookId = data.countryOfBirthCodebookId
        this.dateOfBirth = data.dateOfBirth
        this.gender = data.gender
        this.placeOfBirth = data.placeOfBirth
        this.taxNumber = data.taxNumber
        this.terms = data.terms
        this.citizenship = data?.citizenship
        this.address = data?.address
        this.additionalAddress = data?.additionalAddress
        this.mothersMaidenName = data.birthFirstName
        this.identityCardType = data.identityCardType
        this.identityCardNumber = data.identityCardNumber
    }
}
