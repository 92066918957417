import {
    AuthorizationStatusResponse,
    VendorRequestAuthorizationStatus,
    VendorRequestAuthorizationStatusCode,
} from './dto-client'

export class AuthorizationCodeStatusData {
    readonly status: VendorRequestAuthorizationStatus
    readonly statusCode: VendorRequestAuthorizationStatusCode
    readonly initialAmount: number
    readonly authorizedAmount: number

    constructor(data: AuthorizationStatusResponse) {
        this.status = data?.status
        this.statusCode = data?.statusCode
        this.initialAmount = data?.initialAmount
        this.authorizedAmount = data?.authorizedAmount
    }

    get remainingAmount(): number {
        return this.initialAmount - this.authorizedAmount
    }

    get shortAmount(): number {
        return this.authorizedAmount - this.initialAmount
    }

    get isAuthorized(): boolean {
        return this.status === VendorRequestAuthorizationStatus.AUTHORIZED
    }

    get isVendorConfirmed(): boolean {
        return this.status === VendorRequestAuthorizationStatus.VENDOR_CONFIRMED
    }

    get isRejected(): boolean {
        return this.status === VendorRequestAuthorizationStatus.REJECTED
    }

    get isPending(): boolean {
        return this.status === VendorRequestAuthorizationStatus.PENDING
    }

    get isExpired(): boolean {
        return (
            this.status === VendorRequestAuthorizationStatus.REJECTED &&
            this.statusCode === VendorRequestAuthorizationStatusCode.EXPIRED_GLOBAL
        )
    }

    get isCartTotalBelowMinimum(): boolean {
        return this.statusCode === VendorRequestAuthorizationStatusCode.GLOBAL_MIN_REQUIREMENT
    }

    get isCartTotalAboveMaximum(): boolean {
        return (
            this.statusCode === VendorRequestAuthorizationStatusCode.AMOUNT_EXCEEDS_INITIALLY_AMOUNT
        )
    }

    get isPurchaseAmountShort(): boolean {
        return this.isCartTotalAboveMaximum && this.shortAmount > 0
    }
}
