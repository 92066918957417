import { CSS } from '@leanpay/stitches'
import { IconName } from '@leanpay/utils'
import { memo, useCallback } from 'react'

import AddikoLogoIcon from '../../assets/icons/AddikoLogoIcon'
import AliansaLogoIcon from '../../assets/icons/AliansaLogoIcon'
import ApprovedIcon from '../../assets/icons/ApprovedIcon'
import ArrowIcon from '../../assets/icons/ArrowIcon'
import BackIcon from '../../assets/icons/BackIcon'
import BigBangLogoIcon from '../../assets/icons/BigBangLogoIcon'
import BralkoLogoIcon from '../../assets/icons/BralkoLogoIcon'
import BurgerMenuIcon from '../../assets/icons/BurgerMenuIcon'
import CalculatorIcon from '../../assets/icons/CalculatorIcon'
import CalendarIcon from '../../assets/icons/CalendarIcon'
import CheckBoxCheckedIcon from '../../assets/icons/CheckBoxCheckedIcon'
import CheckIcon from '../../assets/icons/CheckIcon'
import CheckMarkIcon from '../../assets/icons/CheckMarkIcon'
import CheckedIcon from '../../assets/icons/CheckedIcon'
import CloseCircleIcon from '../../assets/icons/CloseCircleIcon'
import CloseIcon from '../../assets/icons/CloseIcon'
import ContractIcon from '../../assets/icons/ContractIcon'
import ContractSearchIcon from '../../assets/icons/ContractSearchIcon'
import CreditCardIcon from '../../assets/icons/CreditCardIcon'
import DashboardUserIcon from '../../assets/icons/DashboardUserIcon'
import DocumentIcon from '../../assets/icons/DocumentIcon'
import DownloadIcon from '../../assets/icons/DownloadIcon'
import EltusLogoIcon from '../../assets/icons/EltusLogoIcon'
import EmailIcon from '../../assets/icons/EmailIcon'
import EmudiaLogoIcon from '../../assets/icons/EmudiaLogoIcon'
import EplLogoIcon from '../../assets/icons/EplLogoIcon'
import ErrorIcon from '../../assets/icons/ErrorIcon'
import FAQIcon from '../../assets/icons/FAQIcon'
import FilterIcon from '../../assets/icons/FilterIcon'
import GbLogoIcon from '../../assets/icons/GbLogoIcon'
import IStyleLogoIcon from '../../assets/icons/IStyleLogoIcon'
import IdCardIcon from '../../assets/icons/IdCardIcon'
import IdeoLogoIcon from '../../assets/icons/IdeoLogoIcon'
import InfoIcon from '../../assets/icons/InfoIcon'
import InvoiceIcon from '../../assets/icons/InvoiceIcon'
import IonLogoIcon from '../../assets/icons/IonLogoIcon'
import IstoreLogoIcon from '../../assets/icons/IstoreLogoIcon'
import LeanPayTreeIcon from '../../assets/icons/LeanPayTreeIcon'
import LeanpayTreeSecondaryIcon from '../../assets/icons/LeanpayTreeSecondaryIcon'
import LockIcon from '../../assets/icons/LockIcon'
import LogoutIcon from '../../assets/icons/LogoutIcon'
import MasterCardIcon from '../../assets/icons/MasterCardIcon'
import MedtLogoIcon from '../../assets/icons/MedtLogoIcon'
import MobilePhoneIcon from '../../assets/icons/MobilePhoneIcon'
import OffersIcon from '../../assets/icons/OffersIcon'
import PlusCircleIcon from '../../assets/icons/PlusCircleIcon'
import PowerByLeanPayIcon from '../../assets/icons/PowerByLeanPayIcon'
import QuestionMarkIcon from '../../assets/icons/QuestionMarkIcon'
import QuickCheckIcon from '../../assets/icons/QuickCheckIcon'
import RedirectIcon from '../../assets/icons/RedirectIcon'
import RejectedIcon from '../../assets/icons/RejectedIcon'
import RingIcon from '../../assets/icons/RingIcon'
import SearchIcon from '../../assets/icons/SearchIcon'
import SettingsIcon from '../../assets/icons/SettingsIcon'
import ShopBagIcon from '../../assets/icons/ShopBagIcon'
import ShopCartIcon from '../../assets/icons/ShopCartIcon'
import SisbonLogoIcon from '../../assets/icons/SisbonLogoIcon'
import SmartphoneIcon from '../../assets/icons/SmartphoneIcon'
import ToolTipIcon from '../../assets/icons/ToolTipIcon'
import TransactionsIcon from '../../assets/icons/TransactionsIcon'
import TrigalLogoIcon from '../../assets/icons/TrigalLogoIcon'
import TriglavLogoIcon from '../../assets/icons/TriglavLogoIcon'
import UserIcon from '../../assets/icons/UserIcon'
import UserProfileIcon from '../../assets/icons/UserProfileIcon'
import VerificationStatusError from '../../assets/icons/VerificationStatusError'
import VerificationStatusRejected from '../../assets/icons/VerificationStatusRejected'
import VerificationStatusSuccess from '../../assets/icons/VerificationStatusSuccess'
import VerificationStatusTimeout from '../../assets/icons/VerificationStatusTimeout'
import VerificationStatusWarning from '../../assets/icons/VerificationStatusWarning'
import VisaIcon from '../../assets/icons/VisaIcon'
import WarningIcon from '../../assets/icons/WarningIcon'
import { Box } from '../common'
import SettingsIconSecondary from '../../assets/icons/SettingsIconSecondary'
import SpeedyClockIcon from '../../assets/icons/SpeedyClockIcon'
import QuestionMarkSelfCheckoutIcon from '../../assets/icons/QuestionMarkSelfCheckoutIcon'
import CookieIcon from '../../assets/icons/CookieIcon'

interface InfoIconProps {
    iconName: string
    css?: CSS
}

const Icon = ({ iconName, css }: InfoIconProps) => {
    const renderIcon = useCallback(() => {
        switch (iconName) {
            case IconName.CloseCircleIcon:
                return <CloseCircleIcon />
            case IconName.Info:
                return <InfoIcon />
            case IconName.Error:
                return <ErrorIcon />
            case IconName.Success:
                return <ApprovedIcon />
            case IconName.Warning:
                return <WarningIcon />
            case IconName.Approved:
                return <ApprovedIcon />
            case IconName.Rejected:
                return <RejectedIcon />
            case IconName.Timeout:
                return <WarningIcon />
            case IconName.VerificationStatusError:
                return <VerificationStatusError />
            case IconName.VerificationStatusReject:
                return <VerificationStatusRejected />
            case IconName.VerificationStatusSuccess:
                return <VerificationStatusSuccess />
            case IconName.VerificationStatusTimeout:
                return <VerificationStatusTimeout />
            case IconName.VerificationStatusWarning:
                return <VerificationStatusWarning />
            case IconName.LockIcon:
                return <LockIcon />
            case IconName.ShopCartIcon:
                return <ShopCartIcon />
            case IconName.LeanPayTreeIcon:
                return <LeanPayTreeIcon />
            case IconName.FAQIcon:
                return <FAQIcon />
            case IconName.TooltipIcon:
                return <ToolTipIcon />
            case IconName.CheckboxCheckedIcon:
                return <CheckBoxCheckedIcon />
            case IconName.SmartphoneIcon:
                return <SmartphoneIcon />
            case IconName.UserIcon:
                return <UserIcon />
            case IconName.QuickCheckIcon:
                return <QuickCheckIcon />
            case IconName.CalendarIcon:
                return <CalendarIcon />
            case IconName.PowerByLeanPayIcon:
                return <PowerByLeanPayIcon />
            case IconName.CheckMarkIcon:
                return <CheckMarkIcon />
            case IconName.SisbonLogoIcon:
                return <SisbonLogoIcon />
            case IconName.DownloadIcon:
                return <DownloadIcon />
            case IconName.ContractIcon:
                return <ContractIcon />
            case IconName.ContractSearchIcon:
                return <ContractSearchIcon />
            case IconName.ArrowIcon:
                return <ArrowIcon />
            case IconName.MasterCardIcon:
                return <MasterCardIcon />
            case IconName.VisaIcon:
                return <VisaIcon />
            case IconName.IdCardIcon:
                return <IdCardIcon />
            case IconName.AliansaLogoIcon:
                return <AliansaLogoIcon />
            case IconName.BigBangLogoIcon:
                return <BigBangLogoIcon />
            case IconName.BralkoLogoIcon:
                return <BralkoLogoIcon />
            case IconName.EltusLogoIcon:
                return <EltusLogoIcon />
            case IconName.EmudiaLogoIcon:
                return <EmudiaLogoIcon />
            case IconName.EplLogoIcon:
                return <EplLogoIcon />
            case IconName.IdeoLogoIcon:
                return <IdeoLogoIcon />
            case IconName.IstoreLogoIcon:
                return <IstoreLogoIcon />
            case IconName.AddikoLogoIcon:
                return <AddikoLogoIcon />
            case IconName.DocumentIcon:
                return <DocumentIcon />
            case IconName.CreditCardIcon:
                return <CreditCardIcon />
            case IconName.BurgerMenuIcon:
                return <BurgerMenuIcon />
            case IconName.RingIcon:
                return <RingIcon />
            case IconName.DashboardUserIcon:
                return <DashboardUserIcon />
            case IconName.LogoutIcon:
                return <LogoutIcon />
            case IconName.TransactionsIcon:
                return <TransactionsIcon />
            case IconName.MobilePhoneIcon:
                return <MobilePhoneIcon />
            case IconName.ShopBagIcon:
                return <ShopBagIcon />
            case IconName.CalculatorIcon:
                return <CalculatorIcon />
            case IconName.CheckedIcon:
                return <CheckedIcon />
            case IconName.SettingsIcon:
                return <SettingsIcon />
            case IconName.SettingsIconSecondary:
                return <SettingsIconSecondary />
            case IconName.RedirectIcon:
                return <RedirectIcon />
            case IconName.SearchIcon:
                return <SearchIcon />
            case IconName.QuestionMarkIcon:
                return <QuestionMarkIcon />
            case IconName.OffersIcon:
                return <OffersIcon />
            case IconName.InvoiceIcon:
                return <InvoiceIcon />
            case IconName.FilterIcon:
                return <FilterIcon />
            case IconName.LeanPayTreeSecondaryIcon:
                return <LeanpayTreeSecondaryIcon />
            case IconName.EmailIcon:
                return <EmailIcon />
            case IconName.UserProfileIcon:
                return <UserProfileIcon />
            case IconName.PlusCircleIcon:
                return <PlusCircleIcon />
            case IconName.CloseIcon:
                return <CloseIcon />
            case IconName.CheckIcon:
                return <CheckIcon />
            case IconName.BackIcon:
                return <BackIcon />
            case IconName.TrigalLogoIcon:
                return <TrigalLogoIcon />
            case IconName.TriglavLogoIcon:
                return <TriglavLogoIcon />
            case IconName.GbLogoIcon:
                return <GbLogoIcon />
            case IconName.MedtLogoIcon:
                return <MedtLogoIcon />
            case IconName.IonLogoIcon:
                return <IonLogoIcon />
            case IconName.IStyleLogoIcon:
                return <IStyleLogoIcon />
            case IconName.SpeedyClockIcon:
                return <SpeedyClockIcon />
            case IconName.QuestionMarkSelfCheckoutIcon:
                return <QuestionMarkSelfCheckoutIcon />
            case IconName.CookieIcon:
                return <CookieIcon />

            default:
                return null
        }
    }, [iconName])

    return (
        <Box
            css={{
                display: 'flex',
                justifyContent: 'center',
                ...css,
            }}
        >
            {renderIcon()}
        </Box>
    )
}

export default memo(Icon)
