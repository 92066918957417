const DocumentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none">
            <path
                fill="#000"
                fillRule="evenodd"
                d="M9.586.5H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.914a1 1 0 0 0-.293-.707L10.293.793A1 1 0 0 0 9.586.5ZM3 14.5v-12h6v3a1 1 0 0 0 1 1h3v8H3Zm2-10a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2H5Zm-1 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm1 2a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H5Z"
                clipRule="evenodd"
            />
            <mask
                id="a"
                width={14}
                height={17}
                x={1}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M9.586.5H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.914a1 1 0 0 0-.293-.707L10.293.793A1 1 0 0 0 9.586.5ZM3 14.5v-12h6v3a1 1 0 0 0 1 1h3v8H3Zm2-10a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2H5Zm-1 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm1 2a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H5Z"
                    clipRule="evenodd"
                />
            </mask>
            <g mask="url(#a)">
                <path fill="#FF6A71" d="M0 .5h16v16H0z" />
            </g>
        </svg>
    )
}

export default DocumentIcon
