import { memo } from 'react'

const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <path
                fill="#8190A6"
                fillRule="evenodd"
                d="M7 14a7 7 0 1 1 5.606-2.808l3.101 3.1a1 1 0 0 1-1.414 1.415l-3.1-3.1A6.97 6.97 0 0 1 7 14Zm0-2A5 5 0 1 0 7 2a5 5 0 0 0 0 10Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(SearchIcon)
