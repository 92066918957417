import { memo } from 'react'

const LockIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.509 16.8h7.908v-1.898a3.397 3.397 0 0 0-1.01-2.388 3.38 3.38 0 0 0-2.404-.977 3.447 3.447 0 0 0-2.117.735 3.437 3.437 0 0 0-1.21 1.883.84.84 0 0 1-.569.613.833.833 0 0 1-1.059-.984 5.096 5.096 0 0 1 1.802-2.812A5.11 5.11 0 0 1 20 9.87a5.077 5.077 0 0 1 3.58 1.464 5.065 5.065 0 0 1 1.5 3.565v1.961a2.907 2.907 0 0 1 1.665 1.014c.423.52.653 1.167.653 1.835v6.307a2.91 2.91 0 0 1-.854 2.058 2.91 2.91 0 0 1-2.058.854H15.51a2.91 2.91 0 0 1-2.058-.854 2.91 2.91 0 0 1-.854-2.058v-6.303a2.916 2.916 0 0 1 2.912-2.912Zm-1.248 9.219a1.244 1.244 0 0 0 1.248 1.248h8.977a1.244 1.244 0 0 0 1.248-1.248v-6.307a1.244 1.244 0 0 0-1.248-1.248H15.51a1.244 1.244 0 0 0-1.248 1.248v6.307Z"
            fill="#FF786E"
        />
        <circle opacity={0.12} cx={20} cy={20} r={20} fill="#FF786E" />
    </svg>
)

export default memo(LockIcon)
