import { memo } from 'react'

const LogoutIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <path
                fill="#000"
                fillRule="evenodd"
                d="M8 1a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V2a1 1 0 0 0-1-1Zm0 15a7 7 0 0 0 2-13.71v2.126a5.001 5.001 0 1 1-4 0V2.29A7 7 0 0 0 8 16Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(LogoutIcon)
