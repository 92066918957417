const CookieIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
        <g clipPath="url(#a)">
            <path
                fill="#FF6A71"
                d="M24.102 12.078A6.04 6.04 0 0 1 18.13 6.04a6.04 6.04 0 0 1-6.036-5.97A6.23 6.23 0 0 0 8.33.678L5.067 2.34A6.242 6.242 0 0 0 2.34 5.069L.682 8.32a6.262 6.262 0 0 0-.605 3.821l.57 3.601a6.256 6.256 0 0 0 1.754 3.444l2.586 2.586a6.234 6.234 0 0 0 3.433 1.75l3.621.573a6.228 6.228 0 0 0 3.802-.602l3.264-1.663a6.244 6.244 0 0 0 2.728-2.729l1.657-3.252a6.259 6.259 0 0 0 .61-3.772ZM8.309 17.374a1.51 1.51 0 1 1 0-3.022 1.51 1.51 0 0 1 0 3.022Zm1.51-7.554a1.51 1.51 0 1 1 .001-3.022 1.51 1.51 0 0 1 0 3.022Zm7.554 6.043a1.511 1.511 0 1 1 0-3.022 1.511 1.511 0 0 1 0 3.022Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24.172v24.172H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default CookieIcon
