const SvgComponent = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={140} height={43}>
        <g fill="none" fillRule="nonzero">
            <path
                fill="#91A3AE"
                d="M30.422 8.457a4.05 4.05 0 0 0 4.059-4.062 4.035 4.035 0 1 0-8.072 0 4.013 4.013 0 0 0 4.013 4.062M15.171 27.489c0 1.37-1.526 2.25-3.769 2.25-1.526 0-2.743-.293-3.67-.98-1.075-.831-1.419-1.526-1.468-2.594H.88v2.846l.18.042a124.422 124.422 0 0 0 17.982 3.208 7.314 7.314 0 0 0 2.194-5.459c0-9.59-15.072-6.504-15.072-9.735 0-1.026 1.224-1.762 3.181-1.762 2.934 0 4.6.785 5.04 2.4h5.283v-6.707h-5.284v.786a15.028 15.028 0 0 0-5.821-1.126C3.571 10.658 0 13.493 0 17.46a6.045 6.045 0 0 0 1.713 4.196c4.7 4.696 13.458 2.445 13.458 5.821"
            />
            <path
                fill="#939598"
                d="M6.264 32.967c1.076 1.026 2.544 1.613 5.87 1.613a11.228 11.228 0 0 0 5.242-1.144c-5.56-.64-11.07-1.65-16.495-3.025v3.73h5.383v-1.174zm16.968 1.041v.134h1.823c-.606-.042-1.217-.084-1.823-.134"
            />
            <path
                fill="#91A3AE"
                d="M23.232 28.809v3.837c4.493.351 9.193.542 14.236.542v-4.38h-3.376v-17.81h-10.86v5.34h3.372v12.47h-3.372z"
            />
            <path
                fill="#939598"
                d="M48.351 34.294c1.07.213 2.16.309 3.25.286 1.407.021 2.805-.2 4.136-.656-2.465.145-4.926.272-7.386.382"
            />
            <path
                fill="#91A3AE"
                d="M54.634 27.489c0 1.37-1.526 2.25-3.769 2.25-1.525 0-2.739-.293-3.666-.98-1.08-.831-1.419-1.526-1.468-2.594h-5.383v6.985a254.96 254.96 0 0 0 5.383-.118v-.065l.068.06c4.197-.133 8.393-.38 12.566-.629 1.545-1.38 2.335-3.33 2.335-5.596 0-9.59-15.068-6.504-15.068-9.735 0-1.026 1.224-1.762 3.181-1.762 2.934 0 4.6.785 5.04 2.4h5.275v-6.707h-5.276v.786a15.027 15.027 0 0 0-5.82-1.126c-4.994 0-8.565 2.835-8.565 6.802a6.045 6.045 0 0 0 1.713 4.196c4.7 4.696 13.454 2.445 13.454 5.821"
            />
            <path
                fill="#F47920"
                d="M113.804 22.549c0-.233-.019-.454-.026-.68v1.378c0-.237.026-.462.026-.698"
            />
            <path
                fill="#91A3AE"
                d="M79.098 16.53c2.838 0 4.444 2.162 4.444 5.977 0 3.914-1.713 6.26-4.505 6.26s-4.55-2.346-4.55-6.066c0-3.868 1.712-6.164 4.6-6.164M63.149 28.809v3.269c2.36-.165 4.71-.334 7.05-.507 6.194-.454 12.512-.912 18.852-1.229 1.25-1.842 2.083-4.402 2.083-7.835 0-7.096-4.06-12.04-9.98-12.04-2.69 0-4.501.882-6.607 3.132V.92H63.15v5.34h3.914v22.55H63.15zm38.475-12.623c2.937 0 4.6 2.35 4.6 6.363s-1.663 6.408-4.6 6.408c-2.838 0-4.551-2.353-4.551-6.263 0-4.158 1.614-6.508 4.551-6.508m-9.98-.248c.104.244.2.496.29.763.039.11.08.217.119.328a16.986 16.986 0 0 1 .381 1.404c.077.328.141.656.199.995 0 .069.026.138.034.206.065.405.11.813.145 1.232l.023.32c.03.436.05.874.05 1.325 0 .53-.023 1.041-.062 1.526 0 .083 0 .167-.019.251a19.665 19.665 0 0 1-.236 1.725 19.93 19.93 0 0 1-.195.896 14.264 14.264 0 0 1-1.262 3.33c3.997-.171 8.01-.282 11.985-.282 2.884 0 5.665.061 8.37.172a12.506 12.506 0 0 0 2.338-7.584c0-7.782-5.428-12.089-12.18-12.089-4.051 0-7.923 1.671-10.166 5.04a.884.884 0 0 1 .038.083l.149.355"
            />
            <path
                fill="#91A3AE"
                d="M115.555 22.549a14.45 14.45 0 0 1-2.067 7.675c4.078.217 7.972.568 11.73 1.064V28.37h-3.082v-9.754a6.636 6.636 0 0 1 3.627-1.309c.847 0 1.713.256 1.713 2.156V31.6a124.94 124.94 0 0 1 11.734 2.316V28.37h-3.376v-9.834c0-6.13-3.864-8.316-7.48-8.316-2.186 0-4.036.614-6.218 2.09v-1.747h-11.933v.713c3.361 2.4 5.34 6.352 5.34 11.273m1.141 10.948c7.774-.046 15.536.617 23.19 1.98-12.26-3.155-24.517-4.162-36.778-4.162-21.862 0-43.731 3.212-65.613 3.212-12.245.008-24.513-1.003-36.762-4.154v10.22a127.63 127.63 0 0 0 23.193 1.99c30.923 0 61.845-9.086 92.77-9.086"
            />
        </g>
    </svg>
)
export default SvgComponent
