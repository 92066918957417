import { separateThousands } from '@leanpay/utils'

import { CurrencyInfo, CurrencySymbols } from './dto-client'

//Used for SLO, ROM
const LOCALE = 'hr-HR'

export class Currency {
    readonly primaryCurrency: string
    readonly secondaryCurrency: string | null
    readonly multiCurrency: boolean
    readonly conversionRate?: number | null

    constructor(data: CurrencyInfo) {
        this.primaryCurrency = data?.primaryCurrency ?? ''
        this.secondaryCurrency = data?.secondaryCurrency || null
        this.multiCurrency = !!data?.multiCurrency
        this.conversionRate = data?.conversionRate ?? null
    }

    get currencySymbol() {
        return CurrencySymbols[this.primaryCurrency as 'EUR' | 'lei' | 'Ft'] || ''
    }

    displayCurrency(amount?: number) {
        if (amount === undefined) return
        return this.formatCurrency(amount, ' / ')
    }

    displayCurrencyWithBreakAndHtml(amount: number) {
        return this.formatCurrency(amount, '<br/>')
    }

    private formatCurrency(amount: number, separator: string) {
        const formattedAmount = amount.toLocaleString(LOCALE, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })

        if (this.multiCurrency && this.conversionRate && this.secondaryCurrency) {
            const convertedAmount = amount * this.conversionRate

            if (this.currencySymbol === CurrencySymbols.Ft) {
                return `${amount} ${
                    this.currencySymbol
                }${separator}${convertedAmount.toLocaleString()}`
            }

            return `${formattedAmount} ${
                this.currencySymbol
            }${separator}${convertedAmount.toLocaleString(LOCALE, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })} ${this.secondaryCurrency}`
        }

        if (this.currencySymbol === CurrencySymbols.Ft) {
            return `${separateThousands(amount)} ${this.currencySymbol}`
        }

        return `${formattedAmount} ${this.currencySymbol}`
    }
}
