import { memo } from 'react'

const ToolTipIcon = () => (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM7 8C7 7.44772 7.44772 7 8 7C8.55229 7 9 7.44772 9 8V11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11V8ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55229 6 9 5.55228 9 5C9 4.44772 8.55229 4 8 4Z"
            fill="black"
        />
        <mask
            id="mask0_163_6365"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={16}
            height={16}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM7 8C7 7.44772 7.44772 7 8 7C8.55229 7 9 7.44772 9 8V11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11V8ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6C8.55229 6 9 5.55228 9 5C9 4.44772 8.55229 4 8 4Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_163_6365)">
            <rect width={16} height={16} fill="#8190A6" />
        </g>
    </svg>
)

export default memo(ToolTipIcon)
