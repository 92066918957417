import { memo } from 'react'

const DownloadIcon = () => {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={12} cy={12} r={12} fill="#FF786E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0001 12V6C13.0001 5.44772 12.5523 5 12.0001 5C11.4478 5 11.0001 5.44772 11.0001 6V12L8.60006 10.2C8.15823 9.86863 7.53143 9.95817 7.20006 10.4C6.86869 10.8418 6.95823 11.4686 7.40006 11.8L11.4001 14.8C11.7556 15.0667 12.2445 15.0667 12.6001 14.8L16.6001 11.8C17.0419 11.4686 17.1314 10.8418 16.8001 10.4C16.4687 9.95817 15.8419 9.86863 15.4001 10.2L13.0001 12ZM8.00006 18C7.44777 18 7.00006 17.5523 7.00006 17C7.00006 16.4477 7.44777 16 8.00006 16H16.0001C16.5523 16 17.0001 16.4477 17.0001 17C17.0001 17.5523 16.5523 18 16.0001 18H8.00006Z"
                fill="black"
            />
            <mask
                id="mask0_472_11672"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x={7}
                y={5}
                width={11}
                height={13}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0001 12V6C13.0001 5.44772 12.5523 5 12.0001 5C11.4478 5 11.0001 5.44772 11.0001 6V12L8.60006 10.2C8.15823 9.86863 7.53143 9.95817 7.20006 10.4C6.86869 10.8418 6.95823 11.4686 7.40006 11.8L11.4001 14.8C11.7556 15.0667 12.2445 15.0667 12.6001 14.8L16.6001 11.8C17.0419 11.4686 17.1314 10.8418 16.8001 10.4C16.4687 9.95817 15.8419 9.86863 15.4001 10.2L13.0001 12ZM8.00006 18C7.44777 18 7.00006 17.5523 7.00006 17C7.00006 16.4477 7.44777 16 8.00006 16H16.0001C16.5523 16 17.0001 16.4477 17.0001 17C17.0001 17.5523 16.5523 18 16.0001 18H8.00006Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_472_11672)">
                <rect x={4} y={4} width={16} height={16} fill="white" />
            </g>
        </svg>
    )
}

export default memo(DownloadIcon)
