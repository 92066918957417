import { memo } from 'react'

const SettingsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <mask
                id="a"
                width={16}
                height={16}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M.06.04H16V16H.06V.04Z"
                    clipRule="evenodd"
                />
            </mask>
            <g fill="#fff" fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
                <path d="M6.955 12.438c-1.297-.442-1.427-.53-2.428-1.349L4.419 11l-1.407.783a5.982 5.982 0 0 1-.911-1.519l1.408-.784-.026-.133c-.234-1.237-.25-1.389 0-2.7l.026-.134L2.1 5.729c.223-.54.528-1.05.91-1.518l1.408.783.109-.089c1.022-.841 1.158-.917 2.427-1.349l.133-.046V1.944a6.778 6.778 0 0 1 1.822 0V3.51l.133.046c1.276.435 1.412.511 2.428 1.349l.107.089 1.407-.784c.385.469.69.979.913 1.519l-1.408.784.026.134c.234 1.235.249 1.386 0 2.699l-.026.134 1.408.784a5.982 5.982 0 0 1-.912 1.519L11.578 11l-.107.089c-1.024.842-1.16.917-2.428 1.349l-.133.046v1.566a6.466 6.466 0 0 1-1.822 0v-1.566l-.133-.046Zm8.373-3.562-.693-.385c.024-.328.024-.66 0-.988l.699-.386c.544-.304.793-.918.602-1.491a7.904 7.904 0 0 0-1.832-3.054 1.354 1.354 0 0 0-1.647-.243l-.693.385a6.504 6.504 0 0 0-.893-.497v-.768c0-.605-.43-1.121-1.043-1.254a8.666 8.666 0 0 0-3.656 0c-.615.133-1.044.649-1.044 1.254v.768a6.65 6.65 0 0 0-.892.497l-.694-.385a1.357 1.357 0 0 0-1.646.242A8.026 8.026 0 0 0 .063 5.619 1.272 1.272 0 0 0 .67 7.122l.694.385a6.28 6.28 0 0 0 0 .989l-.7.385c-.54.302-.792.932-.602 1.496a7.894 7.894 0 0 0 1.833 3.055c.424.443 1.102.54 1.646.243l.694-.385c.289.191.588.357.892.495v.769c0 .605.429 1.121 1.044 1.254A8.689 8.689 0 0 0 8 16c.628 0 1.243-.065 1.827-.192.614-.133 1.044-.649 1.044-1.254v-.769c.304-.137.603-.304.893-.495l.693.385a1.363 1.363 0 0 0 1.645-.244 8 8 0 0 0 1.833-3.049 1.274 1.274 0 0 0-.607-1.506Z" />
                <path d="M9.382 7.997c0 .734-.62 1.331-1.383 1.331-.764 0-1.385-.597-1.385-1.331 0-.735.621-1.333 1.385-1.333.762 0 1.383.598 1.383 1.333Zm1.961 0c0-1.776-1.5-3.22-3.344-3.22-1.845 0-3.345 1.444-3.345 3.22 0 1.776 1.5 3.22 3.345 3.22 1.844 0 3.344-1.444 3.344-3.22Z" />
            </g>
        </svg>
    )
}

export default memo(SettingsIcon)
