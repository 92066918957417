import { memo } from 'react'

const SmartphoneIcon = () => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity={0.12} cx={20} cy={20} r={20} fill="#FF786E" />
        <rect
            x={14.167}
            y={11.667}
            width={11.667}
            height={16.667}
            rx={2}
            stroke="#FF786E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx={20} cy={25} r={0.833} fill="#FF786E" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.056 31.086 22.47 27.5a2.083 2.083 0 1 1 2.947-2.946l3.586 3.586"
            fill="#FFEEED"
        />
        <path
            d="M26.056 31.086 22.47 27.5a2.083 2.083 0 1 1 2.947-2.946l3.586 3.586"
            stroke="#FF786E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default memo(SmartphoneIcon)
