const SvgComponent = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={140} height={47}>
        <path
            fill="#91A3AE"
            fillRule="nonzero"
            d="M58.359 10.178h9.913v18.604h8.337v8.703h-18.25zm35.164-.214c-8.363 0-15.136 6.218-15.136 13.874 0 7.656 6.786 13.874 15.136 13.874s15.135-6.218 15.135-13.874c0-7.656-6.773-13.874-15.135-13.874zm0 19.36a5.26 5.26 0 1 1 .025.001h-.025zM112 10.178v27.307h9.485V23.838l8.337 13.647h9.51V10.178h-9.46V24.33l-8.438-14.152zM51.434 22.324 13.655.487 38.544 0zM12.852 45.721l38.582-22.135L12.852 1.261 0 23.497l13.199 22.224zm38.582-20.874L13.655 46.982h24.839z"
        />
    </svg>
)
export default SvgComponent
