const ArrowIcon = () => {
    return (
        <svg
            width={16}
            height={17}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.70697 12.5224L10.5355 9.68548C10.9288 9.29107 10.929 8.6617 10.5421 8.27364L7.70059 5.42379C7.31889 5.04096 6.68342 5.03865 6.29289 5.43033C5.89965 5.82473 5.89945 6.45411 6.28637 6.84217L8.41421 8.97629L6.29276 11.104C5.89954 11.4984 5.90237 12.1306 6.29289 12.5223C6.68614 12.9167 7.31652 12.914 7.70697 12.5224Z"
                fill="black"
            />
            <mask
                id="mask0_472_13768"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x={5}
                y={5}
                width={6}
                height={8}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.70697 12.5224L10.5355 9.68548C10.9288 9.29107 10.929 8.6617 10.5421 8.27364L7.70059 5.42379C7.31889 5.04096 6.68342 5.03865 6.29289 5.43033C5.89965 5.82473 5.89945 6.45411 6.28637 6.84217L8.41421 8.97629L6.29276 11.104C5.89954 11.4984 5.90237 12.1306 6.29289 12.5223C6.68614 12.9167 7.31652 12.914 7.70697 12.5224Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_472_13768)">
                <rect
                    y={17}
                    width="16.0472"
                    height={16}
                    transform="rotate(-90 0 17)"
                    fill="#8190A6"
                />
            </g>
        </svg>
    )
}

export default ArrowIcon
