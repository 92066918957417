import { memo } from 'react'

const CreditCardIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <circle cx="20" cy="20" r="20" fill="#FF786E" opacity="0.12"></circle>
            <rect
                width="22"
                height="16"
                x="9"
                y="12"
                stroke="#FF786E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                rx="2"
            ></rect>
            <path
                stroke="#FF786E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 18h22"
            ></path>
        </svg>
    )
}

export default memo(CreditCardIcon)
