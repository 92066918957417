import { CSS, styled } from '@leanpay/stitches'
import * as Progress from '@radix-ui/react-progress'
import { ReactNode } from 'react'

interface ProgressBarProps {
    progress: number
    children?: ReactNode
    css?: CSS
}

const ProgressBar = ({ children, css, progress = 0 }: ProgressBarProps) => {
    return (
        <ProgressRoot max={100} css={{ ...css }}>
            <ProgressIndicator style={{ transform: `translateX(-${100 - progress}%)` }} />
            {children}
        </ProgressRoot>
    )
}

const ProgressRoot = styled(Progress.Root, {
    overflow: 'hidden',
    background: 'transparent',
    borderRadius: '$round',
    height: 4,
    //@ts-ignore
    // Fix overflow clipping in Safari
    // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
    transform: 'translateZ(0)',
})

const ProgressIndicator = styled(Progress.Indicator, {
    background: '$peachGradient',
    width: '100%',
    height: '100%',
    transition: 'transform 1200ms cubic-bezier(0.65, 0, 0.35, 1)',
})

export default ProgressBar
