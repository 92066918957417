import { memo } from 'react'

const VerificationStatusSuccess = () => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={20} cy={20} r={20} fill="#37B047" fillOpacity={0.2} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 29.333c-5.148 0-9.333-4.185-9.333-9.333 0-5.148 4.185-9.333 9.333-9.333 5.148 0 9.333 4.185 9.333 9.333 0 5.148-4.185 9.333-9.333 9.333Zm-1.285-4.96c.34 0 .68-.13.94-.39l5.661-5.662a1.337 1.337 0 0 0-.002-1.883 1.328 1.328 0 0 0-1.883-.003l-4.717 4.717-1.886-1.886a1.33 1.33 0 0 0-1.885 0 1.33 1.33 0 0 0 0 1.886l2.828 2.829c.262.261.602.392.942.392h.002ZM20 8c6.627 0 12 5.373 12 12s-5.373 12-12 12S8 26.627 8 20 13.373 8 20 8Z"
            fill="#37B047"
        />
    </svg>
)

export default memo(VerificationStatusSuccess)
