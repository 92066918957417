import { memo } from 'react'

const CloseCircleIcon = () => (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.3001 13.1001C24.6867 12.7135 24.6867 12.0867 24.3001 11.7001C23.9135 11.3135 23.2867 11.3135 22.9001 11.7001L18.0001 16.6001L13.1001 11.7001C12.7135 11.3135 12.0867 11.3135 11.7001 11.7001C11.3135 12.0867 11.3135 12.7135 11.7001 13.1001L16.6001 18.0001L11.7001 22.9001C11.3135 23.2867 11.3135 23.9135 11.7001 24.3001C12.0867 24.6867 12.7135 24.6867 13.1001 24.3001L18.0001 19.4001L22.9001 24.3001C23.2867 24.6867 23.9135 24.6867 24.3001 24.3001C24.6867 23.9135 24.6867 23.2867 24.3001 22.9001L19.4001 18.0001L24.3001 13.1001Z"
            fill="#8190A6"
        />
        <mask
            id="mask0_472_14109"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x={11}
            y={11}
            width={14}
            height={14}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.3001 13.1001C24.6867 12.7135 24.6867 12.0867 24.3001 11.7001C23.9135 11.3135 23.2867 11.3135 22.9001 11.7001L18.0001 16.6001L13.1001 11.7001C12.7135 11.3135 12.0867 11.3135 11.7001 11.7001C11.3135 12.0867 11.3135 12.7135 11.7001 13.1001L16.6001 18.0001L11.7001 22.9001C11.3135 23.2867 11.3135 23.9135 11.7001 24.3001C12.0867 24.6867 12.7135 24.6867 13.1001 24.3001L18.0001 19.4001L22.9001 24.3001C23.2867 24.6867 23.9135 24.6867 24.3001 24.3001C24.6867 23.9135 24.6867 23.2867 24.3001 22.9001L19.4001 18.0001L24.3001 13.1001Z"
                fill="white"
            />
        </mask>

        <circle cx={18} cy={18} r="17.3571" stroke="#DFE3E9" strokeWidth="1.28571" />
    </svg>
)

export default memo(CloseCircleIcon)
