import {
    AccountVerificationStatus,
    CheckoutFlowType,
    MembershipStatus,
    SessionInfoDTO,
    TwoFAStatus,
} from './dto-client'

export class SessionInfo {
    readonly firstName: string
    readonly lastName: string
    readonly membershipStatus: MembershipStatus
    readonly accountVerificationStatus: AccountVerificationStatus
    readonly creditRating: string
    readonly emailVerified: boolean
    readonly annexActive: boolean
    readonly pendingApproval: boolean
    readonly hasPreApprovedTransaction: boolean
    readonly customOfferUrlEnabled: boolean
    readonly remoteOfferEnabled: boolean
    readonly loginActions: string[]
    readonly twoFAStatus: TwoFAStatus
    readonly userIdentityNumber: string
    readonly iphoneForLifeProgram: boolean
    readonly cardPaymentEnabled: boolean
    readonly checkoutFlowType: CheckoutFlowType
    readonly email: string

    constructor(data: SessionInfoDTO) {
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.membershipStatus = data.membershipStatus
        this.accountVerificationStatus = data.accountVerificationStatus
        this.creditRating = data.creditRating
        this.emailVerified = data.emailVerified
        this.annexActive = data.annexActive
        this.pendingApproval = data.pendingApproval
        this.hasPreApprovedTransaction = data.hasPreApprovedTransaction
        this.customOfferUrlEnabled = data.customOfferUrlEnabled
        this.remoteOfferEnabled = data.remoteOfferEnabled
        this.loginActions = data.loginActions
        this.twoFAStatus = data.twoFAStatus
        this.iphoneForLifeProgram = data.iphoneForLifeProgram
        this.cardPaymentEnabled = data.cardPaymentEnabled
        this.checkoutFlowType = data.checkoutFlowType
        this.userIdentityNumber = data.userIdentityNumber
        this.email = data.email
    }

    get isVerified(): boolean {
        return this.accountVerificationStatus === AccountVerificationStatus.VERIFIED
    }

    get shouldShowKYC(): boolean {
        return (
            this.accountVerificationStatus !== AccountVerificationStatus.VERIFIED &&
            this.accountVerificationStatus !== AccountVerificationStatus.PENDING
        )
    }

    get isFirstTimeShopper(): boolean {
        return (
            this.membershipStatus === MembershipStatus.NOT_SUBMITTED ||
            this.membershipStatus === MembershipStatus.RESTARTED
        )
    }

    get isExistingMembership(): boolean {
        return (
            this.membershipStatus !== MembershipStatus.NOT_SUBMITTED &&
            this.membershipStatus !== MembershipStatus.RESTARTED
        )
    }
}
