import {
    ApplicationApprovalCheckType,
    CardPaymentFailureReason,
    CardPaymentStatus,
    CardPaymentStatusResponse,
    CardPaymentType,
    VendorRequestType,
} from './dto-client'

export class CardPaymentStatusData {
    readonly token: string
    readonly status: CardPaymentStatus
    readonly failureReason: CardPaymentFailureReason
    readonly vendorSuccessUrl: string
    readonly vendorErrorUrl: string
    readonly vendorRequestType: VendorRequestType
    readonly inStore: boolean
    readonly verified: boolean
    readonly vendorPackage: string
    readonly applicationApprovalCheckType: ApplicationApprovalCheckType
    readonly applicationApprovalId: number
    readonly paymentType: CardPaymentType
    readonly iphoneForLifeProgram: boolean

    constructor(data: CardPaymentStatusResponse) {
        this.token = data?.token
        this.status = data?.status
        this.failureReason = data?.failureReason
        this.vendorSuccessUrl = data?.vendorSuccessUrl
        this.vendorErrorUrl = data?.vendorErrorUrl
        this.vendorRequestType = data?.vendorRequestType
        this.inStore = data?.inStore
        this.verified = data?.verified
        this.vendorPackage = data?.vendorPackage
        this.applicationApprovalCheckType = data?.applicationApprovalCheckType
        this.applicationApprovalId = data?.applicationApprovalId
        this.paymentType = data?.paymentType
        this.iphoneForLifeProgram = data?.iphoneForLifeProgram
    }

    get shouldProcessPaymentStatus(): boolean {
        return (
            this.status !== CardPaymentStatus.INITIALIZED &&
            this.status !== CardPaymentStatus.CREATED &&
            this.status !== CardPaymentStatus.PUBLISHED &&
            this.status !== CardPaymentStatus.IN_PROCESSING
        )
    }

    get pendingApproval(): boolean {
        if (this.status === CardPaymentStatus.SUCCESS) {
            return false
        }
        return (
            this.status === CardPaymentStatus.PENDING_APPROVAL &&
            this.applicationApprovalCheckType !== ApplicationApprovalCheckType.NO_APPROVAL
        )
    }
}
