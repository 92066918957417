import { QuestionDTO, QuestionnaireDTO, RenderingType } from './dto-client'

export class Questionnaire {
    readonly id: number
    readonly title: string
    readonly description: string
    readonly questions: QuestionDTO[]
    readonly additionalQuestions?: QuestionDTO[]
    readonly conditionalShowing?: boolean
    constructor(data: QuestionnaireDTO) {
        this.id = data?.id
        this.title = data?.title
        this.description = data?.description
        this.additionalQuestions = data?.additionalQuestions
        // Sort questions by order number.
        this.questions = data?.questions
            .sort((a, b) => {
                return a.orderNumber > b.orderNumber ? 1 : -1
            })
            .map((question) => {
                //HUN FIX for checkbox
                if (question.groupName === 'PEP_TYPE') {
                    return {
                        ...question,
                        renderingType: RenderingType.SINGLE_CHOICE,
                    }
                }

                return {
                    ...question,
                }
            })
        this.conditionalShowing = data?.conditionalShowing
    }
}
