import { storage } from '@leanpay/local-storage'
import { SessionInfo, SessionInfoResponse } from '@leanpay/models'
import { STORAGE_KEY } from '@leanpay/utils'

export const logoutUser = () => {
    removeAuthToken()
    removeAuthSession()
}

export const setAuthToken = (token: string) => storage.save(STORAGE_KEY.AUTH_TOKEN, token)
export const getAuthToken = () => storage.load(STORAGE_KEY.AUTH_TOKEN)
export const removeAuthToken = () => storage.remove(STORAGE_KEY.AUTH_TOKEN)

export const getPreQSessionInfo = (): SessionInfo | null => storage.load(STORAGE_KEY.SESSION_INFO)
export const getVerificationSessionInfo = (): SessionInfoResponse | null =>
    storage.load(STORAGE_KEY.SESSION_INFO)

export const removeAuthSession = () => storage.remove(STORAGE_KEY.SESSION_INFO)
