import { memo } from 'react'

const IstoreLogoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="23" viewBox="0 0 80 23">
            <path
                fill="#91A3AE"
                d="M.232 22.53h4.074V6.629H.232v15.903zm4.34-20.342c0 1.175-.895 2.155-2.32 2.155C.895 4.343 0 3.363 0 2.188 0 .947.928 0 2.286 0 3.677 0 4.538.947 4.57 2.188zm4.183 15.975c1.28.745 3.28 1.392 5.347 1.392 2.559 0 4.002-1.197 4.002-2.978 0-1.65-1.115-2.622-3.904-3.626-3.674-1.295-6.002-3.204-6.002-6.344 0-3.594 3.017-6.28 7.84-6.28 2.394 0 4.132.517 5.28 1.1l-.95 3.205c-.82-.42-2.363-1.036-4.43-1.036-2.558 0-3.64 1.327-3.64 2.622 0 1.683 1.28 2.46 4.231 3.593 3.838 1.425 5.675 3.335 5.675 6.475 0 3.528-2.69 6.571-8.364 6.571-2.362 0-4.79-.647-6.003-1.392l.918-3.302m21.347-15.55v4.061h3.857v2.99h-3.857v6.987c0 1.95.527 2.924 2.077 2.924.725 0 1.12-.032 1.582-.162l.066 3.022c-.593.227-1.681.422-2.934.422-1.517 0-2.737-.487-3.495-1.3-.89-.91-1.286-2.372-1.286-4.452V9.664h-2.275v-2.99h2.275V3.717l3.99-1.105m9.603 11.935c0 3.075 1.522 5.398 3.92 5.398 2.266 0 3.853-2.224 3.853-5.464 0-2.486-1.1-5.365-3.821-5.365-2.818 0-3.952 2.78-3.952 5.43zm11.887-.164c0 5.857-4.081 8.474-8.097 8.474-4.438 0-7.903-3.075-7.903-8.212 0-5.235 3.4-8.44 8.162-8.44 4.696 0 7.838 3.336 7.838 8.178zm3.397-2.671c0-2.164-.033-3.738-.132-5.147h3.499l.165 3.016h.099c.792-2.23 2.673-3.377 4.423-3.377.396 0 .627.033.957.098v3.77a6.56 6.56 0 00-1.221-.098c-1.915 0-3.268 1.213-3.631 3.05-.066.36-.099.786-.099 1.212v8.295h-4.06V11.71m21.206 1.148c.033-1.475-.623-3.901-3.312-3.901-2.493 0-3.543 2.262-3.707 3.9h7.02zm-7.019 2.819c.099 2.885 2.33 4.13 4.92 4.13 1.87 0 3.182-.295 4.428-.72l.59 2.753c-1.377.59-3.28 1.016-5.575 1.016-5.182 0-8.233-3.18-8.233-8.064 0-4.426 2.69-8.589 7.806-8.589 5.183 0 6.888 4.261 6.888 7.77 0 .753-.065 1.343-.131 1.704H69.176z"
            ></path>
        </svg>
    )
}

export default memo(IstoreLogoIcon)
