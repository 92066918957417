import { memo } from 'react'

const BurgerMenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={14} fill="none">
        <path
            stroke="#8190A6"
            strokeLinecap="round"
            strokeWidth={2}
            d="M1 7h16M1 1.5h16M1 12.5h16"
        />
    </svg>
)
export default memo(BurgerMenuIcon)
