import { Language } from '@leanpay/utils'
import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import { defaultAppLanguage } from 'library/constants'
import enHU from 'library/i18n/en-HU.json'
import enRO from 'library/i18n/en-RO.json'
import enSL from 'library/i18n/en-SL.json'
import hu from 'library/i18n/hu.json'
import ro from 'library/i18n/ro.json'
import sl from 'library/i18n/sl.json'

const getEn = () => {
    if (defaultAppLanguage === Language.ROM) return enRO
    if (defaultAppLanguage === Language.SLO) return enSL
    if (defaultAppLanguage === Language.HUN) return enHU

    return enSL
}

// TODO: FIXME: Not all languages should be loaded every time, it should be dependant on the market
export const resources = {
    en: getEn(),
    ro: ro,
    sl: sl,
    hu: hu,
} as const

export const supportedLngs: InitOptions['supportedLngs'] = Object.keys(resources)
export const defaultLanguage = defaultAppLanguage

export const defaultNS = 'lp'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
    defaultNS: defaultNS,
    ns: defaultNS,
    lng: defaultLanguage,
    fallbackLng: Language.ENG,
    resources: resources,
    supportedLngs,
})

export default i18n
