import { memo } from 'react'

const SpeedyClockIcon = () => (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16.5" r="16" fill="#FF786E" fillOpacity="0.12" />
        <g clipPath="url(#clip0_111_3744)">
            <path
                d="M16.9824 9.43341C14.9867 9.43341 13.1095 10.2054 11.6868 11.5949C11.4299 11.8458 11.4299 12.251 11.6868 12.5019L12.7933 13.5826C12.9317 13.7178 13.0897 13.7756 13.2676 13.7756C13.4454 13.7756 13.6035 13.7178 13.7419 13.5826C13.9988 13.3317 13.9988 12.9265 13.7419 12.6756L13.1294 12.0774C14.0581 11.344 15.1646 10.9002 16.3502 10.7844V11.6335C16.3502 11.9808 16.6466 12.2704 17.0022 12.2704C17.3578 12.2704 17.6544 11.981 17.6544 11.6335V10.7844C18.8597 10.9195 19.9662 11.3826 20.8752 12.0966L20.2426 12.6756C19.9858 12.9265 19.9858 13.3317 20.2426 13.5826C20.3811 13.7178 20.539 13.7756 20.7169 13.7756C20.8947 13.7756 21.0528 13.7178 21.1912 13.5826L21.8037 12.9844C22.5348 13.8528 23.009 14.9335 23.1473 16.13H22.2778C21.9222 16.13 21.6258 16.4195 21.6258 16.7669C21.6258 17.1142 21.9221 17.4037 22.2778 17.4037H23.1473C23.0089 18.5809 22.5348 19.6616 21.8037 20.5301L21.1912 19.9318C20.9343 19.6809 20.5194 19.6809 20.2625 19.9318C20.0056 20.1827 20.0056 20.5879 20.2625 20.8389L20.875 21.437C19.966 22.1703 18.8594 22.6333 17.6541 22.7685V21.9194C17.6541 21.5721 17.3576 21.2825 17.002 21.2825C16.6464 21.2825 16.3498 21.5719 16.3498 21.9194V22.7685C15.1642 22.6527 14.0577 22.1895 13.129 21.4755L13.7415 20.8773C13.9985 20.6264 13.9985 20.2211 13.7415 19.9703C13.4846 19.7194 13.0698 19.7194 12.8128 19.9703L11.7063 21.0509C11.6471 21.1088 11.5878 21.1861 11.568 21.2633C11.4691 21.4947 11.5284 21.7649 11.7063 21.958C13.1095 23.3281 14.9866 24.1001 16.9822 24.1001C21.1119 24.1001 24.4909 20.8194 24.4909 16.7667C24.4909 12.7141 21.1319 9.43341 16.9824 9.43341Z"
                fill="#FF6A71"
            />
            <path
                d="M16.9831 13.1193C16.6275 13.1193 16.3311 13.4087 16.3311 13.7561V16.7666C16.3311 17.1139 16.6274 17.4035 16.9831 17.4035H19.6111C19.9667 17.4035 20.2632 17.114 20.2632 16.7666C20.2632 16.4193 19.9668 16.1298 19.6111 16.1298H17.6549V13.7561C17.6549 13.4087 17.3586 13.1193 16.9831 13.1193Z"
                fill="#FF6A71"
            />
            <path
                d="M10.7584 16.1298H6.37179C6.01619 16.1298 5.71973 16.4192 5.71973 16.7667C5.71973 17.114 6.01606 17.4035 6.37179 17.4035H10.7584C11.114 17.4035 11.4105 17.1141 11.4105 16.7667C11.4105 16.4194 11.114 16.1298 10.7584 16.1298Z"
                fill="#FF6A71"
            />
            <path
                d="M10.7597 13.6597H7.87472C7.51913 13.6597 7.22266 13.9491 7.22266 14.2966C7.22266 14.6439 7.519 14.9334 7.87472 14.9334H10.7597C11.1153 14.9334 11.4118 14.644 11.4118 14.2966C11.4118 13.9491 11.1153 13.6597 10.7597 13.6597Z"
                fill="#FF6A71"
            />
            <path
                d="M10.7597 18.5807H7.87472C7.51913 18.5807 7.22266 18.8702 7.22266 19.2176C7.22266 19.5649 7.519 19.8544 7.87472 19.8544H10.7597C11.1153 19.8544 11.4118 19.565 11.4118 19.2176C11.4118 18.8702 11.1153 18.5807 10.7597 18.5807Z"
                fill="#FF6A71"
            />
        </g>
        <defs>
            <clipPath id="clip0_111_3744">
                <rect
                    width="19.2"
                    height="14.9333"
                    fill="white"
                    transform="translate(5.59961 9.29999)"
                />
            </clipPath>
        </defs>
    </svg>
)
export default memo(SpeedyClockIcon)
