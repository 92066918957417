import { memo } from 'react'

const ShopCartIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle opacity={0.12} cx={20} cy={20} r={20} fill="#FF786E" />
        <path
            d="M18.883 28.697a2.51 2.51 0 0 0-.732-1.774 2.51 2.51 0 0 0-3.547 0 2.51 2.51 0 0 0 0 3.547 2.51 2.51 0 0 0 3.547-.005c.465-.465.732-1.1.732-1.768Zm-3.468 0a.959.959 0 0 1 1.916 0 .959.959 0 0 1-1.635.677 1 1 0 0 1-.281-.677ZM28 28.697a2.51 2.51 0 0 0-.731-1.774 2.51 2.51 0 0 0-3.546 0 2.51 2.51 0 0 0 0 3.547 2.51 2.51 0 0 0 3.546-.005c.465-.465.732-1.1.732-1.768Zm-3.476 0a.959.959 0 0 1 1.916 0 .959.959 0 0 1-1.916 0Z"
            fill="#FF786E"
        />
        <path
            d="m13.103 9.76-2.952-.517a.775.775 0 0 0-.258 1.525l2.4.424 2.22 13.585c.027.185.124.35.267.47.142.12.322.184.506.18h11.937a.774.774 0 0 0 0-1.547H15.94l-.327-1.986h11.55a.77.77 0 0 0 .737-.534l2.064-6.447a.782.782 0 0 0-.111-.696.78.78 0 0 0-.626-.318H14.31l-.571-3.509a.756.756 0 0 0-.217-.414.769.769 0 0 0-.419-.217Zm15.064 5.691-1.58 4.896H15.36l-.801-4.9 13.608.005Z"
            fill="#FF786E"
            stroke="#FF786E"
            strokeWidth={0.3}
        />
    </svg>
)

export default memo(ShopCartIcon)
