import { keyframes, styled } from '@leanpay/stitches'

const rotation = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
})

const LoadingSpinner = styled('span', {
    border: '3px solid $grey1',
    borderBottomColor: 'transparent',
    borderRightColor: 'transparent',
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    animation: `${rotation} 1s linear infinite`,

    variants: {
        variant: {
            primary: {
                border: '4px solid $peach2',
                borderBottomColor: 'transparent',
                borderRightColor: 'transparent',
            },
            secondary: {
                border: '4px solid $grey1',
                borderBottomColor: 'transparent',
                borderRightColor: 'transparent',
            },
        },
        size: {
            xl: {
                width: 40,
                height: 40,
            },

            l: {
                width: 24,
                height: 24,
            },
            m: {
                width: 18,
                height: 18,
            },
            s: {
                width: 5,
                height: 5,
            },
        },
    },
    defaultVariants: {
        size: 'xl',
        variant: 'primary',
    },
})

export default LoadingSpinner
