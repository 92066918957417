import { memo } from 'react'

const VisaIcon = () => {
    return (
        <svg
            width={44}
            height={14}
            viewBox="0 0 44 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.86095 0.5H0.043238L0 0.758589C1.4916 1.0975 2.81691 1.59486 3.96789 2.21636L7.13859 13.5L10.8913 13.4948L16.4752 0.601282H12.719L9.24666 9.41107L8.87521 7.62135C8.78378 7.32872 8.66484 7.03259 8.5216 6.73666C8.60637 6.90808 8.68515 7.08141 8.7579 7.25647L7.49517 1.54762C7.27722 0.761076 6.64365 0.52818 5.86095 0.5ZM32.1143 1.12747C31.4127 0.873966 30.3112 0.601282 28.9374 0.601282C25.4362 0.601282 22.9688 2.29576 22.9471 4.72574C22.9281 6.52028 24.7077 7.52346 26.0529 8.12136C27.4318 8.73344 27.8967 9.12371 27.8915 9.67074C27.8811 10.5088 26.7883 10.8899 25.7684 10.8899C24.3513 10.8899 23.5985 10.7006 22.4337 10.2345L21.9775 10.0368L21.4797 12.8345C22.3062 13.1831 23.8378 13.485 25.4276 13.5C29.1534 13.5 31.5714 11.8264 31.5991 9.23045C31.6121 7.81032 30.6694 6.72876 28.6244 5.83565C27.385 5.25776 26.6253 4.8725 26.6331 4.28711C26.6331 3.76842 27.2766 3.21221 28.6634 3.21221C29.8212 3.1947 30.6625 3.4382 31.3147 3.69087L31.6338 3.83513L32.1143 1.12747ZM41.1789 0.601282H38.4882C37.6536 0.601282 37.0295 0.832108 36.6629 1.67187L31.4887 13.5H35.1488C35.1488 13.5 35.7447 11.9101 35.882 11.5604C36.0694 11.5604 36.9531 11.5617 37.8902 11.5631C38.9477 11.5647 40.0731 11.5664 40.3425 11.5664C40.4465 12.0177 40.7663 13.5 40.7663 13.5H44L41.1789 0.601282ZM38.2708 5.31255C38.2708 5.31255 37.1727 8.17721 36.8837 8.92137H39.7756C39.6358 8.27454 38.9682 5.19369 38.9682 5.19369L38.7321 4.0809C38.6348 4.35028 38.4969 4.71221 38.397 4.97417C38.3175 5.18294 38.2621 5.32821 38.2708 5.31255ZM18.0137 0.601282L15.8496 13.5H19.3097L21.4797 0.601282H18.0137Z"
                fill="#16216A"
            />
        </svg>
    )
}

export default memo(VisaIcon)
