import { Gender, IDDocumentType } from './dto-client'

export class PersonalDetailsV2 {
    token: string
    firstName: string
    lastName: string
    email: string
    marketingConsent: boolean
    birthFirstName?: string
    birthLastName?: string
    motherMaidenFirstName?: string
    motherMaidenLastName?: string
    placeOfBirth?: string
    houseNumber?: string
    gender?: Gender
    domesticCitizenshipAccepted?: boolean
    birthDate?: string
    countryOfBirthCodebookId?: number
    identityCardType?: IDDocumentType
    identityCardNumber?: string
    taxNumber?: string
    responsibilityAccepted: boolean
    nationalityCodebookId?: number
    creditBureauConsentAccepted?: boolean
    streetCodebookId?: number
    postZipCodebookId?: number
    settlementCodebookId?: number

    constructor(data: any) {
        this.token = data?.token
        this.firstName = data?.firstName
        this.lastName = data?.lastName
        this.email = data?.email
        this.marketingConsent = data?.marketingConsent
        this.birthFirstName = data?.birthFirstName
        this.birthLastName = data?.birthLastName
        this.motherMaidenFirstName = data?.motherMaidenFirstName
        this.motherMaidenLastName = data?.motherMaidenLastName
        this.placeOfBirth = data?.placeOfBirth
        this.houseNumber = data?.houseNumber
        this.gender = data?.gender
        this.domesticCitizenshipAccepted = data?.domesticCitizenshipAccepted
        this.birthDate = data?.birthDate
        this.countryOfBirthCodebookId = data?.countryOfBirthCodebookId
        this.identityCardType = data?.identityCardType
        this.identityCardNumber = data?.identityCardNumber
        this.taxNumber = data?.taxNumber
        this.responsibilityAccepted = data?.responsibilityAccepted
        this.nationalityCodebookId = data?.nationalityCodebookId
        this.creditBureauConsentAccepted = data?.creditBureauConsentAccepted
        this.streetCodebookId = data?.streetCodebookId
        this.postZipCodebookId = data?.postZipCodebookId
        this.settlementCodebookId = data?.settlementCodebookId
    }
}
