import { memo } from 'react'

const EmailIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={12} fill="none">
            <path
                fill="#8190A6"
                fillRule="evenodd"
                d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1Zm12.438 1H3.562L8 5.698 12.438 2ZM2 3.302V10h12V3.302L8.64 7.768a1 1 0 0 1-1.28 0L2 3.302Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(EmailIcon)
