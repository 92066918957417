import { CancelTransactionRequest } from '@leanpay/models'
import { logoutUser } from '@leanpay/services'
import { CSS } from '@leanpay/stitches'
import {
    BodyText,
    Box,
    Button,
    Dialog,
    DialogCloseTrigger,
    DialogContent,
    DialogTrigger,
    Flex,
    Icon,
    Image,
    ProgressBar,
    ThinkingFaceImg,
} from '@leanpay/ui'
import { IconName } from '@leanpay/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppStore } from 'app/app-context'

import { useCancelTransaction } from 'library/hooks/query-hooks'
import { shouldShowProgressBar } from 'library/utils'

interface CheckoutHeaderProps {
    css?: CSS
    progress: number
    onCancelPress?: () => void
    showCancelIcon?: boolean
    children: any
}

const CheckoutHeader = ({
    css,
    progress,
    onCancelPress,
    showCancelIcon = false,
    children,
}: CheckoutHeaderProps) => {
    const { t } = useTranslation()
    const { vendorToken, paymentData, setIsAuthenticated } = useAppStore()
    const navigate = useNavigate()
    const showProgressbar = shouldShowProgressBar()
    const [isOpen, setIsOpen] = useState(false)

    const { mutateAsync: mutateCancelTransaction, isLoading } = useCancelTransaction()

    const handleExitCheckoutProcess = async () => {
        const request: CancelTransactionRequest = {
            surrogateId: null,
            token: vendorToken,
            maturityPriceId: null,
            maturityGraceId: null,
            firstPaymentDate: null,
        }

        try {
            await mutateCancelTransaction(request)
            logoutUser()
            if (paymentData?.errorUrl) {
                window.location.href = paymentData?.errorUrl
                return
            }
            setIsAuthenticated(false)
            navigate('/', { replace: true })
        } catch (error) {
            navigate('/', { replace: true })
        }
    }

    return (
        <>
            {showProgressbar && (
                <Flex
                    css={{
                        'flexDirection': 'column',
                        'maxWidth': 840,
                        'width': '100%',
                        'position': 'fixed',
                        'alignItems': 'center',
                        'px': '$1',
                        'pt': '$0-5',
                        'zIndex': 10,
                        'borderRadius': '$1',
                        'background':
                            'linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(243,246,248,1) 58%)',
                        ...css,
                        '@md': {
                            borderRadius: '0px',
                            width: '100%',
                        },
                        '@sm': {
                            width: '100%',
                            borderRadius: '0px',
                        },
                    }}
                >
                    <Dialog onOpenChange={setIsOpen}>
                        <ProgressBar progress={progress} css={{ width: '100%' }} />
                        <DialogTrigger asChild>
                            <Box
                                css={{
                                    pt: '$1',
                                    cursor: 'pointer',
                                    alignSelf: 'flex-end',
                                    display: showCancelIcon && !isOpen ? 'block' : 'none',
                                }}
                            >
                                <Icon iconName={IconName.CloseCircleIcon} />
                            </Box>
                        </DialogTrigger>

                        <DialogContent>
                            <Box
                                css={{
                                    padding: '$2',
                                    pt: '$6',
                                    alignSelf: 'stretch',
                                    textAlign: 'center',
                                }}
                            >
                                <Image src={ThinkingFaceImg} />

                                <BodyText
                                    css={{ fontSize: '$2', mb: '$5', mt: '$4' }}
                                    dangerouslySetInnerHTML={{
                                        __html: t('common.cancelTransaction'),
                                    }}
                                />

                                <DialogCloseTrigger asChild>
                                    <Button
                                        testId="next"
                                        onClick={handleExitCheckoutProcess}
                                        css={{ mb: '$2' }}
                                    >
                                        {t('common.leave')}
                                    </Button>
                                </DialogCloseTrigger>

                                <DialogCloseTrigger asChild>
                                    <Button testId="previous" variant={'secondary'}>
                                        {t('common.continue')}
                                    </Button>
                                </DialogCloseTrigger>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </Flex>
            )}
            {children}
        </>
    )
}

export default CheckoutHeader
