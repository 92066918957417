import { memo } from 'react'

const UserProfileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <path
                fill="#000"
                fillRule="evenodd"
                d="M8.004 8.912c1.624 0 2.942-3.202 2.942-4.878C10.946 2.359 9.628 1 8.004 1 6.378 1 5.059 2.36 5.059 4.034c0 1.676 1.319 4.878 2.945 4.878Zm-.005 2.589s2.845-.296 3.702-1.457c4.617 0 4.294 5.957 4.294 5.956H0s.123-5.931 4.232-5.931c.857 1.147 3.767 1.432 3.767 1.432Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(UserProfileIcon)
