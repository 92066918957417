import { HttpClient } from '@leanpay/http-client'
import {
    ConnectionStatusRequest,
    Psd2ConnectionStatusRequest,
    Psd2ConnectionStatusResponse,
    Psd2TokenRequest,
    Psd2TokenRequestResponse,
} from '@leanpay/models'

import { preQualifiedErrorHandler } from '../errorHandler'
import { clientInterceptor } from '../interceptors'

class Psd2Service {
    _httpClient = new HttpClient({
        config: {
            baseURL: undefined,
            responseType: 'json',
        },
        interceptorConfig: {
            onRequestFulfilled: clientInterceptor,
            onRejected: preQualifiedErrorHandler,
        },
    })

    private _tokenRequestUrl = 'api/psd2/v1/token-request'
    private _connectionStatusUrl = 'api/psd2/v1/connection-status'
    private _sendConnectionStatusUrl = 'api/v1/connection-status'

    async requestToken(request: Psd2TokenRequest) {
        const { data } = await this._httpClient.post<Psd2TokenRequestResponse>(
            this._tokenRequestUrl,
            request,
        )

        return data
    }

    async getConnectionStatus(request: Psd2ConnectionStatusRequest) {
        const maxRetries = 12
        const delay = 5000

        for (let i = 0; i <= maxRetries; i++) {
            const { data } = await this._httpClient.post<Psd2ConnectionStatusResponse>(
                this._connectionStatusUrl,
                request,
            )

            if (data.accessGranted) {
                return data
            }

            if (i === maxRetries) {
                return data
            }

            await new Promise((resolve) => setTimeout(resolve, delay))
        }
    }

    async sendConnectionStatus(request: ConnectionStatusRequest) {
        const { data } = await this._httpClient.post<void>(this._sendConnectionStatusUrl, request)
        return data
    }
}

export const psd2Service = new Psd2Service()
