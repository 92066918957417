import { memo } from 'react'

const InvoiceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none">
            <path
                fill="#fff"
                d="M6.688 13.524a.196.196 0 0 0-.297.017c-.446.449-1.008.681-1.569.681-.826 0-1.618-.515-2.064-1.346h2.147a.22.22 0 0 0 .214-.216v-.73a.22.22 0 0 0-.214-.217h-2.51c-.017-.15-.017-.282-.017-.415s.016-.282.033-.432h2.51a.22.22 0 0 0 .215-.216v-.764a.22.22 0 0 0-.215-.216H2.774c.446-.831 1.222-1.346 2.065-1.346.578 0 1.122.25 1.568.698a.226.226 0 0 0 .298.016l.809-.764c.05-.033.066-.1.082-.166a.322.322 0 0 0-.05-.166c-.742-.764-1.684-1.18-2.691-1.18-1.651 0-3.138 1.163-3.732 2.891H.215A.22.22 0 0 0 0 9.87v.731c0 .117.1.216.215.216h.66c-.016.15-.016.3-.016.432 0 .133.016.283.016.416h-.66A.22.22 0 0 0 0 11.88v.73c0 .117.1.217.215.217h.908c.594 1.728 2.08 2.89 3.732 2.89.99 0 1.949-.415 2.692-1.18a.255.255 0 0 0 .066-.165.265.265 0 0 0-.083-.166l-.842-.682Z"
            />
            <path
                fill="#fff"
                d="M15.756 2.991 12.582.598A2.056 2.056 0 0 0 11.128 0h-6.11c-1.14 0-2.064.93-2.064 2.06v3.622h1.503V2.06c0-.315.248-.548.561-.548h5.978c.033 0 .05.033.05.05v2.342c0 .565.462 1.014 1.024 1.014h2.328c.033 0 .066.033.066.066v8.94a.559.559 0 0 1-.561.564H8.575l-.033.05c-.313.482-.726.864-1.222 1.096l-.082.05.016.1c.017.033.017.05.017.066 0 .017 0 .017.016.05l.017.1h6.632c1.14 0 2.064-.93 2.064-2.077V4.885c0-.565.143-1.43-.244-1.894Z"
            />
            <path
                fill="#fff"
                d="M8.769 13.259h3.959c.314 0 .553-.333.553-.781 0-.416-.25-.748-.553-.748h-4.09l.033.166c.087.415.12.83.098 1.23v.133ZM12.739 8.972H8.726l.032.166c.033.166.054.3.054.399a1.75 1.75 0 0 1-.119.78l-.076.2h4.122c.303 0 .553-.332.553-.78-.011-.433-.25-.765-.553-.765ZM8.292 7.045l.043.033c.141.133.293.332.445.598l.022.033h3.98c.271 0 .488-.332.488-.764 0-.415-.217-.748-.488-.748h-3.98c-.271 0-.51.35-.51.748v.1ZM9.296 4.968a.742.742 0 0 0 .743-.748.757.757 0 0 0-.743-.764h-2.84c-.414 0-.76.332-.744.748-.016.182.066.382.198.531.149.15.347.233.545.233h2.84Z"
            />
        </svg>
    )
}

export default memo(InvoiceIcon)
