import { memo } from 'react'

const EplLogoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g fill="#91A3AE">
                <path d="M8.58 21.06c.36.36.9.54 1.56.54.48 0 .9-.12 1.26-.36s.6-.48.66-.78h2.16c-.36 1.08-.84 1.8-1.56 2.28-.72.48-1.56.66-2.58.66-.72 0-1.32-.12-1.86-.36s-1.02-.54-1.44-.96c-.36-.42-.66-.9-.9-1.5s-.3-1.2-.3-1.86c0-.66.12-1.26.3-1.86.24-.6.54-1.08.9-1.5.42-.42.9-.72 1.44-.96.54-.24 1.14-.36 1.86-.36.78 0 1.44.12 1.98.42s1.02.66 1.38 1.2c.36.48.6 1.08.78 1.68.18.6.24 1.26.18 1.98H7.98c.06.78.24 1.38.6 1.74m2.76-4.68c-.3-.3-.72-.48-1.32-.48-.36 0-.72.06-.96.18s-.48.3-.6.48c-.18.18-.24.42-.3.6-.06.24-.12.42-.12.6H12c-.18-.6-.36-1.08-.66-1.38m6.78-2.1v1.14h.06c.3-.48.66-.84 1.14-1.02.48-.24.96-.3 1.5-.3.72 0 1.32.12 1.8.42.48.24.9.6 1.26 1.02.36.42.6.96.72 1.5.18.6.24 1.2.24 1.8 0 .6-.06 1.14-.24 1.74-.18.54-.42 1.02-.72 1.44-.3.42-.72.78-1.2 1.02-.54.24-1.08.36-1.74.36-.54 0-1.08-.12-1.5-.36-.48-.24-.84-.54-1.14-.96h-.06v4.2h-2.46v-12h2.34zm3.18 7.02c.24-.18.48-.36.66-.6.18-.24.3-.54.36-.9s.12-.72.12-1.02c0-.36-.06-.72-.12-1.02-.06-.36-.18-.66-.36-.9s-.42-.48-.66-.66c-.24-.18-.6-.24-.96-.24-.42 0-.72.06-1.02.24-.24.18-.48.36-.66.66-.18.24-.3.54-.36.9s-.12.72-.12 1.08c0 .36.06.72.12 1.02.06.36.18.66.36.9s.36.48.66.6c.3.18.6.24 1.02.24.36-.06.66-.12.96-.3zm7.5-11.46v13.32h-2.46V10.38z"></path>
                <path d="M35.4.6v34.8H.6V.6h34.8zM36 0H0v36h36V0z"></path>
            </g>
        </svg>
    )
}

export default memo(EplLogoIcon)
