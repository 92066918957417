import { AddressDetailsResponse } from './dto-client'

export class AddressData {
    postZipCodebookId?: number
    settlementCodebookId?: number
    streetCodebookId?: number
    street?: string
    houseNumber?: string
    floor?: string
    apartment?: string
    entrance?: string
    blockNumber?: string

    constructor(data: AddressDetailsResponse) {
        this.postZipCodebookId = data?.postZipCodebookId
        this.settlementCodebookId = data?.settlementCodebookId
        this.streetCodebookId = data?.streetCodebookId
        this.street = data?.street
        this.houseNumber = data?.houseNumber
        this.floor = data?.floor
        this.apartment = data?.apartmentNumber
        this.entrance = data?.entrance
        this.blockNumber = data?.blockNumber
    }
}
