import {
    CardPaymentStatusData,
    CheckoutData,
    ContractData,
    FinancialDataResponse,
    FinancialDetailsV2,
    MaturityType,
    OfferValidation,
    PaymentData,
    PaymentPlan,
    PersonalDetails,
    SessionInfo,
} from '@leanpay/models'
import { createContext, useContext } from 'react'

export interface AppStateProps {
    vendorToken: string
    isAuthenticated: boolean
    sessionInfo: SessionInfo | null
    progress: number
    personalDetailsData: PersonalDetails | null
    financialDetailsDataV2: FinancialDetailsV2 | null
    offerValidationData: OfferValidation | null
    selectedPaymentPlan: PaymentPlan | null
    paymentData: PaymentData | null
    cardPaymentStatusData: CardPaymentStatusData | null
    checkoutData: CheckoutData | null
    contractData: ContractData[] | null
    financialData: FinancialDataResponse | null
    maturityType: MaturityType
    setVendorToken: (value: string) => void
    setIsAuthenticated: (value: boolean) => void
    setSessionInfo: (value: SessionInfo) => void
    setProgress: (value: number) => void
    setPersonalDetailsData: (value: PersonalDetails) => void
    setFinancialDetailsDataV2: (value: FinancialDetailsV2) => void
    setOfferValidationData: (value: OfferValidation) => void
    setSelectedPaymentPlan: (value: PaymentPlan) => void
    setPaymentData: (value: PaymentData | null) => void
    setCardPaymentStatusData: (value: CardPaymentStatusData) => void
    setCheckoutData: (value: CheckoutData) => void
    setContractData: (value: ContractData[]) => void
    setFinancialData: (value: FinancialDataResponse) => void
    setMaturityType: (value: MaturityType) => void
}

const AppContext = createContext<AppStateProps | null>({
    vendorToken: '',
    sessionInfo: null,
    isAuthenticated: false,
    progress: 0,
    personalDetailsData: null,
    offerValidationData: null,
    financialDetailsDataV2: null,
    selectedPaymentPlan: null,
    paymentData: null,
    cardPaymentStatusData: null,
    checkoutData: null,
    contractData: null,
    financialData: null,
    maturityType: MaturityType.PRICE,
    setVendorToken: () => {
        throw new Error('setVendorToken() not implemented')
    },
    setSessionInfo: () => {
        throw new Error('setSessionInfo() not implemented')
    },
    setIsAuthenticated: () => {
        throw new Error('setIsAuthenticated() not implemented')
    },

    setProgress: () => {
        throw new Error('setProgress() not implemented')
    },

    setPersonalDetailsData: () => {
        throw new Error('setPersonalDetailsData() not implemented')
    },
    setFinancialDetailsDataV2: () => {
        throw new Error('setFinancialDetailsDataV2() not implemented')
    },
    setOfferValidationData: () => {
        throw new Error('setOfferValidationData() not implemented')
    },

    setSelectedPaymentPlan: () => {
        throw new Error('setSelectedPaymentPlan() not implemented')
    },
    setPaymentData: () => {
        throw new Error('setPaymentData() not implemented')
    },

    setCardPaymentStatusData: () => {
        throw new Error('setCardPaymentStatusData() not implemented')
    },
    setCheckoutData: () => {
        throw new Error('setCheckoutData() not implemented')
    },
    setContractData: () => {
        throw new Error('setContractData() not implemented')
    },
    setFinancialData: () => {
        throw new Error('setFinancialData() not implemented')
    },
    setMaturityType: () => {
        throw new Error('setMaturityType() not implemented')
    },
})

export const useAppStore = () => {
    const ctx = useContext(AppContext)

    if (!ctx) {
        throw new Error('AppContext can be used only within AppProvider')
    }
    return ctx
}

export default AppContext
