import { memo } from 'react'

const MobilePhoneIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={11} height={17} fill="none">
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M1 2.136C1 1.236 1.703.5 2.562.5h6.25c.86 0 1.563.736 1.563 1.636v12.728c0 .895-.696 1.629-1.55 1.636H2.563C1.702 16.5 1 15.764 1 14.864V2.136Zm7.803 13.637h-6.24c-.487 0-.869-.4-.869-.91v-1.272h7.987v1.273c0 .507-.378.905-.861.909h-.017Zm.01-14.546h-6.25c-.487 0-.869.4-.869.91v.181h7.987v-.182c0-.51-.382-.909-.868-.909ZM1.694 3.045h7.987v9.819H1.694V3.045Zm3.473 11.637c0-.302.233-.546.52-.546.288 0 .521.245.521.546a.534.534 0 0 1-.52.545.534.534 0 0 1-.521-.545Z"
                clipRule="evenodd"
            />
            <path
                fill="#fff"
                d="m8.825 16.5.001.25h.001l-.002-.25Zm-.012 0v.25-.25Zm-7.119-2.909v-.25h-.25v.25h.25Zm7.987 0h.25v-.25h-.25v.25Zm-.861 2.182v.25h.002l-.002-.25Zm-.007 0v.25-.25ZM1.694 2.318h-.25v.25h.25v-.25Zm7.987 0v.25h.25v-.25h-.25Zm0 .727h.25v-.25h-.25v.25Zm-7.987 0v-.25h-.25v.25h.25Zm7.987 9.819v.25h.25v-.25h-.25Zm-7.987 0h-.25v.25h.25v-.25ZM2.562.25C1.554.25.75 1.11.75 2.136h.5c0-.773.602-1.386 1.312-1.386v-.5Zm6.25 0h-6.25v.5h6.25v-.5Zm1.813 1.886C10.625 1.11 9.821.25 8.813.25v.5c.71 0 1.312.613 1.312 1.386h.5Zm0 12.728V2.136h-.5v12.728h.5ZM8.827 16.75c1.002-.009 1.798-.864 1.798-1.886h-.5c0 .769-.596 1.38-1.302 1.386l.004.5Zm-.013 0h.012l-.002-.5h-.013l.003.5Zm-.011 0h.01v-.5h-.01v.5Zm-6.24 0h6.24v-.5h-6.24v.5ZM.75 14.864c0 1.027.804 1.886 1.812 1.886v-.5c-.71 0-1.312-.613-1.312-1.386h-.5Zm0-12.728v12.728h.5V2.136h-.5Zm1.812 13.887h6.24v-.5h-6.24v.5Zm-1.118-1.16c0 .638.483 1.16 1.118 1.16v-.5c-.338 0-.618-.276-.618-.66h-.5Zm0-1.272v1.273h.5V13.59h-.5Zm8.237-.25H1.694v.5h7.987v-.5Zm.25 1.523V13.59h-.5v1.273h.5Zm-1.11 1.159c.632-.005 1.11-.525 1.11-1.16h-.5c0 .382-.278.657-.613.66l.004.5Zm-.007 0h.007l-.002-.5h-.007l.002.5Zm-.011 0h.01v-.5h-.01v.5ZM2.563 1.477h6.25v-.5h-6.25v.5Zm-.619.66c0-.384.28-.66.618-.66v-.5c-.635 0-1.118.522-1.118 1.16h.5Zm0 .181v-.182h-.5v.182h.5Zm7.737-.25H1.694v.5h7.987v-.5Zm-.25.068v.182h.5v-.182h-.5Zm-.618-.659c.338 0 .618.276.618.66h.5c0-.638-.483-1.16-1.118-1.16v.5Zm.868 1.318H1.694v.5h7.987v-.5Zm.25 10.069V3.045h-.5v9.819h.5Zm-8.237.25h7.987v-.5H1.694v.5Zm-.25-10.069v9.819h.5V3.045h-.5Zm4.244 10.841c-.437 0-.771.367-.771.796h.5c0-.174.132-.296.27-.296v-.5Zm.77.796c0-.429-.334-.796-.77-.796v.5c.138 0 .27.122.27.296h.5Zm-.77.795c.436 0 .77-.367.77-.795h-.5c0 .174-.132.295-.27.295v.5Zm-.771-.795c0 .428.334.795.77.795v-.5c-.138 0-.27-.121-.27-.295h-.5Z"
            />
        </svg>
    )
}

export default memo(MobilePhoneIcon)
