import { PaymentPlanDTO, TestAB, ValidationDataDTO } from './dto-client'

export class OfferValidation {
    applicationId: number
    status: boolean
    validatedPaymentPlan: PaymentPlanDTO
    hasPossibleCashFlows: boolean
    paymentPlans: PaymentPlanDTO[]
    availableExposure: number
    recommendedPurchaseAmount: number
    testAB: TestAB
    failedReasonType?: 'NO_PAYMENT_OPTIONS' | 'FAIL_CREDIT_CHECK' | 'EMPTY'
    shadowLimit?: number

    constructor(data: ValidationDataDTO) {
        this.applicationId = data.applicationId
        this.status = data.status
        this.validatedPaymentPlan = data.validatedPaymentPlan
        this.hasPossibleCashFlows = data.hasPossibleCashFlows
        this.paymentPlans = data.paymentPlans.sort((a, b) => {
            return a.termMaturity.numberOfMonths > b.termMaturity.numberOfMonths ? -1 : 1
        })
        this.availableExposure = data.availableExposure
        this.recommendedPurchaseAmount = data.recommendedPurchaseAmount
        this.testAB = data.testAB
        this.failedReasonType = data.failedReasonType
        this.shadowLimit = data.shadowLimit
    }
}
