import { HttpClient } from '@leanpay/http-client'

import { errorHandler } from '../errorHandler'
import { verificationInterceptor } from '../interceptors'

class TimeService {
    _httpClient = new HttpClient({
        config: { baseURL: 'api/' },
        interceptorConfig: {
            onRequestFulfilled: verificationInterceptor,
            onRejected: errorHandler,
        },
    })

    private _timeNowUrl = 'time/now'

    async getCurrentTime() {
        const { data } = await this._httpClient.get<number>(this._timeNowUrl)

        return data
    }
}

export const timeService = new TimeService()
