import {
    EmploymentContractType,
    EmploymentType,
    FinancialDetailsRequestV2,
    LatePaymentTime,
} from './dto-client'

export class FinancialDetailsV2 {
    token: string
    income?: number
    employmentType?: EmploymentType
    monthlyCreditExpense?: number
    monthlyHouseCredit?: number
    monthlyCashOrMortgageCredit?: number
    monthlyCreditExpenseInLastThreeMonthExcludeLoan?: number
    monthlyCreditExpenseExcludeLoan?: number
    monthlyLeasingOrAutoCredit?: number
    employerCredit?: boolean
    useCreditCardForInstallmentPayment?: boolean
    creditCardLimit?: number
    executionInLastYear?: boolean
    executionInLastYearAmountId?: number
    executionInLastYearCreditOrLeasing?: boolean
    personalBankruptcyInProgress?: boolean
    lateInstallmentPayment?: boolean
    lateInstallmentPaymentTime?: LatePaymentTime | null
    employmentContractType?: EmploymentContractType
    numberOfDependants?: number
    numberOfChildren?: number
    numberOfConsecutivePaymentMonths?: string
    numberOfRecentLoans?: string
    financialProductBankAccountLimit?: boolean
    financialProductCreditCard?: boolean
    financialProductBankCredit?: boolean
    financialProductLeasing?: boolean
    industryCodebookId?: number
    historicalLoanLessThanAmount?: boolean
    bankAccountNumber?: string
    nrLoansDelaysInLastYear?: string
    nrLoansInLastYear?: string

    constructor(data: FinancialDetailsRequestV2) {
        this.token = data.token
        this.income = data.income
        this.employmentType = data.employmentType
        this.monthlyCreditExpense = data.monthlyCreditExpense
        this.monthlyCreditExpenseInLastThreeMonthExcludeLoan =
            data.monthlyCreditExpenseInLastThreeMonthExcludeLoan
        this.monthlyCreditExpenseExcludeLoan = data.monthlyCreditExpenseExcludeLoan
        this.monthlyHouseCredit = data.monthlyHouseCredit
        this.monthlyCashOrMortgageCredit = data.monthlyCashOrMortgageCredit
        this.monthlyLeasingOrAutoCredit = data.monthlyLeasingOrAutoCredit
        this.employerCredit = data.employerCredit
        this.useCreditCardForInstallmentPayment = data.useCreditCardForInstallmentPayment
        this.creditCardLimit = data.creditCardLimit
        this.executionInLastYear = data.executionInLastYear
        this.executionInLastYearAmountId = data.executionInLastYearAmountId
        this.executionInLastYearCreditOrLeasing = data.executionInLastYearCreditOrLeasing
        this.personalBankruptcyInProgress = data.personalBankruptcyInProgress
        this.lateInstallmentPayment = data.lateInstallmentPayment
        this.lateInstallmentPaymentTime = data.lateInstallmentPaymentTime
        this.employmentContractType = data.employmentContractType
        this.numberOfDependants = data.numberOfDependants
        this.numberOfChildren = data.numberOfChildren
        this.numberOfConsecutivePaymentMonths = data.numberOfConsecutivePaymentMonths
        this.financialProductBankAccountLimit = data.financialProductBankAccountLimit
        this.financialProductCreditCard = data.financialProductCreditCard
        this.financialProductBankCredit = data.financialProductBankCredit
        this.financialProductLeasing = data.financialProductLeasing
        this.industryCodebookId = data.industryCodebookId
        this.historicalLoanLessThanAmount = data.historicalLoanLessThanAmount
        this.bankAccountNumber = data.bankAccountNumber
        this.nrLoansDelaysInLastYear = data.nrLoansDelaysInLastYear
        this.nrLoansInLastYear = data.nrLoansInLastYear
    }
}
