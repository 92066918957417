import { Box, Flex, P } from '@leanpay/ui'
import { lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { isMarketHun, isMarketRom, isMarketSlo, isProdEnvironment } from 'library/constants'
import { handleCheckoutProgress } from 'library/utils'

import { useAppStore } from './app-context'
import CheckoutHeader from './components/checkout-header/checkout-header'

const MainAppHUN = lazy(() => import('./checkout-markets/hun/main-app-hun'))
const MainAppROM = lazy(() => import('./checkout-markets/rom/main-app-rom'))
const MainAppSLO = lazy(() => import('./checkout-markets/slo/main-app-slo'))

const MainLayout = () => {
    const { i18n } = useTranslation()
    const location = useLocation()
    const { progress, setProgress, isAuthenticated } = useAppStore()

    //show only if user is authenticated and screen is not checkout-success
    const showCancelIcon = isAuthenticated && location.pathname !== '/checkout-success'

    useEffect(() => {
        handleCheckoutProgress(location.pathname, setProgress)
    }, [location.pathname, setProgress])

    const renderApp = () => {
        if (isMarketSlo) return <MainAppSLO />
        else if (isMarketRom) return <MainAppROM />
        else if (isMarketHun) return <MainAppHUN />

        throw Error('Error')
    }

    const marketLanguage = isMarketHun ? 'hu' : isMarketSlo ? 'sl' : 'ro'

    return (
        <>
            {!isProdEnvironment && (
                <Box
                    css={{
                        position: 'fixed',
                        paddingLeft: 70,
                        top: 30,
                        zIndex: 1000,
                        display: 'flex',
                        gap: '$1',
                        justifyContent: 'flex-start',
                    }}
                >
                    <P
                        onClick={() => {
                            i18n.changeLanguage('en')
                        }}
                        css={{
                            cursor: 'pointer',
                            color: i18n.language === 'en' ? '$peach2' : '$dark2',
                        }}
                    >
                        EN
                    </P>
                    <P
                        onClick={() => {
                            i18n.changeLanguage(marketLanguage)
                        }}
                        css={{
                            cursor: 'pointer',
                            color: i18n.language === 'en' ? '$dark2' : '$peach2',
                        }}
                    >
                        {String(marketLanguage).toUpperCase()}
                    </P>
                </Box>
            )}
            <Flex
                css={{
                    'alignSelf': 'center',
                    'flexDirection': 'column',
                    'alignItems': 'center',
                    'maxWidth': '840px',
                    'width': '100%',
                    'backgroundColor': '$grey2',
                    'minHeight': '100vh',
                    'borderRadius': '$1',
                    '@minMd': {
                        'maxHeight': '95vh',
                        'minHeight': '95vh',
                        'overflowY': 'scroll',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                    },

                    '@md': {
                        borderRadius: '0px',
                        maxWidth: '100%',
                    },
                    '@sm': {
                        borderRadius: '0px',
                        maxWidth: '100%',
                    },
                }}
            >
                <CheckoutHeader showCancelIcon={showCancelIcon} progress={progress}>
                    {renderApp()}
                </CheckoutHeader>
            </Flex>
        </>
    )
}

export default MainLayout
