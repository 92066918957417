import { RestResponseKey } from '@leanpay/utils'

import { PaymentPlan } from './PaymentPlan'
import { PersonalDetails } from './PersonalDetails'
import { SessionInfo } from './SessionInfo'
import { IdentityCardType } from './dto-verification'

export interface CountryCodebookDTO {
    id: number
    name: string
    isoAlpha2Code: string
    isoAlpha3Code: string
    featured: boolean
}
export interface VendorUserPasswordChangeRequestDTO {
    personId: number
    password: string
    passwordRepeat: string
    version?: number
}

export interface PasswordResetRequest {
    email: string
}

export enum DisputeType {
    CANCEL_CREDIT = 'CANCEL_CREDIT',
    ADVANCED_PAYMENT = 'ADVANCED_PAYMENT',
}
export enum VendorAccountStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
}
export interface VendorUserRoleDTO {
    roleId: number
    roleName: string
}

export interface VendorUserRequestDTO {
    email: string
    firstName: string
    lastName: string
    hasAccessToVerificationApp: boolean
    roleId: number
    status: VendorAccountStatus
    version?: number
}

export interface VendorUserResponseDTO {
    id: number
    email: string
    firstName: string
    lastName: string
    role: VendorUserRoleDTO
    hasAccessToVerificationApp: boolean
    status: VendorAccountStatus
    version: number
}

export interface TransactionDeliveryRequest {
    id: number
}

export interface DisputeRequest {
    transactionId: number
    message: string
    type?: DisputeType
    annexDocumentId?: number
    forceDispute?: boolean
}
export interface NationalityCodebookDTO {
    country: string
    name: string
    id: number
    nameEn: string
}
export interface IndustryOfEmploymentDTO {
    name: string
    id: number
    nameEn: string
}

export interface SubmitAdditionalDocumentValidationRequest {
    token: string
    documentType: string
    documentId: string
    firstName: string
    lastName: string
}

export interface StreetCodebookDTO {
    id: number
    code: string
    name: string
}
export interface PostZipCodebookDTO {
    id: number
    code: string
    displayName: string
    name?: string
}

export interface Page<T> {
    content: T[]
    totalElements: number
    totalPages: number
}

export interface SettlementCodebookDTO {
    id: number
    code: string
    name: string
}

export interface PostZipCodebookDTO {
    id: number
    code: string
    displayName: string
    name?: string
}

export interface PersonalDetailsCheckRequest {
    token: string
    taxNumber: string
    email: string
    couponCode?: string
    firstName: string
    lastName: string
    marketingConsent: boolean
    bankAccountNumber?: string
}

export interface PersonalDetailsV2Request {
    token: string
    firstName: string
    lastName: string
    email: string
    birthFirstName?: string
    birthLastName?: string
    mothersMaidenFirstName?: string
    mothersMaidenLastName?: string
    marketingConsent?: boolean
    placeOfBirth?: string
    gender?: string
    domesticCitizenshipAccepted?: boolean
    birthDate?: string
    countryOfBirthCodebookId?: number
    nationalityCodebookId?: number
    identityCardType?: string
    identityCardNumber?: string
    taxNumber?: string
    responsibilityAccepted?: boolean
    creditBureauConsentAccepted?: boolean
}

export interface FinancialProductV2Request {
    token: string
    maturityPriceId: any
    maturityGraceId: any
    firstPaymentDate: string
}
export interface FinancialAccountNumberV2Request {
    token: string
    bankAccountNumber: string
}

export enum CheckoutStep {
    TERM = 'TERM',
    PRE_QUALIFIED_INFO = 'PRE_QUALIFIED_INFO',
    COOKIE_CONSENT = 'COOKIE_CONSENT',
    LOGIN_PHONE_NUMBER = 'LOGIN_PHONE_NUMBER',
    LOGIN_AUTHENTIFICATION = 'LOGIN_AUTHENTIFICATION',
    PERSONAL_DETAILS = 'PERSONAL_DETAILS',
    ADDITIONAL_DATA = 'ADDITIONAL_DATA',
    ADDRESS_DATA = 'ADDRESS_DATA',
    PSD2 = 'PSD2',
    FINANCIAL_DETAILS = 'FINANCIAL_DETAILS',
    FINANCIAL_DETAILS_OVERVIEW = 'FINANCIAL_DETAILS_OVERVIEW',
    VALIDATE_OFFER = 'VALIDATE_OFFER',
    ADJUST_OFFER = 'ADJUST_OFFER',
    APPROVED = 'APPROVED',
    CONTRACT = 'CONTRACT',
    PAYMENT = 'PAYMENT',
    TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS',
    TRANSACTION_ERROR = 'TRANSACTION_ERROR',
    PRE_QUALIFIED_SUCCESS = 'PRE_QUALIFIED_SUCCESS',
    CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS',
    PRE_QUALIFIED_ERROR = 'PRE_QUALIFIED_ERROR',
    CALL_ME = 'CALL_ME',
    CHECKOUT_ERROR = 'CHECKOUT_ERROR',
    TWO_FA_PIN = 'TWO_FA_PIN',
    FORGOT_TWO_FA_PIN = 'FORGOT_TWO_FA_PIN',
    KYC_VERIFICATION_INFO = 'KYC_VERIFICATION_INFO',
    KYC_VERIFICATION = 'KYC_VERIFICATION',
    KYC_VERIFICATION_POA_INFO = 'KYC_VERIFICATION_POA_INFO',
    KYC_VERIFICATION_POA = 'KYC_VERIFICATION_POA',
    SELF_CHECKOUT_INFO = 'SELF_CHECKOUT_INFO',
    ONBOARDING = 'ONBOARDING',
    ONBOARDING_SHORT = 'ONBOARDING_SHORT',
    KYC_FAILED = 'KYC_FAILED',
    SET_PURCHASE_AMOUNT = 'SET_PURCHASE_AMOUNT',
    BARCODE = 'BARCODE',
}

export enum CheckoutStepViewType {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
}

export interface RecordStepParams {
    token: string
    checkoutStepPriority?: number
    checkoutStep: CheckoutStep
    checkoutStepViewType: CheckoutStepViewType
    data: any
}

export interface CheckoutStepRecordRequest {
    token: string
    checkoutStepPriority?: number
    checkoutStep: CheckoutStep
    checkoutStepViewType: CheckoutStepViewType
    data?: string
}

export enum Gender {
    Male = 'Male',
    Female = 'Female',
}

export interface PersonalDetailsFormData {
    firstName: string
    lastName: string
    postZipId: number
    settlementId: number
    streetId?: number
    street?: string
    houseNumber: string
    gender: Gender
    birthDate: string
    placeOfBirth: string
    countryOfBirthCodebookId: number
    taxNumber: string
    email: string
    termsAccepted: boolean
    citizenshipAccepted: boolean
    coupon?: string
    bankAccountNumber?: string
    marketingConsent?: boolean
    termsPart1: boolean
    termsPart2: boolean
}

export interface Testimonial {
    personName: string
    text: string
}

export interface PersonalDetailsData {
    firstName: string
    lastName: string
    countryOfBirth: string
    email: string
    street: string
    birthDate: string
    houseNumber: string
    settlement: number
    postZip: string
    countryOfBirthCodebookId: number
    placeOfBirth: string
    gender: string
    taxNumber: ''
    coupon?: string
    citizenship: boolean
    termsPart1: boolean
    termsPart2: boolean
    marketingConsent: boolean
    bankAccountNumber?: string
}

export interface PersonalDetailsDTO {
    firstName: string
    lastName: string
    birthDate: string
    email: string
    postZipCodebookId: number
    settlementCodebookId: number
    streetCodebookId: number
    street: string
    houseNumber: string
    marketingConsent: boolean
    bankAccountNumber: string
    cnp?: string
}

export interface PersonalDetailsDTOV2 {
    firstName: string
    lastName: string
    email: string
    marketingConsent: boolean
    birthFirstName: string
    birthLastName: string
    mothersMaidenFirstName: string
    mothersMaidenLastName: string
    placeOfBirth: string
    gender: Gender
    domesticCitizenshipAccepted?: boolean
    birthDate?: string
    countryOfBirthCodebookId: number
    identityCardType: IDDocumentType
    identityCardNumber: string
    taxNumber: string
    responsibilityAccepted: boolean
    creditBureauConsentAccepted: boolean
    nationalityCodebookId?: number
}

export interface AccountSingleStepPinRequest {
    phoneNumber: string
    token?: string
}

export interface JWTLoginRequest {
    token?: string
    username: string
    password: string
    rememberMe?: boolean
}

export interface AuthResponse {
    idToken: string
}
export interface AuthRequest {
    username: string
    password: string
}

export enum FinancialDetailsOptionsTypes {
    TYPE_1 = 'TYPE_1',
    TYPE_2 = 'TYPE_2',
}

export enum EmploymentType {
    FULL_TIME = 'FULL_TIME',
    PART_TIME = 'PART_TIME',
    SP_IT = 'SP_IT',
    SP_OTHER = 'SP_OTHER',
    STUDENT = 'STUDENT',
    RETIRED = 'RETIRED',
    UNEMPLOYED = 'UNEMPLOYED',
    OTHER = 'OTHER',
    FARMER = 'FARMER',
    FREE_ARTIST = 'FREE_ARTIST',
    SEASONAL_WORKER = 'SEASONAL_WORKER',
}

export enum EmploymentContractType {
    DO_NOT_WANT_TO_ASK = 'DO_NOT_WANT_TO_ASK',
    PERMANENT = 'PERMANENT',
    TEMPORARY = 'TEMPORARY',
    HONORARY = 'HONORARY',
}

export interface EmploymentMetaTypeDTO {
    name: EmploymentType
    contractTypes: EmploymentContractType[]
}

export enum ExecutionAmountDTO {
    LOWER_THAN_100 = 'LOWER_THAN_100',
    BETWEEN_101_200 = 'BETWEEN_101_200',
    GREATER_THAN_200 = 'GREATER_THAN_200',
}

export enum LatePaymentTime {
    BETWEEN_1_AND_7_DAYS = 'BETWEEN_1_AND_7_DAYS',
    BETWEEN_8_AND_15_DAYS = 'BETWEEN_8_AND_15_DAYS',
    BETWEEN_16_AND_30_DAYS = 'BETWEEN_16_AND_30_DAYS',
    GREATER_THAN_30_DAYS = 'GREATER_THAN_30_DAYS',
}

export interface ExecutionAmountMetaDTO {
    id: number
    orderNumber: number
    fromAmount: number
    toAmount: number
    name: string
}

export interface FinancialDetailsRequestV2 {
    token: string
    income?: number
    employmentType?: EmploymentType
    monthlyCreditExpense?: number
    monthlyHouseCredit?: number
    monthlyCreditExpenseInLastThreeMonthExcludeLoan?: number
    monthlyCreditExpenseExcludeLoan?: number
    monthlyCashOrMortgageCredit?: number
    monthlyLeasingOrAutoCredit?: number
    employerCredit?: boolean
    useCreditCardForInstallmentPayment?: boolean
    creditCardLimit?: number
    executionInLastYear?: boolean
    executionInLastYearAmountId?: number
    executionInLastYearCreditOrLeasing?: boolean
    personalBankruptcyInProgress?: boolean
    lateInstallmentPayment?: boolean
    lateInstallmentPaymentTime?: LatePaymentTime | null
    employmentContractType?: EmploymentContractType
    numberOfDependants?: number
    numberOfChildren?: number
    numberOfConsecutivePaymentMonths?: string
    financialProductBankAccountLimit?: boolean
    financialProductCreditCard?: boolean
    financialProductBankCredit?: boolean
    financialProductLeasing?: boolean
    industryCodebookId?: number
    historicalLoanLessThanAmount?: boolean
    bankAccountNumber?: string
    nrLoansInLastYear?: string
    nrLoansDelaysInLastYear?: string
}

export interface FinancialDetailsCheckRequest {
    token: string
    financialProductBankAccountLimit?: boolean
    financialProductCreditCard?: boolean
    financialProductBankCredit?: boolean
    financialProductLeasing?: boolean
    income: number
    monthlyCreditExpense?: number
    monthlyHouseCredit?: number
    monthlyCashOrMortgageCredit?: number
    monthlyLeasingOrAutoCredit?: number
    employerCredit?: boolean
    useCreditCardForInstallmentPayment?: boolean
    creditCardLimit?: number
    executionInLastYear: boolean
    executionInLastYearCreditOrLeasing?: boolean
    executionInLastYearAmount?: ExecutionAmountDTO
    personalBankruptcyInProgress: boolean
    lateInstallmentPayment?: boolean
    lateInstallmentPaymentTime?: LatePaymentTime
    employmentType: EmploymentType
    employmentContractType?: EmploymentContractType
    householdMembersNumber: number
    bankAccountNumber?: string
    numberOfChildren?: number
    numberOfConsecutivePaymentMonths?: string
    numberOfRecentLoans?: string
}

export interface FinancialDetailsValidationDataDTO {
    status: boolean
    errorKeys: RestResponseKey[]
}

export interface PreQualifiedRequest {
    surrogateId: string | null
    token: string
}

export interface LoanAmountLimitsRequest {
    vendorId: string
}

export interface TransactionRequest {
    surrogateId: string | null
    token: string
    maturityPriceId: number
    maturityGraceId: number
    firstPaymentDate: string

    // ----------- <FINANCIAL DETAILS> -------------------------------------------
    checkoutFlowType?: string
    income?: number
    employmentType?: string
    bankAccountNumber?: string
    monthlyCreditExpense?: number
    monthlyHouseCredit?: number
    monthlyCashOrMortgageCredit?: number
    monthlyLeasingOrAutoCredit?: number
    employerCredit?: boolean
    useCreditCardForInstallmentPayment?: boolean
    creditCardLimit?: number
    executionInLastYear?: boolean | null
    executionInLastYearCreditOrLeasing?: boolean | null
    executionInLastYearAmount?: ExecutionAmountDTO | null
    personalBankruptcyInProgress?: boolean | null
    lateInstallmentPayment?: boolean | null
    lateInstallmentPaymentTime?: LatePaymentTime | null
    employmentContractType?: EmploymentContractType | null
    householdMembersNumber?: number
    numberOfDependants?: number | string
    numberOfChildren?: number
    numberOfConsecutivePaymentMonths?: string
    numberOfRecentLoans?: string
}

export interface ApplicationPreQualifiedRequest extends PreQualifiedRequest {
    firstName: string
    lastName: string
    email?: string
    zip?: string
    city?: string
    postZipCodebookId: number
    settlementCodebookId: number
    streetCodebookId?: number
    street?: string
    houseNumber: string | number
    gender: Gender
    birthDate: string
    placeOfBirth: string
    countryOfBirth?: string
    countryOfBirthCodebookId: number
    taxNumber: string
    responsibilityAccepted: boolean
    citizenshipOfSloveniaAccepted: boolean
    financialProductBankAccountLimit?: boolean
    financialProductCreditCard?: boolean
    financialProductBankCredit?: boolean
    financialProductLeasing?: boolean
    useCreditCardForInstallmentPayment?: boolean
    creditCardLimit?: number
    executionInLastYear?: boolean | null
    executionInLastYearCreditOrLeasing?: boolean | null
    executionInLastYearAmount?: ExecutionAmountDTO | null
    personalBankruptcyInProgress?: boolean | null
    lateInstallmentPayment?: boolean | null
    lateInstallmentPaymentTime?: LatePaymentTime | null
    monthlyCreditExpense?: number
    monthlyHouseCredit?: number
    monthlyCashOrMortgageCredit?: number
    monthlyLeasingOrAutoCredit?: number
    employerCreditYesNo?: boolean
    income?: number
    employmentType?: EmploymentType
    employmentContractType?: EmploymentContractType
    numberOfDependants?: number | string
    numberOfChildren?: number
    bankAccountNumber?: string
    bankAccountNumberUsedForDirectDebit?: boolean | null
    numberOfConsecutivePaymentMonths?: string
    numberOfRecentLoans?: string
    checkoutFlowType?: string
}

export interface ApplicationRequest extends Partial<TransactionRequest> {
    firstName: string
    lastName: string
    email?: string
    zip?: string
    city?: string
    postZipCodebookId: number
    settlementCodebookId: number
    streetCodebookId?: number
    street?: string
    houseNumber: string | number
    gender: Gender
    birthDate: string
    placeOfBirth: string
    countryOfBirth?: string
    countryOfBirthCodebookId: number
    taxNumber: string
    responsibilityAccepted: boolean
    citizenshipOfSloveniaAccepted: boolean
    financialProductBankAccountLimit?: boolean
    financialProductCreditCard?: boolean
    financialProductBankCredit?: boolean
    financialProductLeasing?: boolean
    useCreditCardForInstallmentPayment?: boolean
    creditCardLimit?: number
    executionInLastYear?: boolean | null
    executionInLastYearCreditOrLeasing?: boolean | null
    executionInLastYearAmount?: ExecutionAmountDTO | null
    personalBankruptcyInProgress?: boolean | null
    lateInstallmentPayment?: boolean | null
    lateInstallmentPaymentTime?: LatePaymentTime | null
    monthlyCreditExpense?: number
    monthlyHouseCredit?: number
    monthlyCashOrMortgageCredit?: number
    monthlyLeasingOrAutoCredit?: number
    employerCreditYesNo?: boolean
    income?: number
    employmentType?: EmploymentType
    employmentContractType?: EmploymentContractType
    numberOfDependants?: number | string
    numberOfChildren?: number
    bankAccountNumber?: string
    bankAccountNumberUsedForDirectDebit?: boolean | null
    numberOfConsecutivePaymentMonths?: string
    numberOfRecentLoans?: string
    checkoutFlowType?: string
}

export interface ApplicationRequestROM extends Omit<TransactionRequest, 'surrogateId'> {
    firstName: string
    lastName: string
    postZipCodebookId: number
    houseNumber: string
    taxNumber: string
    responsibilityAccepted: boolean
    employmentType?: EmploymentType
    income: number
    email: string
    placeOfBirth: string
    countryOfBirthCodebookId: number
    settlementCodebookId: number
    birthDate: string
    street: string
    additionalAddressDetails: string
}

export interface MaturityDTO {
    id: number
    numberOfMonths: number
    description: string
}

export interface PaymentPlanDTO {
    possible: boolean
    termMaturity: MaturityDTO
    graceMaturity: MaturityDTO
    loanAmount: number
    totalAmount: number
    graceAmount: number
    installmentAmount: number
    firstInstallmentAmount: number
    firstInstallmentDate: string
    numberOfInstallments: number
    nominalInterestRate: number
    costOfReview: number
    costOfRisk: number
    clientInterest: number
    interestAndFees: number
    eom: number
    downPaymentTotal: number
    downPaymentPrincipal: number
    calculationMethod: CostsCalculationMethod
}

export enum CostsCalculationMethod {
    FIRST_INSTALLMENT = 'FIRST_INSTALLMENT',
    DIVIDED_ON_INSTALLMENTS = 'DIVIDED_ON_INSTALLMENTS',
}

export interface PaymentDataOption {
    numberOfMonths: number
    paymentPlan: PaymentPlan
    possible: boolean
}

export enum TestAB {
    A = 'A',
    B = 'B',
}

export interface ValidationDataDTO {
    applicationId: number
    status: boolean
    validatedPaymentPlan: PaymentPlanDTO
    hasPossibleCashFlows: boolean
    paymentPlans: PaymentPlanDTO[]
    availableExposure: number
    recommendedPurchaseAmount: number
    testAB: TestAB
    failedReasonType?: 'EMPTY' | 'NO_PAYMENT_OPTIONS' | 'FAIL_CREDIT_CHECK'
    shadowLimit?: number
}
export interface ValidationDataV2DTO {
    token: string
}

export interface VendorApiInitResponse {
    token: string
    vendorRequestType: string
    inStoreFlag: boolean
    vendorPackageDescription: string
    customerLanguage: string
    vendorName: string
}

export interface ApplicationScoringRequest {
    applicationId: number
    token: string
}

export interface ValidateOfferInitialResponse {
    applicationId: number
    token: string
}

export enum ScoringStatus {
    CREATED = 'CREATED',
    PENDING = 'PENDING',
    DONE = 'DONE',
    FAILED = 'FAILED',
}

export interface ApplicationScoringResponse {
    applicationId: number
    status: ScoringStatus
}

export interface ValidationDataResultRequest {
    applicationId: number
    maturityPriceId?: number
    maturityGraceId?: number
    token: string
}

export interface GetSelfCheckoutTokenRequest {
    vendorId: string
    financialProductCode?: string
    amount: number
}

export interface CreatePinRequest {
    pin: string
    rePin: string
}

export enum MembershipStatus {
    NOT_SUBMITTED = 'NOT_SUBMITTED',
    PENDING = 'PENDING',
    CONTRACT_SENT = 'CONTRACT_SENT',
    CONTRACT_SUBMITTED = 'CONTRACT_SUBMITTED',
    CONFIRMED = 'CONFIRMED',
    REJECTED = 'REJECTED',
    RESTARTED = 'RESTARTED',
}

export enum AccountVerificationStatus {
    NOT_VERIFIED = 'NOT_VERIFIED',
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
}

export enum TwoFAStatus {
    SET = 'SET',
    NOT_SET = 'NOT_SET',
    LOCKED = 'LOCKED',
    DISABLED = 'DISABLED',
}

export interface SessionInfoDTO {
    firstName: string
    lastName: string
    membershipStatus: MembershipStatus
    accountVerificationStatus: AccountVerificationStatus
    creditRating: string
    emailVerified: boolean
    annexActive: boolean
    pendingApproval: boolean
    hasPreApprovedTransaction: boolean
    customOfferUrlEnabled: boolean
    remoteOfferEnabled: boolean
    loginActions: string[]
    twoFAStatus: TwoFAStatus
    iphoneForLifeProgram: boolean
    cardPaymentEnabled: boolean
    userIdentityNumber: string
    email: string
    checkoutFlowType: CheckoutFlowType
}
export interface ChangePinRequest {
    currentPin: string
    newPin: string
    newRePin: string
}

export interface ReCreatePinRequest {
    pin: string
    rePin: string
    last3DigitsOfTaxNumber?: string
    last3DigitsOfBankAccountNumber?: string
    mothersMaidenLastName?: string
}

export interface ProfileIbanChangeRequest {
    bankAccountNumber: string
}

export interface PersonMarketingConsentChangeRequest {
    consentMarketing: boolean
}

export interface ProfileAddressChangeRequest {
    postZipCodebookId: number
    settlementCodebookId: number
    streetCodebookId?: number
    street?: string
    houseNumber: string
}

export interface PreQualifiedInfoRequest {
    token: string
}

export interface PreQualifiedInfoDTO {
    vendorKnown: boolean
    vendorName: string
    returnUrl: string
    incomeLimit: number
}
export interface VendorApiPreQualifiedRequest {
    vendor?: string
    returnUrl?: string
    requestType?: VendorRequestType
    productCode?: string
}

export interface SystemConfigResponse {
    psd2SandboxModeEnabled: boolean
}

export interface Psd2TokenRequest {
    token: string
    data?: string
    bankId?: number
    iban?: string
    language?: string
    returnTo?: string
    externalConnectionId?: string
}

export enum BankszamlakivonatCode {
    SUCCESS = 'SUCCESS',
    ACCOUNT_NOT_ATTACHED = 'ACCOUNT_NOT_ATTACHED',
    ACCOUNT_ATTACHMENT_FAILED = 'ACCOUNT_ATTACHMENT_FAILED',
}

export interface ConnectionStatusRequest {
    customerId: string
    connectionId: string
    status: BankszamlakivonatCode
}

export interface Psd2TokenRequestResponse {
    redirectUrl: string
}

export interface Psd2ConnectionStatusRequest {
    token: string
    connectionId: string
}

export interface Psd2ConnectionStatusResponse {
    accessGranted: boolean
    data: string
    nameMatched: boolean
}

export interface PaymentDataDTO {
    purchaseAmount: number
    paymentCycleDates: string[]
    gracePaymentCycleDates: string[]
    successUrl: string
    errorUrl: string
    siteUrl: string
    paymentPlans: PaymentPlanDTO[]
    showCoupon: boolean
    pendingApproval: boolean
    pendingSisbonCheck: boolean
    incomeLimit: number
}

export interface CheckoutDataDTO {
    token: string
    sessionInfo?: SessionInfo | null
    maturityPriceId?: number
    maturityGraceId?: number
    firstPaymentDate?: string
    authToken?: string
    personalDetails?: PersonalDetails | null
    paymentData?: PaymentDataDTO | null
    isVerified?: boolean
    isInStore?: boolean
    paymentPlan?: PaymentPlanDTO | null
    incomeLimit?: number
    vendorKnown?: boolean
    vendorName?: string
    returnUrl?: string
    vendorRequestType?: string
    vendorPackageDescription?: string
    customerLanguage?: string
    pendingApproval?: boolean
    applicationApprovalType?: string
}

export interface CurrencyInfo {
    primaryCurrency: string
    secondaryCurrency: string | null
    multiCurrency: boolean
    conversionRate: number | null
}

export enum CurrencySymbols {
    EUR = '€',
    lei = 'lei',
    Ft = 'Ft',
}

export interface PaymentDataRequest {
    token: string
    firstPaymentDate?: string
    maturityType?: MaturityType
}

export enum MaturityType {
    GRACE = 'GRACE',
    PRICE = 'PRICE',
}

export interface PaymentDataDTO {
    purchaseAmount: number
    paymentCycleDates: string[]
    successUrl: string
    errorUrl: string
    siteUrl: string
    paymentPlans: PaymentPlanDTO[]
    showCoupon: boolean
    pendingApproval: boolean
    pendingSisbonCheck: boolean
    incomeLimit: number
}

export interface TransactionRequest {
    surrogateId: string | null
    token: string
    maturityPriceId: number
    maturityGraceId: number
    firstPaymentDate: string
    income?: number
    employmentType?: string
}

export interface RequestFinancialDataRequest {
    token: string
    firstName: string
    lastName: string
    userIdentityNumber: string
}

export interface FinancialDataResponse {
    requestId: string
    userId: string
    userIdentityNumber: string
    income: number
    status: string
    employmentType: string
    additionalEmploymentInfoDataRequired?: boolean
}

export interface PersonBaseInfoDTO {
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    blocked: boolean
    verified: boolean
}

export type AdditionalEmploymentInfo = FinancialDataResponse

export enum FinancialDataStatus {
    'IN_PROCESSING' = 'IN_PROCESSING',
    'RESULTS_UNAVAILABLE' = 'RESULTS_UNAVAILABLE',
    'RESULTS_AVAILABLE' = 'RESULTS_AVAILABLE',
    'RESULTS_INVALID' = 'RESULTS_INVALID',
}

export interface ContractContentDTO {
    items: ContractSectionDTO[]
}

export enum DocumentType {
    REPRESENTATIVE_EXAMPLE = 'REPRESENTATIVE_EXAMPLE',
    DIGITAL_VERIFICATION_POLICY = 'DIGITAL_VERIFICATION_POLICY',
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    CONSUMER_LOAN_LAW = 'CONSUMER_LOAN_LAW',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    PRICE_LIST = 'PRICE_LIST',
    CONTRACT = 'CONTRACT',
    CONTRACT_DRAFT = 'CONTRACT_DRAFT',
    CONTRACT_PRELIMINARY = 'CONTRACT_PRELIMINARY',
    VERIFICATION_DOCUMENT = 'VERIFICATION_DOCUMENT',
    CONTRACT_ANNEX = 'CONTRACT_ANNEX',
    INSTALLMENT_PLAN = 'INSTALLMENT_PLAN',
    INSTALLMENT_PLAN_DRAFT = 'INSTALLMENT_PLAN_DRAFT',
    IPHONE_ASSET_PROTECTION = 'IPHONE_ASSET_PROTECTION',
    IPHONE_PROGRAM = 'IPHONE_PROGRAM',
    SEPA_DIRECT_DEBIT = 'SEPA_DIRECT_DEBIT',
    SEPA_CONSENT = 'SEPA_CONSENT',
    PEP = 'PEP',
    INVOICE = 'INVOICE',
    SISBON_REPORT = 'SISBON_REPORT',
    COOKIE_POLICY = 'COOKIE_POLICY',
    COMPLAINT_POLICY = 'COMPLAINT_POLICY',
    FULL_ADVANCED_PAYMENT = 'FULL_ADVANCED_PAYMENT',
    TRADECORE_TERMS_AND_CONDITIONS = 'TRADECORE_TERMS_AND_CONDITIONS',
    MONAVATE_TERMS_AND_CONDITIONS = 'MONAVATE_TERMS_AND_CONDITIONS',
    FINANCIAL_DATA_AGREEMENT = 'FINANCIAL_DATA_AGREEMENT',
    CREDIT_BUREAU_AGREEMENT = 'CREDIT_BUREAU_AGREEMENT',
    HARDWARE_AND_SOFTWARE_COMPATIBILITY = 'HARDWARE_AND_SOFTWARE_COMPATIBILITY',
}

export interface ContractDataComplexDTO {
    documentType: DocumentType
    contractTitle: string
    contractHeader: ContractHeaderDTO
    contractContent: ContractContentDTO
    contractFooter: ContractFooterDTO
}

export interface ContractFooterDTO {
    items: ContractParagraphDTO[]
}

export interface ContractHeaderDTO {
    items: ContractSectionDTO[]
}

export interface ContractParagraphDTO {
    content: string
}

export interface ContractSectionDTO {
    caption: string
    items: ContractParagraphDTO[]
}

export enum RenderingType {
    FREE_TEXT = 'FREE_TEXT',
    BOOLEAN = 'BOOLEAN',
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}
export interface QuestionDTO {
    id: number
    orderNumber: number
    localizationCode: string
    label: string
    value: string | null
    answerId: number
    renderingType: RenderingType
    answers: AnswerDTO[]
    groupName?: string
}

export interface QuestionnaireDTO {
    id: number
    title: string
    description: string
    questions: QuestionDTO[]
    conditionalShowing?: boolean
    additionalQuestions?: QuestionDTO[]
}

export interface LoanAmountLimitDTO {
    IN_STORE: {
        minAmount: number
        maxAmount: number
    }
    IN_STORE_SELF_CHECKOUT: {
        minAmount: number
        maxAmount: number
    }
    REMOTE: {
        minAmount: number
        maxAmount: number
    }
}
export interface QuestionnaireListDTO {
    basicQuestionnaire: QuestionnaireDTO
    additionalQuestionnaire?: QuestionnaireDTO
}

export interface VendorProductValidationRequest {
    vendor: string
    productCode?: string
    requestType: VendorRequestType
}

export interface AnswerDTO {
    id: number
    localizationCode: string
    label: string
    answerType: AnswerType
    value: string
}

export enum AnswerType {
    FREE_TEXT = 'FREE_TEXT',
    BOOLEAN = 'BOOLEAN',
}

export interface ContractPreliminaryRequest {
    surrogateId: string | null
    token: string
}

export interface QuestionResponseDTO {
    questionId: number
    answerId?: number | null
    value: string | null
    renderingType?: string
}

export interface PepQuestionnaireResponseDTO {
    questionnaireId: number
    applicationId: number
    questionResponses: QuestionResponseDTO[]
}

export interface ContractSignOrBuyRequest {
    surrogateId?: string
    token: string
    maturityPriceId: number
    maturityGraceId: number
    firstPaymentDate: string
    contractSigned: boolean
    iphoneAssetProtectionSigned?: boolean
    iphoneProgramSigned?: boolean
    sepaDirectDebitSigned?: boolean
    pepSigned: boolean
    pepQuestionnaireResponse?: PepQuestionnaireResponseDTO | null
    additionalPepQuestionnaireResponse?: PepQuestionnaireResponseDTO | null
}

export interface SignOrBuyResponse {
    status: SignOrBuyStatus
    applicationApprovalId: number
    applicationApprovalCheckType: ApplicationApprovalCheckType
}

export enum ApplicationApprovalCheckType {
    NO_APPROVAL = 'NO_APPROVAL',
    SISBON = 'SISBON',
    BASIC = 'BASIC',
    LIGHT = 'LIGHT',
    FULL = 'FULL',
    PEP = 'PEP',
    MEDIUM = 'MEDIUM',
    RISK = 'RISK',
    CB_POSITIVE_CHECK = 'CB_POSITIVE_CHECK',
    PENSIONER = 'PENSIONER',
    PUBLIC_SERVICE = 'PUBLIC_SERVICE',
}

export enum SignOrBuyStatus {
    PROCEED_TO_PAYMENT = 'PROCEED_TO_PAYMENT',
    TRANSACTION_CREATED = 'TRANSACTION_CREATED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    REJECTED = 'REJECTED',
}

export interface StripeCardRequest {
    stripeToken: string
}

export interface StripeChargeRequest {
    amount: number
    creditCardId?: number
    stripeToken?: string
}

export interface StripeTestRequest {
    amount: number
    stripeToken: string
}

export enum CreditCardType {
    AMEX = 'AMEX',
    DINERS = 'DINERS',
    DISCOVER = 'DISCOVER',
    JCB = 'JCB',
    MASTER = 'MASTER',
    VISA = 'VISA',
    UNKNOWN = 'UNKNOWN',
}

export interface CreditCardDTO {
    id: number
    cardNumber: string
    expirationDate: string
    type: CreditCardType
}

export enum CardPaymentType {
    AUTHORIZATION = 'AUTHORIZATION',
    DOWN = 'DOWN',
    LATE = 'LATE',
    DISPUTE = 'DISPUTE',
    UPCOMING_OR_DUE = 'UPCOMING_OR_DUE',
}

export enum CardPaymentProvider {
    WIRECARD = 'WIRECARD',
    PAYDOO = 'PAYDOO',
    NETOPIA = 'NETOPIA',
}

export enum CardPaymentStatus {
    INITIALIZED = 'INITIALIZED',
    CREATED = 'CREATED',
    PUBLISHED = 'PUBLISHED',
    IN_PROCESSING = 'IN_PROCESSING',
    SUCCESS = 'SUCCESS',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    SUCCESS_TRANSACTION_FAILED = 'SUCCESS_TRANSACTION_FAILED',
    WAITING_REFUND = 'WAITING_REFUND',
    REFUNDED = 'REFUNDED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
}

export enum CardPaymentFailureReason {
    AUTHORIZATION_FAILED_3D_SECURE_MANDATORY = 'AUTHORIZATION_FAILED_3D_SECURE_MANDATORY',
    INVALID_CARD = 'INVALID_CARD',
    CVV2_FAILURE = 'CVV2_FAILURE',
    INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
    CARD_TYPE_NOT_SUPPORTED = 'CARD_TYPE_NOT_SUPPORTED',
    REFERENCED_TRANSACTION_FAILED_GENERAL_ERROR = 'REFERENCED_TRANSACTION_FAILED_GENERAL_ERROR',
    // NEW
    INCORRECT_CARD_NUMBER = 'INCORRECT_CARD_NUMBER',
    CARD_EXPIRED = 'CARD_EXPIRED',
    GENERAL_ERROR = 'GENERAL_ERROR',
}
export interface CardPaymentPrepareRequest {
    token?: string
    applicationId?: number
    transactionId?: number
    paymentType: CardPaymentType
    locale: string
    recurring?: boolean | null
}

export interface CardPaymentResolvedRequest {
    cardPaymentId: string
}
export enum VendorRequestType {
    WEB = 'WEB',
    CUSTOM = 'CUSTOM',
    VIRTUAL_CARD = 'VIRTUAL_CARD',
    SELF_CHECKOUT = 'SELF_CHECKOUT',
}

export interface CardPaymentStatusResponse {
    token: string
    status: CardPaymentStatus
    failureReason: CardPaymentFailureReason
    vendorSuccessUrl: string
    vendorErrorUrl: string
    vendorRequestType: VendorRequestType
    inStore: boolean
    verified: boolean
    vendorPackage: string
    applicationApprovalCheckType: ApplicationApprovalCheckType
    applicationApprovalId: number
    paymentType: CardPaymentType
    iphoneForLifeProgram: boolean
}

export interface CardPaymentPrepareResponse {
    providerIdentifier: string
    cardPaymentId: number
    providerUrl: string
    provider: CardPaymentProvider
    cardPaymentRequestId: string
    amount: number
    providerMetadata?: any
}

export enum SignAndBuyStatus {
    TRANSACTION_CREATED = 'TRANSACTION_CREATED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
}
export enum CheckoutFlowType {
    REGULAR = 'REGULAR',
    FINANCIAL_DATA_UPDATE = 'FINANCIAL_DATA_UPDATE',
    PSD2_DATA_UPDATE = 'PSD2_DATA_UPDATE',
}

export interface VendorRequestInvalidateRequest {
    token: string
}

export enum FailedReasonType {
    FAIL_CREDIT_CHECK = 'FAIL_CREDIT_CHECK',
    NO_PAYMENT_OPTIONS = 'NO_PAYMENT_OPTIONS',
    EMPTY = 'EMPTY',
}

export enum IDDocumentType {
    PERSONAL_ID = 'PERSONAL_ID',
    PASSPORT = 'PASSPORT',
    DRIVING_LICENSE = 'DRIVING_LICENSE',
}

export interface AddressDetailsRequest {
    token: string
    postZipCodebookId: number
    settlementCodebookId: number
    streetCodebookId?: number
    street?: string
    houseNumber: string
    additionalAddressDetails?: string
}

export interface AddressDetailsResponse {
    postZipCodebookId?: number
    settlementCodebookId?: number
    streetCodebookId?: number
    street?: string
    houseNumber?: string
    floor?: string
    apartmentNumber?: string
    entrance?: string
    blockNumber?: string
}

export interface FinancialDetailsResponse {
    income: number | null
    bankAccountNumber: string | null
    employmentType: EmploymentType | null
    monthlyCreditExpense: number | null
    employmentContractType: EmploymentContractType | null
    numberOfDependants: number | null
    industryCodebookId: number | null
}

export enum IndustryOfEmployment {
    StateAdministration = 'State administration',
    Healthcare = 'Healthcare',
    Industry = 'Industry',
    BuildingIndustry = 'Building industry',
    FinanceInsurance = 'Finance, insurance',
    Law = 'Law',
    Tourism = 'Tourism',
    Agriculture = 'Agriculture',
    TradeServiceIndustry = 'Trade, service industry',
    Education = 'Education',
    Transport = 'Transport',
    PoliceNationalDefenseDisasterManagementNAV = 'Police, National Defense, Disaster Management, NAV',
    TelecommunicationsIT = 'Telecommunications, IT',
    ShippingTransportation = 'Shipping, transportation',
    Other = 'Other',
}

export enum MaritalStatus {
    SINGLE = 'SINGLE',
    MARRIED = 'MARRIED',
    WIDOWED = 'WIDOWED',
    DIVORCED = 'DIVORCED',
}

export interface MaritalStatusResponse {
    maritalStatus: MaritalStatus
}

export interface MaritalStatusRequest {
    token: string
    maritalStatus: MaritalStatus
}

export interface EmploymentInfoRequest {
    token: string
    income: number
    employmentType: EmploymentType
    employerName?: string
}

export interface CancelTransactionRequest {
    surrogateId?: string | null
    token: string
    maturityPriceId: number | null
    maturityGraceId: number | null
    firstPaymentDate: number | null
}

export interface ValidationDataDTO {
    applicationId: number
    status: boolean
    validatedPaymentPlan: PaymentPlanDTO
    hasPossibleCashFlows: boolean
    paymentPlans: PaymentPlanDTO[]
    availableExposure: number
    recommendedPurchaseAmount: number
    testAB: TestAB
}

export interface VendorPackageDTO {
    code: string
    amount: number
    description: string
}

export interface VendorPaymentEventProducerDTO {
    vendorPaymentId: number
    createdTime: string
    lastUpdateTime: string
}

export interface BackToSchoolAmountInfoDTO {
    amount: number
    description: string
}

export interface BackToSchoolDTO {
    isBackToSchoolEnabled: boolean
    amounts: BackToSchoolAmountInfoDTO[]
}

export interface BankDTO {
    id: number
    name: string
    description: string
}

export interface ProductDTO {
    id: number
    active: boolean
    name: string
    description: string
    code: string
    maximumInstallments: number
    createdTime: string
    maturities: MaturityDTO[]
    downPaymentActive: boolean
    adjustmentActive: boolean
    inStore: boolean
    iphoneForLifeProgram: boolean
    verifiedCustomersRequired: boolean
}

export interface LoanAmountInfoDTO {
    minAmount: number
    maxAmount: number
}

export interface VendorRequestValidityPeriodDTO {
    period: number
    identifier: string
}

export interface VendorRequestInfoDTO {
    vendorPackages: VendorPackageDTO[]
    isBackToSchoolEnabled: boolean
    backToSchoolAmounts: BackToSchoolAmountInfoDTO[]
    inStoreEnabled: boolean
    products: ProductDTO[]
    defaultProduct: ProductDTO
    defaultProductInStore: ProductDTO
    loanAmountInfo: LoanAmountInfoDTO
    vendorPartialDeliveryEnabled: boolean
    validityPeriods: VendorRequestValidityPeriodDTO[]
    iphoneForLifeProgram: boolean
    iphoneForLifeProduct: ProductDTO
    verifiedCustomersRequired: boolean
    vendorName: string
    //vendorVerificationAppEnabled: boolean
}

export interface OffersSizeDTO {
    size: number
    page: number
}
export enum VendorRequestStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    EXPIRED = 'EXPIRED',
}

export interface VendorRequest {
    id: number
    surrogateId: string
    vendorName: string
    vendorId: number
    validUntil: string
    amount: number
    url: string
    status: VendorRequestStatus
    vendorTransactionId: string
    transactionId: number
    vendorProductCode: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    address: string
    houseNumber: string
    zip: string
    city: string
    birthDate: string
    taxNumber: string
    language: string
    maximumInstallments: number
    backToSchool: boolean
    inStore: boolean
    createdTime: number
    vendorPackageDescription: string
    vendorPackageDescriptionWithPackage: string
    vendorPackageDescriptionWithPrice: string
    iphoneForLife: boolean
}

export enum VendorRequestProgram {
    IphoneForLife = 'iphoneForLife',
    BackToSchool = 'backToSchool',
    NoProgram = 'noProgram',
}

export enum KYC_CHECK_RESULT_RESPONSE {
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
    NOT_VERIFIED = 'NOT_VERIFIED',
}

export interface ValidateVendorResponse {
    vendorLogoUrl: string
    vendorName: string
}

export interface AuthorizationCodeResponse {
    authorizationCode: string
    authorizationCodeImageBase64: string
    validUntil: any // Date TODO: fix type
    amount: number
}

export enum VendorRequestAuthorizationStatus {
    PENDING = 'PENDING',
    VENDOR_CONFIRMED = 'VENDOR_CONFIRMED',
    AUTHORIZED = 'AUTHORIZED',
    REJECTED = 'REJECTED',
}

export enum VendorRequestAuthorizationStatusCode {
    GLOBAL_MIN_REQUIREMENT = 'GLOBAL_MIN_REQUIREMENT',
    AMOUNT_EXCEEDS_INITIALLY_AMOUNT = 'AMOUNT_EXCEEDS_INITIALLY_AMOUNT',
    EXPIRED_GLOBAL = 'EXPIRED_GLOBAL',
    EXPIRED_SECURITY_PROMPT = 'EXPIRED_SECURITY_PROMPT',
    USER_REJECTED = 'USER_REJECTED',
}

export interface AuthorizationStatusResponse {
    status: VendorRequestAuthorizationStatus
    statusCode: VendorRequestAuthorizationStatusCode
    initialAmount: number
    authorizedAmount: number
}

export interface SecurityConfirmationRequest {
    token: string
    userConfirmation: boolean
}

export enum AuthorizationCodeError {
    AUTHORIZATION_CODE_IS_NOT_VALID = 'AUTHORIZATION_CODE_IS_NOT_VALID',
    PURCHASE_AMOUNT_IS_SHORT = 'PURCHASE_AMOUNT_IS_SHORT',
    CART_TOTAL_BELOW_MINIMUM = 'CART_TOTAL_BELOW_MINIMUM',
    SECURITY_PROMPT_EXPIRED = 'SECURITY_PROMPT_EXPIRED',
    SECURITY_PROMPT_WAS_NOT_CONFIRMED = 'SECURITY_PROMPT_WAS_NOT_CONFIRMED',
}

export enum CookieTypes {
    Necessary = 'necessary',
    Analytical = 'analytical',
    Functional = 'functional',
    Marketing = 'marketing',
}

export enum DataLayerEvent {
    PrequalifySuccess = 'pq_success',
}

export interface IdentityCardValidationRequest {
    vendorPhoneNumber: string
    identityCardType: IdentityCardType
    identityCardNumber: string
}

export interface ExistingUserVendorCustomRequest {
    vendorTransactionId: string
    phoneNumber: string
    vendorProductCode: string
    amount: number
    language: string
    validityPeriodIdentifier: string
    inStore: boolean
    customerIdentified?: boolean
}

export interface VendorCustomRequest {
    vendorTransactionId: string
    vendorProductCode: string
    vendorPackageCode?: string
    vendorItemId?: string
    vendorInvoiceId?: string
    amount: number
    vendorPhoneNumber: string
    vendorFirstName: string
    vendorLastName: string
    vendorPostZipCodebookId?: number
    vendorSettlementCodebookId?: number
    vendorStreetCodebookId?: number
    vendorStreet?: string
    vendorHouseNumber?: string
    email?: string
    language: string
    vendorBirthDate?: string
    vendorIdentityCardType?: IdentityCardType
    vendorIdentityCardNumber?: string
    vendorIdentityCardPlaceOfIssueCodebookId?: string | number
    vendorIdentityCardDateOfExpire?: string
    validityPeriodIdentifier: string
    backToSchool?: boolean
    inStore?: boolean
    customerIdentified?: boolean
    iphoneForLifeProgram?: boolean
}

export interface VendorValidUntilToken {
    token?: string
    url?: string
    validUntil: number
    qrCodeBase64?: string
}

export interface CancelVendorCustomRequest {
    id: number
}

export interface TransactionRequestDTO {
    createdTimeFrom: string
    createdTimeTo: string
    searchCriteria?: string
    verificationStatus?: AccountVerificationStatus
    deliveryStatus?: TransactionDeliveryStatus | null
    vendorSettlementStatus?: TransactionVendorSettlementStatusDTO | null
    verificationCandidate?: boolean | null
}

export enum TransactionDeliveryStatus {
    UNDELIVERED = 'UNDELIVERED',
    DELIVERED = 'DELIVERED',
}

export enum TransactionVendorSettlementStatusDTO {
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    DISPUTED = 'DISPUTED',
    FAILED = 'FAILED',
}

export enum VerificationDocumentStatus {
    CREATED = 'CREATED',
    IN_REVIEW = 'IN_REVIEW',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    CLOSED = 'CLOSED',
}

export enum VendorVerificationState {
    CANDIDATE = 'CANDIDATE',
    NOT_CANDIDATE = 'NOT_CANDIDATE',
    VERIFIED = 'VERIFIED',
    IN_REVIEW = 'IN_REVIEW',
}

export enum TransactionClientSettlementStatus {
    PRE_APPROVED = 'PRE_APPROVED',
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    DISPUTED_CANCEL_CREDIT = 'DISPUTED_CANCEL_CREDIT',
    DISPUTED_ADVANCED_PAYMENT = 'DISPUTED_ADVANCED_PAYMENT',
    DELAYED = 'DELAYED',
    NPL = 'NPL',
    CONTRACT_CANCELLED = 'CONTRACT_CANCELLED',
    CONTRACT_TRANSFERRED = 'CONTRACT_TRANSFERRED',
    EXECUTED = 'EXECUTED',
    CANCELLED = 'CANCELLED',
}

export enum TransactionFinancialStatus {
    PENDING = 'PENDING',
    PLEDGE_IN_PROGRESS = 'PLEDGE_IN_PROGRESS',
    PLEDGED = 'PLEDGED',
    TRANSFER_IN_PROGRESS = 'TRANSFER_IN_PROGRESS',
    TRANSFERRED = 'TRANSFERRED',
}

export interface ResultPageDTO<T> {
    result: T[]
    totalElements: number
    totalPages: number
}

export enum DisputeStatus {
    NEW = 'NEW',
    IN_REVIEW = 'IN_REVIEW',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    CLOSED = 'CLOSED',
}

export interface TransactionVendorDTO {
    id: number
    surrogateId: string
    vendorTransactionId: string
    vendorProductCode: string
    createdTime: string
    amount: number
    vendorProductName: string
    numberOfMonths: number
    personId: number
    phoneNumber: string
    firstName: string
    lastName: string
    accountVerificationStatus: AccountVerificationStatus
    deliveryCandidate: boolean
    verificationDocumentStatus: VerificationDocumentStatus
    verificationOwner: boolean
    verificationState?: VendorVerificationState
    transactionDeliveryStatus: TransactionDeliveryStatus
    transactionClientSettlementStatus: TransactionClientSettlementStatus
    transactionVendorSettlementStatus: TransactionVendorSettlementStatusDTO
    transactionFinancialStatus: TransactionFinancialStatus
    disputable: boolean
    disputeStatus: DisputeStatus
}

export enum VendorPaymentInvoiceFileType {
    PDF = 'PDF',
    XLSX = 'XLSX',
    CSV_DELIMITER_COMMA = 'CSV_DELIMITER_COMMA',
    CSV_DELIMITER_SEMICOLON = 'CSV_DELIMITER_SEMICOLON',
}

export interface VendorPaymentInvoiceFileResponse {
    correlationId: string
    fileName: string
    contentType: string
    fileType: VendorPaymentInvoiceFileType
    fileUrl: string
}

export interface VendorPaymentInvoiceResponse {
    invoiceId: number
    invoiceNumber: string
    invoiceDate: string
    payment: number
    fees: number
    status: string
    vendorPaymentInvoiceFileList: VendorPaymentInvoiceFileResponse[]
}

export interface VendorPaymentInvoiceSearchRequest {
    searchCriteria: string
    createdFrom: string
    createdTo: string
    status: string
}

export interface VendorAgentResponseDTO {
    id: number
    agentSignature: string
    phoneNumber: string
    status: VendorAccountStatus
    version: number
}

export interface VendorAgentRequestDTO {
    agentSignature: string
    phoneNumber: string
    status: VendorAccountStatus
    version?: number
}

export interface PaymentPlanRequestDTO {
    vendorProductCode: string
    amount: number
    maturityType: MaturityType
}
