import { memo } from 'react'

const VerificationStatusTimeout = () => (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={20} cy={20} r={20} fill="#F6A623" fillOpacity="0.2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.026 18.5767L21.5 19.4196V15.8752C21.5 15.0472 20.8295 14.3752 20 14.3752C19.1705 14.3752 18.5 15.0472 18.5 15.8752V21.5002C18.5 21.9816 18.731 22.4347 19.1224 22.7183C19.3819 22.9043 19.6894 23.0002 20 23.0002C20.159 23.0002 20.3194 22.9747 20.4739 22.9222L24.9739 21.4222C25.7599 21.1612 26.1845 20.3123 25.922 19.5263C25.6595 18.7403 24.8165 18.3142 24.026 18.5767M20 29.3334C14.8524 29.3334 10.6666 25.1475 10.6666 20C10.6666 14.8525 14.8525 10.6666 20 10.6666C25.1475 10.6666 29.3334 14.8525 29.3334 20C29.3334 25.1475 25.1475 29.3334 20 29.3334M20 8C13.373 8 8 13.373 8 20C8 26.627 13.373 32 20 32C26.627 32 32 26.627 32 20C32 13.373 26.627 8 20 8Z"
            fill="#F6A623"
        />
    </svg>
)

export default memo(VerificationStatusTimeout)
