const RejectedIcon = () => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
            cx={8}
            cy={8}
            r={7}
            stroke="#FF1807"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m10.4 5.6-4.8 4.8M5.6 5.6l4.8 4.8"
            stroke="#FF1807"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default RejectedIcon
