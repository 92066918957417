const ErrorIcon = () => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8Zm14 0A6 6 0 1 1 2 8a6 6 0 0 1 12 0ZM7 5a1 1 0 0 1 2 0v3a1 1 0 0 1-2 0V5Zm1 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
            fill="#FF1807"
        />
        <mask
            id="a"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={16}
            height={16}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8Zm14 0A6 6 0 1 1 2 8a6 6 0 0 1 12 0ZM7 5a1 1 0 0 1 2 0v3a1 1 0 0 1-2 0V5Zm1 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
                fill="#fff"
            />
        </mask>
        <g mask="url(#a)">
            <path fill="transparent" d="M0 0h16v16H0z" />
        </g>
    </svg>
)

export default ErrorIcon
