import { memo } from 'react'

const QuestionMarkSelfCheckoutIcon = () => (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16.5" r="16" fill="#FF786E" fillOpacity="0.12" />
        <g clipPath="url(#clip0_111_3741)">
            <path
                d="M22.5 16.5C22.5 14.7761 21.8152 13.1228 20.5962 11.9038C19.3772 10.6848 17.7239 10 16 10C14.2761 10 12.6228 10.6848 11.4038 11.9038C10.1848 13.1228 9.5 14.7761 9.5 16.5C9.5 18.2239 10.1848 19.8772 11.4038 21.0962C12.6228 22.3152 14.2761 23 16 23C17.7239 23 19.3772 22.3152 20.5962 21.0962C21.8152 19.8772 22.5 18.2239 22.5 16.5ZM8 16.5C8 14.3783 8.84285 12.3434 10.3431 10.8431C11.8434 9.34285 13.8783 8.5 16 8.5C18.1217 8.5 20.1566 9.34285 21.6569 10.8431C23.1571 12.3434 24 14.3783 24 16.5C24 18.6217 23.1571 20.6566 21.6569 22.1569C20.1566 23.6571 18.1217 24.5 16 24.5C13.8783 24.5 11.8434 23.6571 10.3431 22.1569C8.84285 20.6566 8 18.6217 8 16.5ZM13.3063 13.6656C13.5531 12.9688 14.2156 12.5 14.9563 12.5H16.7781C17.8687 12.5 18.75 13.3844 18.75 14.4719C18.75 15.1781 18.3719 15.8312 17.7594 16.1844L16.75 16.7625C16.7437 17.1687 16.4094 17.5 16 17.5C15.5844 17.5 15.25 17.1656 15.25 16.75V16.3281C15.25 16.0594 15.3938 15.8125 15.6281 15.6781L17.0125 14.8844C17.1594 14.8 17.25 14.6437 17.25 14.475C17.25 14.2125 17.0375 14.0031 16.7781 14.0031H14.9563C14.85 14.0031 14.7563 14.0687 14.7219 14.1687L14.7094 14.2063C14.5719 14.5969 14.1406 14.8 13.7531 14.6625C13.3656 14.525 13.1594 14.0938 13.2969 13.7063L13.3094 13.6687L13.3063 13.6656ZM15 19.5C15 19.2348 15.1054 18.9804 15.2929 18.7929C15.4804 18.6054 15.7348 18.5 16 18.5C16.2652 18.5 16.5196 18.6054 16.7071 18.7929C16.8946 18.9804 17 19.2348 17 19.5C17 19.7652 16.8946 20.0196 16.7071 20.2071C16.5196 20.3946 16.2652 20.5 16 20.5C15.7348 20.5 15.4804 20.3946 15.2929 20.2071C15.1054 20.0196 15 19.7652 15 19.5Z"
                fill="#FF786E"
            />
        </g>
        <defs>
            <clipPath id="clip0_111_3741">
                <rect width="16" height="16" fill="white" transform="translate(8 8.5)" />
            </clipPath>
        </defs>
    </svg>
)
export default memo(QuestionMarkSelfCheckoutIcon)
