const ApprovedIcon = () => (
    <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.236 2.534a.5.5 0 0 0-.27-.27L8.69.079a.5.5 0 0 0-.383 0L3.034 2.264a.5.5 0 0 0-.27.27L.579 7.808a.5.5 0 0 0 0 .383l2.185 5.274a.5.5 0 0 0 .27.271l5.274 2.185a.5.5 0 0 0 .383 0l5.274-2.185a.5.5 0 0 0 .271-.27l2.185-5.275a.5.5 0 0 0 0-.383l-2.185-5.274ZM4.293 7.093a1 1 0 0 1 1.414 0L7.5 8.886l3.793-3.793a1 1 0 1 1 1.414 1.414l-4.5 4.5a1 1 0 0 1-1.414 0l-2.5-2.5a1 1 0 0 1 0-1.414Z"
            fill="#37B047"
        />
        <mask
            id="a"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={17}
            height={16}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.236 2.534a.5.5 0 0 0-.27-.27L8.69.079a.5.5 0 0 0-.383 0L3.034 2.264a.5.5 0 0 0-.27.27L.579 7.808a.5.5 0 0 0 0 .383l2.185 5.274a.5.5 0 0 0 .27.271l5.274 2.185a.5.5 0 0 0 .383 0l5.274-2.185a.5.5 0 0 0 .271-.27l2.185-5.275a.5.5 0 0 0 0-.383l-2.185-5.274ZM4.293 7.093a1 1 0 0 1 1.414 0L7.5 8.886l3.793-3.793a1 1 0 1 1 1.414 1.414l-4.5 4.5a1 1 0 0 1-1.414 0l-2.5-2.5a1 1 0 0 1 0-1.414Z"
                fill="#fff"
            />
        </mask>
        <g mask="url(#a)">
            <path fill="transparent" d="M.5 0h16v16H.5z" />
        </g>
    </svg>
)

export default ApprovedIcon
