import { memo } from 'react'

const RedirectIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <path
                fill="#8190A6"
                d="M16 6.012a.56.56 0 0 1-.97.388L13.48 4.848l-3.685 3.88c-.194.096-.291.096-.485 0l-.97-.97a.295.295 0 0 1 0-.388l3.782-3.88-1.454-1.55a.56.56 0 0 1 .388-.97h4.46c.194 0 .485.29.485.582v4.46Z"
            />
            <path
                fill="#8190A6"
                d="M12.412 8.436h-.582c-.388 0-.775.388-.775.776v2.812c0 .485-.388.97-.873.97H3.006c-.485 0-.873-.388-.873-.97v-7.37c0-.484.388-.97.873-.97h6.11c.387 0 .775-.29.775-.775v-.485c0-.388-.388-.775-.776-.775H3.006A3.021 3.021 0 0 0 0 4.654v7.37a3.021 3.021 0 0 0 3.006 3.005h7.176a3.021 3.021 0 0 0 3.006-3.006V9.212a.765.765 0 0 0-.776-.776Z"
            />
        </svg>
    )
}

export default memo(RedirectIcon)
