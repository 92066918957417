import { memo } from 'react'

const TransactionsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M0 15c0-.552.445-1 1-1h14c.552 0 1 .444 1 1 0 .552-.445 1-1 1H1c-.552 0-1-.444-1-1Zm11-5.293V5.003A.999.999 0 0 1 12 4c.552 0 1 .438 1 1.003v4.775l.45-.45a.997.997 0 0 1 1.414 0 .998.998 0 0 1 0 1.415l-2.122 2.121a.997.997 0 0 1-1.414 0l-2.114-2.115a1 1 0 1 1 1.414-1.414l.372.372ZM3 7.364v4.633A.994.994 0 0 0 4 13c.556 0 1-.449 1-1.003V7.293l.45.45a.997.997 0 0 0 1.414 0 .997.997 0 0 0 0-1.414L4.742 4.207a.997.997 0 0 0-1.414 0L1.214 6.322a1 1 0 1 0 1.414 1.414L3 7.364ZM0 1c0-.552.445-1 1-1h14c.552 0 1 .444 1 1 0 .552-.445 1-1 1H1c-.552 0-1-.444-1-1Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(TransactionsIcon)
