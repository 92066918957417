import { AxiosRequestConfig } from 'axios'

import { getAuthToken } from './storage-service/storageService'

export const commonInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig<any> => {
    const commonHeaders = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'If-Modified-Since': '0',
    } as any
    config.headers = commonHeaders

    return config
}

export const verificationInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig<any> => {
    const token = getAuthToken()
    const commonHeaders = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'If-Modified-Since': '0',
    } as any

    const authHeader = { ...commonHeaders, Authorization: `Bearer ${token}` }

    if (token) {
        config.headers = authHeader
    } else {
        config.headers = commonHeaders
    }

    return config
}

export const clientInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig<any> => {
    const token = getAuthToken()

    const commonHeaders = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'If-Modified-Since': '0',
    } as any

    // Merge the headers from the request config with the common headers
    config.headers = { ...config.headers, ...commonHeaders }

    const authHeader = { Authorization: `Bearer ${token}` }

    if (token) {
        // Merge the headers from the request config with the authHeader
        config.headers = { ...config.headers, ...authHeader }
    } else {
        config.headers = { ...config.headers, ...commonHeaders }
    }

    return config
}
