import axios, {
    AxiosInstance,
    AxiosInterceptorOptions,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios'

export interface HttpClientInterceptorConfig {
    onRequestFulfilled?: (
        value: AxiosRequestConfig,
    ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>
    onResponseFulfilled?: (value: AxiosResponse) => AxiosResponse
    onRejected?: (error: any) => any
    options?: AxiosInterceptorOptions
}

export interface HttpClientConstructor {
    config: any
    interceptorConfig?: HttpClientInterceptorConfig
}

export class HttpClient {
    http: AxiosInstance

    constructor({ config, interceptorConfig }: HttpClientConstructor) {
        this.http = axios.create(config)
        this.http.interceptors.request.use(
            interceptorConfig?.onRequestFulfilled as any,
            interceptorConfig?.onRejected,
            interceptorConfig?.options,
        )
        this.http.interceptors.response.use(
            interceptorConfig?.onResponseFulfilled,
            interceptorConfig?.onRejected,
            interceptorConfig?.options,
        )
    }

    async get<T>(path: string, config?: AxiosRequestConfig) {
        return this.http.get<T>(path, config)
    }

    async post<T>(path: string, payload: unknown, config?: AxiosRequestConfig) {
        return this.http.post<T>(path, payload, config)
    }

    async put<T>(path: string, payload: unknown, config?: AxiosRequestConfig) {
        return this.http.put<T>(path, payload, config)
    }

    async patch<T>(path: string, payload: unknown, config?: AxiosRequestConfig) {
        return this.http.patch<T>(path, payload, config)
    }

    async delete<T>(path: string, config?: AxiosRequestConfig) {
        return this.http.delete<T>(path, config)
    }
}
