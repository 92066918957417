import { memo } from 'react'

const BackIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none">
            <path
                fill="#8190A6"
                fillRule="evenodd"
                d="M7.625 14.28a1 1 0 0 0 .156-1.405L5.08 9.5H13a1 1 0 1 0 0-2H5.08l2.7-3.375a1 1 0 1 0-1.56-1.25l-4 5a1 1 0 0 0 0 1.25l4 5a1 1 0 0 0 1.405.156Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(BackIcon)
