const WarningIcon = () => (
    <svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.058 0C4.053 0 0 4.06 0 9.063c0 5.002 4.053 9.062 9.058 9.062 5.014 0 9.075-4.06 9.075-9.063C18.133 4.06 14.072 0 9.058 0Zm.915 9.969V4.53H8.16V9.97h1.813Zm0 3.625V11.78H8.16v1.813h1.813Zm-8.16-4.531a7.25 7.25 0 0 0 7.254 7.25 7.25 7.25 0 1 0 0-14.5 7.25 7.25 0 0 0-7.254 7.25Z"
            fill="#F6A623"
        />
    </svg>
)

export default WarningIcon
