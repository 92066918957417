import { PostZipCodebookDTO } from './dto-client'

export class PostZip {
    readonly id: number
    readonly code: string
    readonly displayName: string
    readonly name?: string

    constructor(data: PostZipCodebookDTO) {
        this.name = data?.name
        this.id = data?.id
        this.code = data?.code
        this.displayName = data?.displayName
    }
}
