import { memo } from 'react'

const UserIcon = () => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity={0.12} cx={20} cy={20} r={20} fill="#FF786E" />
        <path
            d="M26.667 27.5v-1.667a3.333 3.333 0 0 0-3.334-3.333H17.5a3.333 3.333 0 0 0-3.333 3.333V27.5"
            stroke="#FF786E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle
            cx={20.417}
            cy={15.833}
            r={3.333}
            stroke="#FF786E"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default memo(UserIcon)
