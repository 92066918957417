import { styled } from '@leanpay/stitches'

export const Box = styled('div')

export const Image = styled('img', {
    // Reset
    verticalAlign: 'middle',
    maxWidth: '100%',
})

/* Typography */
export const P = styled('p')
export const Span = styled('span')
export const H1 = styled('h1')
export const H2 = styled('h2')
export const H3 = styled('h3')
export const H4 = styled('h4')
export const H5 = styled('h5')
export const H6 = styled('h6')

/* Semantic */
export const Section = styled('section')
export const Header = styled('header')
export const Footer = styled('footer')
