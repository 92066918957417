import {
    ContractContentDTO,
    ContractDataComplexDTO,
    ContractFooterDTO,
    ContractHeaderDTO,
    DocumentType,
} from './dto-client'

export class ContractData {
    readonly documentType: DocumentType
    readonly contractTitle: string
    readonly contractHeader: ContractHeaderDTO
    readonly contractContent: ContractContentDTO
    readonly contractFooter: ContractFooterDTO

    constructor(data: ContractDataComplexDTO) {
        this.documentType = data?.documentType
        this.contractTitle = data?.contractTitle
        this.contractHeader = data?.contractHeader
        this.contractContent = data?.contractContent
        this.contractFooter = data?.contractFooter
    }
}
