import { memo } from 'react'

const CalendarIcon = () => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 1a1 1 0 0 1 2 0v1h6V1a1 1 0 1 1 2 0v1h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h1V1Zm10 5H3v8h10V6Z"
            fill="#8190A6"
        />
        <mask
            id="a"
            style={{
                maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={1}
            y={0}
            width={14}
            height={16}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 1a1 1 0 0 1 2 0v1h6V1a1 1 0 1 1 2 0v1h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h1V1Zm10 5H3v8h10V6Z"
                fill="#fff"
            />
        </mask>
    </svg>
)

export default memo(CalendarIcon)
