import { memo } from 'react'

const PlusCircleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
            className="mr-2 h-4 w-4"
        >
            <path
                fill="#8190A6"
                fillRule="evenodd"
                d="M7.5.877a6.623 6.623 0 1 0 0 13.246A6.623 6.623 0 0 0 7.5.877ZM1.827 7.5a5.673 5.673 0 1 1 11.346 0 5.673 5.673 0 0 1-11.346 0ZM7.5 4a.5.5 0 0 1 .5.5V7h2.5a.5.5 0 1 1 0 1H8v2.5a.5.5 0 0 1-1 0V8H4.5a.5.5 0 0 1 0-1H7V4.5a.5.5 0 0 1 .5-.5Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default memo(PlusCircleIcon)
