import { memo } from 'react'

const CloseIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3001 5.09995C12.6867 4.71335 12.6867 4.08655 12.3001 3.69995C11.9135 3.31335 11.2867 3.31335 10.9001 3.69995L8.00011 6.59995L5.10011 3.69995C4.71351 3.31335 4.08671 3.31335 3.70011 3.69995C3.31351 4.08655 3.31351 4.71335 3.70011 5.09995L6.60011 7.99995L3.70011 10.9C3.31351 11.2866 3.31351 11.9134 3.70011 12.3C4.08671 12.6866 4.71351 12.6866 5.10011 12.3L8.00011 9.39995L10.9001 12.3C11.2867 12.6866 11.9135 12.6866 12.3001 12.3C12.6867 11.9134 12.6867 11.2866 12.3001 10.9L9.40011 7.99995L12.3001 5.09995Z"
                fill="black"
            />
            <mask id="mask0_137_977" maskUnits="userSpaceOnUse" x="3" y="3" width="10" height="10">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.3001 5.09995C12.6867 4.71335 12.6867 4.08655 12.3001 3.69995C11.9135 3.31335 11.2867 3.31335 10.9001 3.69995L8.00011 6.59995L5.10011 3.69995C4.71351 3.31335 4.08671 3.31335 3.70011 3.69995C3.31351 4.08655 3.31351 4.71335 3.70011 5.09995L6.60011 7.99995L3.70011 10.9C3.31351 11.2866 3.31351 11.9134 3.70011 12.3C4.08671 12.6866 4.71351 12.6866 5.10011 12.3L8.00011 9.39995L10.9001 12.3C11.2867 12.6866 11.9135 12.6866 12.3001 12.3C12.6867 11.9134 12.6867 11.2866 12.3001 10.9L9.40011 7.99995L12.3001 5.09995Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_137_977)">
                <rect width="16" height="16" fill="#8190A6" />
            </g>
        </svg>
    )
}

export default memo(CloseIcon)
