import { memo } from 'react'

const VerificationStatusWarning = () => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ borderRadius: 999 }}
    >
        <path fill="#fff" d="M0 0h40v40H0z" />
        <circle cx={20} cy={20} r={20} fill="#F6A623" fillOpacity={0.2} />
        <path
            d="M20 23.006c.9 0 1.623.724 1.623 1.623 0 .9-.724 1.645-1.623 1.645-.9 0-1.645-.745-1.645-1.645S19.1 23.006 20 23.006Zm0-1.646c-.614 0-1.031-.504-1.097-1.096l-.548-4.893c-.088-.855.811-1.645 1.645-1.645s1.733.79 1.623 1.645l-.526 4.893c-.066.592-.483 1.096-1.097 1.096Z"
            fill="#F6A623"
        />
        <path
            d="M20 32c-6.627 0-12-5.373-12-12S13.373 8 20 8s12 5.373 12 12-5.373 12-12 12Zm0-2.667a9.334 9.334 0 1 0 0-18.667 9.334 9.334 0 0 0 0 18.667Z"
            fill="#F6A623"
        />
    </svg>
)

export default memo(VerificationStatusWarning)
