import { SettlementCodebookDTO } from './dto-client'

export class Street {
    readonly name: string
    readonly id: number
    readonly code: string

    constructor(data: SettlementCodebookDTO) {
        this.name = data?.name
        this.id = data?.id
        this.code = data?.code
    }
}
