import { memo } from 'react'

const VerificationStatusRejected = () => (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={20} cy={20} r={20} fill="#FF786E" fillOpacity={0.2} />
        <path
            d="m20 18.114 1.888-1.888a1.328 1.328 0 0 1 1.883.003c.52.52.516 1.37.003 1.883L21.886 20l1.888 1.888c.52.52.522 1.359-.003 1.883-.52.52-1.37.516-1.883.003L20 21.885l-1.888 1.889c-.52.52-1.359.521-1.883-.003a1.337 1.337 0 0 1-.003-1.883L18.114 20l-1.888-1.889a1.328 1.328 0 0 1 .003-1.882c.52-.521 1.37-.516 1.883-.003L20 18.114ZM20 32c-6.627 0-12-5.373-12-12S13.373 8 20 8s12 5.373 12 12-5.373 12-12 12Zm0-2.667a9.334 9.334 0 1 0 0-18.667 9.334 9.334 0 0 0 0 18.667Z"
            fill="#FF6A71"
        />
    </svg>
)

export default memo(VerificationStatusRejected)
