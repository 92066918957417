import { memo } from 'react'

const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} fill="none">
        <path
            fill="#8190A6"
            fillRule="evenodd"
            d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.944.12l-2.75-2.5a.625.625 0 0 1 .841-.925l2.208 2.007 3.849-5.886a.625.625 0 0 1 .865-.181Z"
            clipRule="evenodd"
        />
    </svg>
)
export default memo(CheckIcon)
