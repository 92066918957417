import type { PropertyValue, CSS as StitchesCSS } from '@stitches/react'
import { createStitches } from '@stitches/react'

export const breakpoints = {
    xs: '370px',
    sm: '767px',
    md: '900px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1440px',
}

export const colors = {
    line: '#E6EAEE',
    peach1: '#FF6A71',
    peach2: '#FF786E',
    statusActiveText: '#2EA2F8',
    statusActiveBg: '#6ABFFF66',
    statusAcceptedText: '#37B047',
    statusAcceptedBg: 'rgba(55, 176, 71, 0.4)',
    statusFailedText: '#FF1807',
    statusFailedBg: '#FF180766',
    statusExpiredText: 'rgba(129, 144, 166, 1)',
    statusExpiredBg: 'rgba(129, 144, 166, 0.4)',
    statusPendingBg: 'rgba(246, 166, 35, 1)',
    statusPendingText: 'rgba(246, 166, 35, .4)',
    orange: '#FFA667',
    yellow: '#F6A623',
    white: '#ffffff',
    dark1: '#354052',
    dark2: '#8190A6',
    grey0: '#C8CED8',
    grey1: '#DFE3E9',
    grey2: '#F3F6F8',
    grey3: '#8F9DB0',
    grey4: '#7F8FA4',
    blue1: '#2EA2F8',
    blue2: '#6ABFFF',
    purple: '#9B95FF',
    tooltipBg: '#222C3C',
    red: '#FF1807',
    green: '#37B047',
    peachGradient: 'linear-gradient(90deg, #FFA667 0%, #FF6A71 100%)',
    purpleGradient: 'linear-gradient(#9B95FF, #6ABFFF)',
    success: 'rgba(55, 176, 71, 0.2)',
    approved: 'rgba(55, 176, 71, 0.2)',
    warning: 'rgba(246, 166, 35, 0.2)',
    error: '#F6DBDC',
    info: 'rgba(46, 162, 248, 0.2)',
}

// FIXME: Move everything to createStitches object

export const { styled, css, config, createTheme, keyframes, globalCss, theme } = createStitches({
    theme: {
        colors: {
            ...colors,
        },
        fonts: {
            sourceSansPro: `'Source Sans Pro', 'Source Sans 3', Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
        },
        fontSizes: {
            '0-5': '6px',
            '0-75': '8px',
            1: '12px',
            2: '14px',
            3: '16px',
            4: '18px',
            5: '20px',
            6: '24px',
            7: '28px',
        },
        space: {
            '0-25': '2px',
            '0-5': '4px',
            '0-75': '6px',
            1: '8px',
            '1-25': '10px',
            '1-5': '12px',
            '1-75': '14px',
            2: '16px',
            '2-25': '18px',
            '2-5': '20px',
            '2-75': '22px',
            3: '24px',
            '3-5': '28px',
            4: '32px',
            '4-5': '36px',
            5: '40px',
            '5-5': '44px',
            6: '48px',
            '6-5': '52px',
            7: '56px',
            '7-5': '60px',
            8: '64px',
            '8-5': '68px',
            9: '72px',
            '9-5': '76px',
            10: '80px',
        },
        sizes: {
            '0-25': '2px',
            '0-5': '4px',
            '0-75': '6px',
            1: '8px',
            '1-25': '10px',
            '1-5': '12px',
            '1-75': '14px',
            2: '16px',
            '2-25': '18px',
            '2-5': '20px',
            '2-75': '22px',
            3: '24px',
            '3-5': '28px',
            4: '32px',
            '4-5': '36px',
            5: '40px',
            '5-5': '44px',
            6: '48px',
            '6-5': '52px',
            7: '56px',
            '7-5': '60px',
            8: '64px',
            '8-5': '68px',
            9: '72px',
            '9-5': '76px',
            10: '80px',
        },
        radii: {
            '0-25': '2px',
            '0-5': '4px',
            '0-75': '6px',
            1: '8px',
            '1-25': '10px',
            '1-5': '12px',
            '1-75': '14px',
            2: '16px',
            'round': '9999px',
        },
        fontWeights: { light: 300, regular: 400, semiBold: 600, bold: 700 },
        lineHeights: {
            1: '18px',
            2: '21px',
            3: '24px',
            4: '27px',
            5: '30px',
            6: '33px',
        },

        shadows: {
            smallShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            buttonShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
            btnShadowHover: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
        },
        transition: {
            btnTransition: 'transform .4s ease, background-color .2s ease',
        },
        transform: { transformY: 'translateY(-1px)' },
    },
    /* eslint-disable */
    utils: {
        d: (value: PropertyValue<'display'>) => ({
            display: value,
        }),
        p: (value: PropertyValue<'padding'>) => ({
            padding: value,
        }),
        pt: (value: PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
        }),
        pr: (value: PropertyValue<'paddingRight'>) => ({
            paddingRight: value,
        }),
        pb: (value: PropertyValue<'paddingBottom'>) => ({
            paddingBottom: value,
        }),
        pl: (value: PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
        }),
        px: (value: PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (value: PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
            paddingBottom: value,
        }),
        m: (value: PropertyValue<'margin'>) => ({
            margin: value,
        }),
        mt: (value: PropertyValue<'marginTop'>) => ({
            marginTop: value,
        }),
        mr: (value: PropertyValue<'marginRight'>) => ({
            marginRight: value,
        }),
        mb: (value: PropertyValue<'marginBottom'>) => ({
            marginBottom: value,
        }),
        ml: (value: PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
        }),
        mx: (value: PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (value: PropertyValue<'marginTop'>) => ({
            marginTop: value,
            marginBottom: value,
        }),
        ta: (value: PropertyValue<'textAlign'>) => ({
            textAlign: value,
        }),
        fd: (value: PropertyValue<'flexDirection'>) => ({
            flexDirection: value,
        }),
        fw: (value: PropertyValue<'flexWrap'>) => ({
            flexWrap: value,
        }),
        ai: (value: PropertyValue<'alignItems'>) => ({
            alignItems: value,
        }),
        ac: (value: PropertyValue<'alignContent'>) => ({
            alignContent: value,
        }),
        jc: (value: PropertyValue<'justifyContent'>) => ({
            justifyContent: value,
        }),
        as: (value: PropertyValue<'alignSelf'>) => ({
            alignSelf: value,
        }),
        fg: (value: PropertyValue<'flexGrow'>) => ({
            flexGrow: value,
        }),
        fs: (value: PropertyValue<'flexShrink'>) => ({
            flexShrink: value,
        }),
        fb: (value: PropertyValue<'flexBasis'>) => ({
            flexBasis: value,
        }),
        bc: (value: PropertyValue<'backgroundColor'>) => ({
            backgroundColor: value,
        }),
        br: (value: PropertyValue<'borderRadius'>) => ({
            borderRadius: value,
        }),
        btrr: (value: PropertyValue<'borderTopRightRadius'>) => ({
            borderTopRightRadius: value,
        }),
        bbrr: (value: PropertyValue<'borderBottomRightRadius'>) => ({
            borderBottomRightRadius: value,
        }),
        bblr: (value: PropertyValue<'borderBottomLeftRadius'>) => ({
            borderBottomLeftRadius: value,
        }),
        btlr: (value: PropertyValue<'borderTopLeftRadius'>) => ({
            borderTopLeftRadius: value,
        }),
        bs: (value: PropertyValue<'boxShadow'>) => ({
            boxShadow: value,
        }),
        lh: (value: PropertyValue<'lineHeight'>) => ({
            lineHeight: value,
        }),
        ox: (value: PropertyValue<'overflowX'>) => ({
            overflowX: value,
        }),
        oy: (value: PropertyValue<'overflowY'>) => ({
            overflowY: value,
        }),
        pe: (value: PropertyValue<'pointerEvents'>) => ({
            pointerEvents: value,
        }),
        us: (value: PropertyValue<'userSelect'>) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),
        userSelect: (value: PropertyValue<'userSelect'>) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),
        size: (value: PropertyValue<'width'>) => ({
            width: value,
            height: value,
        }),
        appearance: (value: PropertyValue<'appearance'>) => ({
            WebkitAppearance: value,
            appearance: value,
        }),
        backgroundClip: (value: PropertyValue<'backgroundClip'>) => ({
            WebkitBackgroundClip: value,
            backgroundClip: value,
        }),
    } as any,
    /* eslint-enable */
    media: {
        xs: `(max-width: calc(${breakpoints.xs} - 1px))`,
        sm: `(max-width: calc(${breakpoints.sm} - 1px))`,
        md: `(min-width: ${breakpoints.sm}) and (max-width: calc(${breakpoints.md} - 1px))`,
        lg: `(min-width: ${breakpoints.md}) and (max-width: calc(${breakpoints.lg} - 1px))`,
        xl: `(min-width: ${breakpoints.lg}) and (max-width: calc(${breakpoints.xl} - 1px))`,
        xxl: `(min-width: ${breakpoints.xl}) and (max-width: ${breakpoints.xxl})`,
        notSm: `(min-width: ${breakpoints.sm})`,
        notMd: `(min-width: ${breakpoints.md})`,
        notLg: `(min-width: ${breakpoints.lg})`,
        notXl: `(min-width: ${breakpoints.xl})`,
        notXxl: `(min-width: calc(${breakpoints.xxl} + 1px ))`,
        minSm: `(min-width: ${breakpoints.sm})`,
        minMd: `(min-width: ${breakpoints.md})`,
        minLg: `(min-width: ${breakpoints.lg})`,
        minXl: `(min-width: ${breakpoints.xl})`,
        minXxl: `(min-width: calc(${breakpoints.xxl} + 1px ))`,
        maxSm: `(max-width: calc(${breakpoints.sm} - 1px))`,
        maxMd: `(max-width: calc(${breakpoints.md} - 1px))`,
        maxLg: `(max-width: calc(${breakpoints.lg} - 1px))`,
        maxXl: `(max-width: calc(${breakpoints.xl} - 1px))`,
        maxXxl: `(max-width: ${breakpoints.xxl})`,
        hover: '(any-hover: hover)',
    },
})

export type CSS = StitchesCSS<typeof config>
